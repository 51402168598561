import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoutesPath } from "../../constants/RoutesEnum";
import { RootState } from "../../store/store";
import { RoleEnum } from "../../constants/RoleEnum";

const TutorialRedirect: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const pathRoot = location.pathname;
  const token = useSelector((state: RootState) => state.token);
  const user = useSelector((state: RootState) => state.user);

  const protectNavigation = useMemo(() => {
    if (token.role === RoleEnum.ADMIN && pathRoot === RoutesPath.TUTORIAL) {
      return <Navigate to={`${RoutesPath.USEREDITOR}?id=${user?.id}`} />;
    }
    if (
      user.id &&
      token.role === RoleEnum.USER &&
      user?.tutorialCompleted &&
      pathRoot === RoutesPath.TUTORIAL
    ) {
      return (
        <Navigate
          to={`${RoutesPath.SUCCESS_PAGE}?id=${user?.id}`}
          state={{
            from: location,
            error: { title: "Warning", message: "Tutorial already completed" },
          }}
          replace
        />
      );
    }
    if (
      user.id &&
      token.role === RoleEnum.USER &&
      !user?.tutorialCompleted &&
      pathRoot !== RoutesPath.TUTORIAL
    ) {
      return (
        <Navigate
          to={`${RoutesPath.TUTORIAL}?id=${user?.id}`}
          state={{
            from: location,
            error: { title: "Error", message: "Tutorial not completed" },
          }}
          replace
        />
      );
    }

    return <>{children}</>;
  }, [
    token.role,
    user?.tutorialCompleted,
    user?.id,
    pathRoot,
    children,
    location,
  ]);

  return protectNavigation;
};

export default TutorialRedirect;
