import { RightOutlined } from "@ant-design/icons";
import styles from "./ButtonSidebar.module.css";
import { IButtonSidebarProps } from "../../../interfaces";

const ButtonSidebar = ({
  text,
  onClickChangeComponent,
  active,
  SecondIcon,
}: IButtonSidebarProps) => {
  return (
    <button
      className={`${styles.button} ${active && styles.active}`}
      onClick={onClickChangeComponent}
    >
      <RightOutlined style={{ fontSize: "14px" }} />
      {SecondIcon && <SecondIcon style={{ fontSize: "14px" }} />}
      {text}
    </button>
  );
};

export default ButtonSidebar;
