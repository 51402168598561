import { Button, Flex, Form, Input, Modal, notification, Select } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ModalSensitiveFields.module.css";
import {
  IErrorData,
  IModalSensitiveFields,
  ITempSensitiveFields,
} from "../../../../interfaces";
import { usePatchSensitiveFieldsMutation } from "../../../../services/api";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../../Notification/utils/constants";
import { openNotification } from "../../../Notification/Notification";
import { NameFormValue } from "./constants/constants";
import _ from "lodash";

const { Option } = Select;

const ModalSensitiveFields = ({
  isModalOpen,
  setIsModalOpen,
  idTemplate,
}: IModalSensitiveFields) => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [tempSensitiveFields, setTempSensitiveFields] =
    useState<ITempSensitiveFields>({
      name: "",
      labelIt: "",
      labelEn: "",
    });
  const [isFormValid, setIsFormValid] = useState(false);
  const [listSensitiveFields, setListSensitiveFields] = useState<
    ITempSensitiveFields[]
  >([]);
  const [patchSensitiveFields] = usePatchSensitiveFieldsMutation();

  const closeModal = useCallback(() => {
    form.resetFields();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  const cleanModal = useCallback(() => {
    form.resetFields();
    setListSensitiveFields([]);
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  const handleOnFinish = useCallback(async () => {
    const response = await patchSensitiveFields({
      id: idTemplate,
      content: listSensitiveFields,
    });

    if ("error" in response && "data" in response.error) {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.error.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.success.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.success.description",
      ),
      NotificationTypes.SUCCESS,
    );
    setIsModalOpen(false);
    setListSensitiveFields([]);
  }, [
    api,
    idTemplate,
    listSensitiveFields,
    patchSensitiveFields,
    setIsModalOpen,
    t,
  ]);

  const handleFormChange = useCallback(
    (_: any, allValues: ITempSensitiveFields) => {
      setTempSensitiveFields((prev) => {
        setIsFormValid(Object.values(allValues).every((value) => !!value));
        return { ...prev, ...allValues };
      });
    },
    [],
  );

  const handleOnSave = useCallback(() => {
    const objSensitiveFields = {
      name: tempSensitiveFields.name,
      labelIt: tempSensitiveFields.labelIt,
      labelEn: tempSensitiveFields.labelEn,
    };
    setListSensitiveFields((prev) => [...prev, objSensitiveFields]);
    setIsFormValid(false);
    form.resetFields();
  }, [
    form,
    tempSensitiveFields.labelEn,
    tempSensitiveFields.labelIt,
    tempSensitiveFields.name,
  ]);

  const deleteSensitiveFields = useCallback((value: string) => {
    setListSensitiveFields((prev) =>
      prev.filter((tag) => tag.labelIt !== value),
    );
  }, []);

  return (
    <>
      {contextHolder}

      <Modal
        title={t("pdfOverview.titleModalPdfSensitiveFields")}
        open={isModalOpen}
        footer={null}
        onCancel={closeModal}
      >
        <Form
          className={styles.form}
          onValuesChange={handleFormChange}
          onFinish={handleOnSave}
          form={form}
        >
          <Form.Item
            className={styles.formItem}
            name={NameFormValue.NAME}
            label={t("homePage.generalInfoSection.labels.userName")}
            rules={[
              {
                required: true,
                message: t("errormsg.genericInput"),
              },
            ]}
          >
            <Input
              placeholder={t(
                "pdfOverview.loadTemplatePlaceholder.modalSensitiveFieldsNamePlaceholder",
              )}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name={NameFormValue.LABELIT}
            label={t("pdfOverview.loadTemplateLabel.labelIt")}
            rules={[
              {
                required: true,
                message: t("errormsg.genericInput"),
              },
            ]}
          >
            <Input
              placeholder={t(
                "pdfOverview.loadTemplatePlaceholder.labelItPlaceholder",
              )}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name={NameFormValue.LABELEN}
            label={t("pdfOverview.loadTemplateLabel.labelEn")}
            rules={[
              {
                required: true,
                message: t("errormsg.genericInput"),
              },
            ]}
          >
            <Input
              placeholder={t(
                "pdfOverview.loadTemplatePlaceholder.labelEnPlaceholder",
              )}
            />
          </Form.Item>

          <Form.Item>
            <Flex
              gap="small"
              align="baseline"
              style={{ width: "100%" }}
              justify="flex-end"
            >
              <Flex gap="small" wrap="wrap">
                <Button
                  type="primary"
                  size={"middle"}
                  htmlType="submit"
                  disabled={!isFormValid}
                >
                  {t("tutorial.insertBtn")}
                </Button>
              </Flex>
            </Flex>
          </Form.Item>
        </Form>
        <Form
          initialValues={listSensitiveFields.map((item) => item.labelIt)}
          onFinish={handleOnFinish}
          className={styles.form}
        >
          <Form.Item
            className={styles.formItem}
            label={t("pdfOverview.titleModalPdfSensitiveFields")}
          >
            <Select
              mode="tags"
              listItemHeight={32}
              placeholder={t("pdfOverview.titleModalPdfSensitiveFields")}
              value={listSensitiveFields.map((item) => item.labelIt)}
              onDeselect={deleteSensitiveFields}
            >
              {listSensitiveFields.map((tag) => (
                <Option key={_.uniqueId("id_")} value={tag.labelIt}>
                  {tag.labelIt}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Flex
              gap="small"
              align="baseline"
              style={{ width: "100%" }}
              justify="flex-end"
            >
              <Flex gap="small" wrap="wrap">
                <Button
                  type="primary"
                  size={"middle"}
                  htmlType="submit"
                  disabled={!listSensitiveFields.length}
                >
                  {t("saveBtn")}
                </Button>
                <Button size={"middle"} onClick={cleanModal}>
                  {t(
                    "homePage.professionalExperienceSection.form.cancelButton",
                  )}
                </Button>
              </Flex>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalSensitiveFields;
