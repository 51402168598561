import { Flex, Switch } from "antd";
import styles from "./Sidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { LogoutOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../constants/ThemeEnum";
import { setTheme } from "../../slices/themeSlice";
import { FiSun, FiMoon } from "react-icons/fi";
import AvatarSidebar from "./AvatarSidebar";
import ButtonSidebar from "./ButtonSidebar";
import LanguagesPickerSidebar from "../LanguagesPickerSidebar";
import useLogOut from "../../hooks/useLogOut";

const Sidebar: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user);
  const isDark = localStorage.getItem("theme") !== Theme.LIGHT;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const onThemeChange = useCallback(
    (checked: boolean) => {
      dispatch(
        setTheme({
          themeName: checked ? Theme.DARK : Theme.LIGHT,
        }),
      );
    },
    [dispatch],
  );

  const logOut = useLogOut();

  return (
    <div className={styles.sidebar}>
      <div>
        <AvatarSidebar
          name={user?.name}
          surname={user?.surname}
          id={idEmployee.employeeId}
        />

        <p className={styles.subDescriptionLambda}>
          {idEmployee.employeeId && t("homePage.impersonationSidebarName")}
          <span className={styles.subtitleLambda}>
            {` ${user?.name} ${user?.surname}`}
          </span>
        </p>
      </div>

      {children}

      <div className={styles.line}></div>

      <div className={styles.sidebarSettings}>
        <ButtonSidebar
          text={t("homePage.logoutBtn")}
          onClickChangeComponent={logOut}
          SecondIcon={LogoutOutlined}
        />

        <LanguagesPickerSidebar />

        <Flex
          align="center"
          justify="flex-start"
          gap={10}
          style={{ width: "100%", margin: "10px" }}
        >
          {<FiSun className={styles.theme} />}
          <Switch defaultChecked={isDark} onChange={onThemeChange} />
          {<FiMoon className={styles.theme} />}
        </Flex>
      </div>
    </div>
  );
};

export default Sidebar;
