import styles from "./ResultLang.module.css";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { SelectFlagMap } from "../../pages/tutorial/languages/utils/constants";
import {
  languageLevelsList,
  languageNameList,
  LanguageNameList,
} from "../LanguagesContent/utils/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IResultLangProps } from "../../interfaces";

const ResultLang: React.FC<IResultLangProps> = ({
  handleSelectName,
  item,
  handleSelect,
}) => {
  const { t } = useTranslation();
  const languageUser = useSelector(
    (state: RootState) => state.userInfoSlice.languages,
  );

  const [selectLanguageList, setSelectLanguageList] =
    useState<LanguageNameList>(
      languageNameList.filter(
        (lang) =>
          !languageUser.some((language) => language.name === lang.value),
      ),
    );

  useEffect(() => {
    setSelectLanguageList(
      languageNameList.filter(
        (lang) =>
          !languageUser.some((language) => language.name === lang.value),
      ),
    );
  }, [languageUser]);

  return (
    <div className={styles.langRow} style={{ gap: "0", height: "100%" }}>
      <Select
        showSearch={true}
        className={styles.levelSelect}
        defaultValue={item.name}
        onChange={handleSelectName}
        options={selectLanguageList.map((lang) => {
          return {
            ...lang,
            label: (
              <span className={styles.flag}>
                {SelectFlagMap[lang.value]} {lang.value}
              </span>
            ),
          };
        })}
      />

      <Select
        defaultValue={item.level}
        className={styles.levelSelect}
        onChange={handleSelect}
        options={[
          ...languageLevelsList,
          {
            value: t("tutorial.languagePage.native"),
            label: t("tutorial.languagePage.native"),
          },
        ]}
      />
    </div>
  );
};

export default ResultLang;
