export const it = {
  login: {
    placeholderEmail: "Email",
    placeholderPassword: "Password",
    placeholderUsername: "Username",
    placeholderLoginBtn: "Log In",
    placeholderOpenId: "Open ID",
    privacyMessage: `Autorizzo il trattamento dei miei dati personali presenti nel cv ai sensi dell'art. 13 del Decreto Legislativo 30 giugno 2003, n. 196
    "Codice in materia di protezione dei dati personali" e dell'art. 13 del GDPR (Regolamento UE 2016/679).`,
  },
  tooltip: {
    tooltipQualification:
      "Inserisci la tua qualifica. Ad esempio: Laurea, Diploma, Master, eccetera.",
    tooltipInstitution:
      "Inserisci l'istituto di istruzione frequentato. Ad esempio: Liceo Statale, Università, I.T.C. Luigi Sturzo, eccetera.",
    tooltipFieldStudy:
      "Inserisci l'indirizzo di studio seguito. Ad esempio: Perito Informatico, Liceo Classico, Ingegneria Informatica, Liceo Scientifico, eccetera.",
    tooltipYearAttainment: "Inserisci l'anno di conseguimento del titolo.",
    tooltipLanguage:
      "Inserisci la lingua. Ad esempio: Inglese, Francese, Spagnolo, eccetera.",
    tooltipLevel:
      "Inserisci il livello di competenza linguistica. Scegli tra Madrelingua, C1, B2, eccetera. Inserisci almeno una Madrelingua.",
  },
  tutorial: {
    tutorialPage: {
      steps: {
        stepOne: "Chi sei?",
        stepTwo: "Titoli di studio",
        stepThree: "Lingua",
        stepFour: "Riepilogo",
        stepFive: "Fine",
      },
      paragraphs: {
        tutorialTitle: "Benvenuto su",
        tutorialSubtitle: "Per iniziare, compila i seguenti campi:",
      },
      nextBtn: "Avanti",
      successPageTitle: "Congratulazioni hai completato la sezione anagrafica!",
      successPageButton: "Entra su AithoCV",
    },
    personalDataPage: {
      sectionDescription: "Io sono AithoCV, tu chi sei?",
      birthDatePlaceholder: "Seleziona data",
      nameLabel: "Nome",
      surnameLabel: "Cognome",
      nameInputPlaceholder: "Inserisci nome",
      surnameInputPlaceholder: "Inserisci cognome",
    },
    insertBtn: "Aggiungi",
    editBtn: "Modifica",

    educationPage: {
      sectionDescription:
        "Inserisci i percorsi formativi che hai intrapreso (es. Diploma, Laurea):",
      labelQualification: "Titolo di studio",
      placeholderQualification: "es. Diploma, Laurea triennale",
      placeholderPlace: "es. Università degli studi di Catania",
      labelPlace: "Istituto",
      labelDescription: "Indirizzo",
      placeholderDescription: "es. Perito informatico, Ingegneria informatica",
      labelDate: "Anno conseguimento",
      placeholderRangePicker: "Seleziona anno",
      placeholderAddonFrom: "Dal: ",
      placeholderAddonTo: "Al: ",
      tableHeader: {
        qualification: "Titolo",
        place: "Istituto",
        description: "Indirizzo",
        date: "Anno",
      },
    },
    languagePage: {
      emptyLanguages: "Non ci sono lingue aggiunte",
      sectionDescription: "Che lingue sai parlare?",
      labelLanguage: "Lingua",
      placeholderLangSelect: "Seleziona la lingua",
      labelLevel: "Livello",
      placeholderLvlSelect: "Seleziona il livello",
      native: "Madrelingua",
      tableHeader: {
        language: "Lingua",
        level: "Livello",
      },
      languageList: {
        english: "Inglese",
        french: "Francese",
        german: "Tedesco",
        italian: "Italiano",
        russian: "Russo",
        ukrainian: "Ucraino",
        spanish: "Spagnolo",
        portuguese: "Portoghese",
        chinese_mandarin: "Cinese Mandarino",
      },
    },
    recapPage: {
      userDataTitle: "Chi sei:",
      educationTitle: "Titoli di studio:",
      languagesTitle: "Lingue:",
      recapTitle: "È tutto corretto?",
    },
  },
  homePage: {
    welcome: "Benvenuto su AITHOJobJourney!",
    welcomeAdmin: "Gestione del Curriculum del Dipendente",
    welcomeMessage:
      "Gestisci il tuo curriculum in modo facile e veloce. Aggiorna le tue esperienze quando vuoi e scarica una copia PDF pronta per essere condivisa con i datori di lavoro. Semplice, veloce e professionale.",
    welcomeMessageAdmin:
      "Stai modificando il curriculum di un dipendente. Aggiorna e modifica le loro esperienze.",
    logoutBtn: "Log out",
    exportPdf: "Export PDF",
    impersonationSidebarName: "Stai modificando il CV di:",
    lastEdit: "Ultima Modifica",
    status: "Stato",
    editRole: "Gestisci ruoli",
    changeProfile: "Vai alla tua home",
    titleAdminPage: "Tutti i dipendenti",
    search: "Cerca dipendente, ruolo o competenza",
    searchButton: "Ricerca",
    filter: "Filtra",
    namePlaceholderSearch:
      "Inseirisci il nome o nome e cogneme del dipendete da cercare",
    rolePlaceholderSearch: "Inseirisci il ruolo del dipendente da cercare",
    technicalSkillsPlaceholderSearch:
      "Inseirisci le competenze tecniche del dipendente da cercare",
    generalInfoSection: {
      sectionTitle: "DATI ANAGRAFICI",
      labels: {
        userName: "Nome",
        userSurname: "Cognome",
        userNameAndSurname: "Nome e Cognome",
        userEmail: "Email",
        userPhoneNumber: "Telefono",
        userGitHub: "GitHub",
        userCity: "Città",
        userLinkedIn: "LinkedIn",
        userRoleManagment: "Gestione Ruolo",
      },
      placeholders: {
        userPlaceholder: "Inserisci Nome e Cognome",
        emailPlaceholder: "Inserisci email",
        phonePlaceholder: "Inserisci il numero di telefono",
        gitPlaceholder: "Url github",
        cityPlaceholder: "Città",
        linkedInPlaceholder: "Url Linkedin",
      },
      titles: {
        generality: "Generalità",
        contacts: "Contatti",
      },
    },
    roleSection: {
      sectionTitle: "RUOLO",
      title: "Ruolo",
      labelProfessionalProfile: "Profilo personale",
      placeholderProfessionalProfile: "es. Frontend",
      emptyRole: "Nessun ruolo inserito",
      tooltip: "Puoi aggiungere un limite massimo di due ruoli",
      alertLimit: "Limite massimo di ruoli raggiunto",
    },
    professionalExperienceSection: {
      sectionTitle: "ESPERIENZE PROFESSIONALI",
      years: "anni",
      year: "anno",
      month: "mese",
      months: "mesi",
      form: {
        itaTab: "Italiano",
        engTab: "Inglese",
        addButton: "Aggiungi",
        cancelButton: "Annulla",
        clearButton: "Svuota",
        labels: {
          role: "Qualifica",
          place: "Luogo",
          finalCustomer: "Cliente finale",
          finalCustomerNDA: "Cliente finale sotto NDA",
          company: "Datore di lavoro",
          projectName: "Nome progetto",
          tools: "Tecnologie utilizzate",
          description: "Descrizione",
          currentProjectCheckBoxLabel: "Attualmente ricopro questo ruolo",
          currentNDACheckBoxLabel:
            "Questa esperienza professionale è soggetta ad un accordo di riservatezza (NDA)?",
          startDateLabel: "Data inzio",
          endDateLabel: "Data fine",
          visible: "Visualizza esperienza professionale",
          invisible: "Non visualizzare esperienza professionale",
          tooltipVisible:
            "Scegliendo di non visualizzare l'esperienza professionale essa non verrà visualizzata nei pdf",
        },
        placeholders: {
          rolePlaceholder: "Inserisci la tua qualifica nel progetto",
          finalCustomerPlaceholder: "Nome del cliente",
          finalCustomerNDAPlaceholder: "Cliente non divulgabile",
          placeAgencyPlaceholder: "Luogo dell'azienda",
          companyPlaceholder: "Datore di lavoro",
          projectNamePlaceholder: "Esempio: FabrikWeb",
          toolsPlaceholder: "Inserisci la tecnologia utilizzata e premi invio",
          descriptionPlaceholder: "Descrivi cosa hai fatto nel progetto",
          startDatePlaceholder: "Data inzio",
          endDatePlaceholder: "Data fine",
        },
        tooltip: {
          role: "Indica la qualifica con il quale sei stato assunto in questa esperienza professionale.",
          company:
            "Inserisci il nome dell'azienda per cui hai lavorato. Se attualmente lavori presso Aitho, e il tuo ruolo coinvolge collaborazioni per altri clienti (es. sei stato assunto da Capgemini per un progetto presso Nexi), inserisci Aitho come azienda di riferimento.",
          place:
            "Indica la sede dell'azienda in cui hai lavorato o lavori, ad esempio Catania per Aitho.",
          finalCustomer:
            "Indica il nome del cliente finale per cui hai svolto il lavoro. Nel caso di collaborazioni che coinvolgono più aziende (es. sei stato assunto da Capgemini per lavorare su un progetto per Nexi), il cliente finale è Nexi.",
          finalCustomerNDA:
            "Indica il nome del cliente finale per cui hai svolto il lavoro. Nel caso di collaborazioni che coinvolgono più aziende (es. sei stato assunto da Capgemini per lavorare su un progetto per Nexi), il cliente finale è Nexi. Nota: Se hai firmato un accordo NDA, assicurati di utilizzare la nomenclatura corretta specificata nel contratto. In alcuni casi, l'azienda potrebbe aver firmato l'NDA per conto dei dipendenti, quindi è consigliabile verificare con l'amministrazione per chiarimenti.",
          projectName:
            "Specifica il nome del progetto. Se il progetto è soggetto a un accordo di NDA e il nome potrebbe rivelare l'identità del cliente, usa descrizioni generiche come Sito web, Applicazione mobile, ecc.",
          tools:
            "Elenca tutte le tecnologie utilizzate nella realizzazione del progetto.",
          description:
            "Descrivi le attività che hai svolto all'interno di questo progetto.",
        },
        notifications: {
          success: {
            title: "Ok!",
            description: "Inserimento effettutato.",
          },
          successPatch: {
            title: "Ok!",
            description: "Modifica effettuata",
          },
          successProfessionalProfile: {
            title: "Ok!",
            description: "Inserimento o Modifica effettuata.",
          },
          successDelete: {
            title: "OK!",
            description: "Esperienza professionale eliminata",
          },
          successDeleteEducation: {
            title: "OK!",
            description: "Formazione eliminata",
          },
          successDeleteLanguage: {
            title: "OK!",
            description: "Lingua eliminata",
          },
          successDeleteCertification: {
            title: "OK!",
            description: "Certificazione eliminata",
          },
          successDeleteSoftSkills: {
            title: "OK!",
            description: "Competenza trasversale eliminata",
          },
          warning: {
            title: "Attenzione!",
            description: "Inserimento annullato.",
          },
          warningProfessionalProfile: {
            title: "Attenzione!",
            description: "Inserimento o Modifica annullata.",
          },
          warningPatch: {
            title: "Attenzione!",
            description: "Modifica annullata.",
          },
          error: {
            title: "Errore!",
            description: "Inserimento fallito.",
          },
          errorPut: {
            title: "Errore!",
            description: "Inserimento o modifica fallita.",
          },
          errorDelete: {
            title: "Errore!",
            description: "Impossibile eliminare l'esperienza professionale",
          },
          errorDeleteEducation: {
            title: "Errore!",
            description: "Impossibile eliminare o modificare la formazione",
          },
          errorDeleteCertification: {
            title: "Errore!",
            description: "Impossibile eliminare la certificazione",
          },
          errorDeleteLanguage: {
            title: "Errore!",
            description: "Impossibile eliminare la lingua",
          },
          errorEditCertification: {
            title: "Errore!",
            description: "Impossibile modificare la certificazione",
          },
          errorPatch: {
            title: "Errore!",
            description: "Impossibile modificare l'esperienza professionale",
          },
          errorProfessionalProfile: {
            title: "Errore!",
            description: "Impossibile modifcare o aggiungere il ruolo.",
          },
          errorDeleteSoftSkills: {
            title: "Errore!",
            description: "Impossibile eliminare la competenza trasversale.",
          },
          errorEditSoftSkills: {
            title: "Errore!",
            description: "Impossibile modificare la competenza trasversale.",
          },
          errorSpecialCharacters: "Impossibile inserire caratteri speciali",
          warningLogout: "Sei stato disconesso. Rieffettuare il login",
        },
      },
      visualization: {
        emptyExperiences: "Nessuna esperienza trovata",
        today: "Oggi",
        ongoing: "In corso",
        error: "Errore durante il caricamento dati",
        refresh: "Aggiorna",
        draft: "Bozza",
        online: "Online",
        changeStatusDraft:
          "Sei sicuro di voler cambiare lo stato del template da online a bozza?",
        changeStatusOnline:
          "Sei sicuro di voler cambiare lo stato del template da bozza a online?",
        buttonStatus: "Cambia stato",
        viewButtonUp: "Mostra meno",
        viewButtonDown: "Mostra tutto",
      },
    },
    educationSection: {
      sectionTitle: "FORMAZIONE",
      emptyEducation: "Nessuna formazione inserita",
    },
    languagesSection: {
      sectionTitle: "LINGUE",
    },
    certificationSection: {
      sectionTitle: "CERTIFICAZIONI",
      emptyCertification: "Nessuna certificazione",
      certificationsNameLabel: "Tipo di certificazione",
      certificationsUrlLabel: "Link certificazione",
      certificationsReleaseYearLabel: "Anno di rilascio",
      certificationsExpireYearLabel: "Anno di scadenza",
      certificationsNamePlaceholder: "Inserisci il tipo di certificazione",
      certificationsUrlPlaceholder:
        "Inserisci il link della tua certificazione",
      certificationsReleaseYearPlaceholder: "Inserisci l'anno di rilascio",
      certificationsExpireYearPlaceholder: "Inserisci l'anno di scadenza",
      linkCertification: "Link certificazione",
    },
    technicalSkillsSection: {
      sectionTitle: "COMPETENZE TECNICHE",
      title: "Competenze Tecniche",
      programmingLanguagesLabel: "Linguaggi di programmazione",
      programmingLanguagesPlaceholder: "Es. Java, PHP, JavaScript, ecc",
      frameworksLabel: "Frameworks",
      frameworksPlaceholder: "Es. React, Angular, Spring, ecc",
      databasesLabel: "Databases",
      databasesPlaceholder: "Es. MySQL, MongoDB, Oracle Database, ecc",
      operativeSystemsLabel: "Sistemi operativi",
      operativeSystemsPlaceholder: "Es. Microsoft Windows, Mac OS, Linux, ecc",
      softwareLabel: "Software",
      softwarePlaceholder:
        "Es. Visual Studio Code, IntelliJ IDEA, Adobe Photoshop, ecc",
      emptySoftware: "Nessun software inserito",
      emptyProgrammingLanguages: "Nessun linguaggio di programmazione inserito",
      emptyFrameworks: "Nessun frameworks inserito",
      emptydatabases: "Nessun databases inserito",
      emptyoperativeSystems: "Nessun sistema operativo inserito",
      emptyTechnicalSkills: "Non ci sono competenze tecniche",
    },
    softSkills: {
      sectionTitle: "COMPETENZE TRASVERSALI",
      nameSoftSkillsLabel: "Competenza",
      nameSoftSkillsPlaceholder: "es. Lavoro di squadra, creatività",
      levelSoftSkillsLabel: "Livello",
      levelSoftSkillsPlaceholder: "es. Basic",
      emptySoftSkills: "Nessuna competenza trasversale inserita",
      softSkillsLevel: {
        basic: "Base",
        moderate: "Moderato",
        advance: "Avanzato",
      },
    },
  },
  pdfOverview: {
    addTemplate: "Carica template",
    editTemplate: "Gestione dei template",
    templateTitleSidebar: "Template",
    pdfTitle: "CV -",
    back: "Torna alla home",
    backAllEmployees: "Torna alla tabella di tutti i dipendenti",
    layouts: "Layouts",
    describe: "Scarica il tuo CV",
    downloadEmployeeName: "Scarica il CV di",
    download: "Download",
    errorTitle: "Errore",
    errorType: "Errore nel recupero dei PDF",
    description:
      "Scegli tra una selezione di template per il tuo curriculum e ottieni una versione personalizzata in un clic! Facile e veloce da usare.",
    titlePdfOverview: "Scegli il tuo CV",
    isAnonymousCV: "Rendi anonimo il tuo nome",
    isAnonymousCVEmployee: "Rendi anonimo il nome del dipendente",
    isAnonymizable: "Può essere anonimizzabile",
    sensitiveFields: "Dati sensibili",
    notIsAnonymousCV: "Non rendere anonimo il tuo nome",
    titleModalPdfSensitiveFields: "Campi Sensibili",
    yes: "Si",
    no: "No",
    containsSensitiveData: "Contiene i dati sensibili",
    containsSensitiveDataEmployee: "Contiene l'inserimento dei dati sensibili",
    notContainsSensitiveData: "Non contiene i dati sensibili",
    choose: "Scegli",
    delete: "Elimina",
    downloadTable: " Scarica",
    addSensitiveData: " Aggiungi dati sensibili",
    deleteTemplate: "Template eliminato",
    notDeleteTemplate: "Template non eliminato",
    titleDeletePopup: "Elimina template",
    descriptionDeletePopup: "Sei sicuro di voler eliminare il template?",
    modalDownloadTitle: "Scegli se scaricare il logo o il template HTML",
    buttonDownloadLogo: "Scarica logo",
    buttonDownloadTemplate: "Scarica template HTML",
    modalUpdateTitle: "Modifica il logo o il template HTML",
    buttonUpdateLogo: "Modifica il logo",
    buttonUpdateTemplate: "Modifica il template HTML",
    loadTemplateLabel: {
      templateNameLabel: "Nome template",
      templateFileLabel: "File template",
      templateLogoLabel: "Logo template",
      templateIsAnonymizableLabel: "Possibilità di renderlo anonimo",
      labelIt: "Label in italiano",
      labelEn: "Label in inglese",
    },
    loadTemplatePlaceholder: {
      alertModalNDA:
        "Se hai lavorato per un cliente con cui hai firmato un accordo di non divulgazione (NDA), ti ricordiamo di non inserire il nome del cliente o del progetto se questi possono rivelare l'identità del cliente. In alcuni casi, l'azienda potrebbe aver firmato l'NDA per conto dei dipendenti, pertanto è consigliabile consultare l'amministrazione per chiarimenti. Se hai dubbi, ti invitiamo a verificare attentamente i dati inseriti o a consultare l'accordo firmato prima di procedere con il download del CV. Grazie.",
      documentationTemplateLink:
        "Consulta la documentazione per inserire i template",
      templateNamePlaceholder: "Inserisci il nome del template",
      templateFilePlaceholder: "Carica il file del template in formato html",
      templateLogoPlaceholder:
        "Carica il logo del template in formato png,jpg o jpeg",
      modalSensitiveFieldsNamePlaceholder:
        "Inserisci il nome della variabile che verrà utilizzata nel template già caricato",
      labelItPlaceholder:
        "Inserisci cosa richiedere come campo sensibile in italiano",
      labelEnPlaceholder:
        "Inserisci cosa richiedere come campo sensibile in inglese",
    },
    layoutsName: {
      layoutNameAitho: "Layout Aitho",
      layoutNameIntesa: "Layout Intesa",
      layoutName3: "Layout 3",
    },
  },
  emptyTemplate: "Nessun template caricato",
  saveBtn: "Salva",
  saveEditBtn: "Salva modifiche",
  cancelBtn: "Annulla",
  continueBtn: "Continua",
  darkMode: "Dark mode",
  actions: "Azioni",
  cv: "CV",
  info: "Info",
  profile: "Profilo",
  errormsg: {
    genericInput: "Riempi il campo.",
    genericDate: "Data necessaria.",
    missingPword: "Inserisci la tua password.",
    missingUsername: "Inserisci il tuo username.",
    missingPhoneNumber: "Inserisci il tuo numero di telefono.",
    invalidPhoneNumber: "Il tuo numero di telefono non è valido.",
    missingEmail: "Inserisci la tua email.",
    invalidEmail: "La tua email non è valida.",
    missingGitHub: "Inserisci link GitHub.",
    invalidGitHub: "Il tuo link GitHub non è valido.",
    missingLinkedIn: "Inserisci link LinkedIn.",
    invalidLinkedIn: "Il tuo link LinkedIn non è valido.",
  },
  notifications: {
    errorTitle: "Errore",
    warningTitle: "Attenzione",
    seccessTitle: "Ok",
  },
  roles: {
    updateRole: "Modifica",
    employeeRoleManagement: "Gestione del ruolo dei dipendenti",
    successUpdateMessage: "Ruolo modificato con successo",
    errorUpdateMessage: "Errore durante la modifica del ruolo",
    errorNotSelectedRole: "Error, ruolo non selezionato",
    titleModal: "Conferma cambiamento di ruolo",
    descriptionModal: "Sei sicuro di voler cambiare il ruolo di questo utente?",
    search: "Cerca utente o ruolo",
  },
  languagePicker: {
    italian: "ITA",
    english: "ENG",
  },
};
