import CustomDivider from "../CustomDivider";
import { useCallback, useState } from "react";
import FormCertifications from "./FormCertifications";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import { Empty, notification } from "antd";
import { useTranslation } from "react-i18next";
import { ICertificationsApi, IErrorData } from "../../interfaces";
import { initialCertifications } from "../models/initialCertifications";
import { formatDateIntoNumber } from "../../utils/formatDate";
import { usePostCertificationsMutation } from "../../services/api";
import CardCertifications from "./CardCertifications";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import _ from "lodash";

interface ICertificationsProps {
  title: string;
}

const Certifications = ({ title }: ICertificationsProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [tempCertifications, setTempCertifications] =
    useState<ICertificationsApi>(initialCertifications);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();
  const [postCertifications, { isLoading }] = usePostCertificationsMutation();
  const user = useSelector((state: RootState) => state.user);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const handleVisibleEditBox = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);

  const closeForm = useCallback(() => {
    setIsEdit(false);
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.description",
      ),
      NotificationTypes.WARNING,
    );
  }, [api, t]);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: ICertificationsApi) =>
      setTempCertifications((prev) => {
        setIsValid(!!allValues.releaseYear && !!allValues.name);
        return { ...prev, ...allValues };
      }),
    [],
  );

  const handleOnFinish = useCallback(async () => {
    const objCertifications = {
      name: tempCertifications.name,
      url: tempCertifications.url,
      releaseYear: formatDateIntoNumber(
        tempCertifications.releaseYear as number,
      ),
      expireYear: formatDateIntoNumber(tempCertifications.expireYear as number),
    };
    const response = await postCertifications({
      content: objCertifications,
      id: idEmployee.employeeId,
    });
    setIsEdit(false);
    setIsValid(false);

    if ("error" in response && "data" in response.error) {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.error.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.success.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.success.description",
      ),
      NotificationTypes.SUCCESS,
    );
  }, [
    api,
    idEmployee.employeeId,
    postCertifications,
    t,
    tempCertifications.expireYear,
    tempCertifications.name,
    tempCertifications.releaseYear,
    tempCertifications.url,
  ]);

  const showNotification = useCallback(
    (title: string, description: string, type: NotificationTypes) => {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(title),
        t(description),
        type,
      );
    },
    [api, t],
  );

  return (
    <>
      <CustomDivider title={title} onEdit={handleVisibleEditBox} isInsert />
      {contextHolder}
      {isEdit && (
        <FormCertifications
          closeForm={closeForm}
          handleOnChangeTemp={handleOnChangeTemp}
          handleOnFinish={handleOnFinish}
          isValid={isValid}
          isLoading={isLoading}
          title={"tutorial.insertBtn"}
          releaseYear={formatDateIntoNumber(
            tempCertifications.releaseYear as number,
          )}
        />
      )}
      {user.certifications.length ? (
        user.certifications.map((certification, index) => (
          <CardCertifications
            user={user}
            key={_.uniqueId("id_")}
            certification={certification}
            index={index}
            showNotification={showNotification}
          />
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("homePage.certificationSection.emptyCertification")}
        />
      )}
    </>
  );
};
export default Certifications;
