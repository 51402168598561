import { Button, Flex, Form, Input, Modal } from "antd";
import styles from "./ModalDownloadCV.module.css";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { IDynamicObjectSensitiveFields } from "../../interfaces/IPdfParams";
import { useCallback, useMemo, useState } from "react";
import { IModalDownloadCVEmployee } from "../../interfaces";
import _ from "lodash";

const ModalDownloadCV = ({
  sensitiveFields,
  handleOnFinish,
  setFormValues,
  setIsModalOpen,
  isModalOpen,
  form,
}: IModalDownloadCVEmployee) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);

  const handleFormChange = useCallback(
    (_: any, allValues: IDynamicObjectSensitiveFields) => {
      setFormValues(allValues);
      const allFieldsFilled = sensitiveFields?.every(
        (field) => !!allValues[field.name],
      );
      setIsDisabled(!allFieldsFilled);
    },
    [sensitiveFields, setFormValues],
  );

  const closeModal = useCallback(() => {
    form.resetFields();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  const sensitiveFormFields = useMemo(
    () =>
      sensitiveFields?.map((label) => (
        <Form.Item
          className={styles.formItem}
          key={_.uniqueId("id_")}
          name={label.name}
          label={label.label}
          rules={[
            {
              required: true,
              message: t("errormsg.genericInput"),
            },
          ]}
        >
          <Input placeholder={label.label} />
        </Form.Item>
      )),
    [sensitiveFields, t],
  );

  return (
    <Modal
      title={t("pdfOverview.titleModalPdfSensitiveFields")}
      open={isModalOpen}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        onValuesChange={handleFormChange}
        className={styles.form}
        onFinish={handleOnFinish}
        form={form}
      >
        {sensitiveFormFields}
        <Form.Item>
          <Flex
            gap="small"
            align="baseline"
            style={{ width: "100%" }}
            justify="flex-end"
          >
            <Flex gap="small" wrap="wrap">
              <Button
                type="primary"
                size={"middle"}
                htmlType="submit"
                disabled={isDisabled}
              >
                {t("pdfOverview.download")}
                <FaDownload style={{ marginLeft: 8 }} />
              </Button>
              <Button
                size={"middle"}
                onClick={() => form.resetFields()}
                disabled={isDisabled}
              >
                {t("homePage.professionalExperienceSection.form.clearButton")}
              </Button>
            </Flex>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalDownloadCV;
