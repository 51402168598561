import { useTranslation } from "react-i18next";
import styles from "./ButtonDownload.module.css";
import { FaDownload } from "react-icons/fa6";
import { LoadingOutlined } from "@ant-design/icons";

interface IButtonDownload {
  isDownload: boolean;
  handleClick: () => void;
}

const ButtonDownload = ({ isDownload, handleClick }: IButtonDownload) => {
  const { t } = useTranslation();

  return (
    <button
      className={styles.buttonOpenPdf}
      onClick={() => handleClick()}
      disabled={isDownload}
    >
      {t("pdfOverview.download")}
      {!isDownload ? (
        <FaDownload className={styles.iconDownload} />
      ) : (
        <LoadingOutlined />
      )}
    </button>
  );
};

export default ButtonDownload;
