import styles from "./Loading.module.css";
import { LoadingType } from "./LoadingType";

interface LoadingProps {
  type: LoadingType;
  loading: boolean;
}

const LoadingModify = (): JSX.Element => <span className={styles.loader}></span>

const LoadingGeneal = (): JSX.Element => <p>Loading</p>

const LoadingMap: Record<LoadingType, JSX.Element> = {
    [LoadingType.GENERAL] : LoadingGeneal(),
    [LoadingType.MODIFY] : LoadingModify(),
}

const Loading = ({ type, loading }: LoadingProps) => {
  return loading ? 
    <div className={styles.loadingContainer}>
      {
        LoadingMap[type]
      }
    </div> : null
  ;
};

export default Loading;
