import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentsComponent } from "../constants/ContentsComponentEnum";

export interface ComponentState {
  component: ContentsComponent;
}

export const initialState: ComponentState = {
  component: ContentsComponent.USEREDITOR,
};

export const componentTempalteEditorSlice = createSlice({
  name: "ComponentTemplateEditor",
  initialState: initialState,
  reducers: {
    setComponentEditor(state, action: PayloadAction<ContentsComponent>) {
      state.component = action.payload;
    },
  },
});
export const { setComponentEditor } = componentTempalteEditorSlice.actions;
export default componentTempalteEditorSlice.reducer;
