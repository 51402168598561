import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../slices/userSlice";
import userInfoSlice from "../slices/tutorialSlice";
import themeSlice from "../slices/themeSlice";
import { api } from "../services/api";
import tokenSlice from "../slices/tokenSlice";
import componentAdminSlice from "../slices/componentNavigationSidebarAdminSlice";
import infoEmployeeSlice from "../slices/infoEmployeeSlice";
import componentNavigationSidebarTemplateEditor from "../slices/componentNavigationSidebarTemplateEditor";
import errorApiCallSlice from "../slices/errorApiCallSlice";

const store = configureStore({
  reducer: {
    userInfoSlice: userInfoSlice,
    user: userSlice,
    theme: themeSlice,
    token: tokenSlice,
    component: componentAdminSlice,
    infoEmployee: infoEmployeeSlice,
    componentTemplateEditor: componentNavigationSidebarTemplateEditor,
    errorApiCallSlice: errorApiCallSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
