import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialThemeState, ITheme } from "../components/models/themeType";

export const themeSlice = createSlice({
    name: "theme",
    initialState: initialThemeState,
    reducers: {
        setTheme: (state, action: PayloadAction<ITheme>) => {
            state.themeName = action.payload.themeName;
        },
    },
});
export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
