import { Avatar, Badge } from "antd";
import { MdOutlineModeEdit } from "react-icons/md";
import styles from "./AvatarSidebar.module.css";
import { getInitials } from "../../../utils/getInitials";
import { SizeBreackpoints } from "../../../constants/EnumBreakpoints";

const breakpoints = {
  xs: SizeBreackpoints.SMALL,
  sm: SizeBreackpoints.SMALL,
  md: SizeBreackpoints.MEDIUM,
  lg: SizeBreackpoints.LARGE,
  xl: SizeBreackpoints.LARGE,
  xxl: SizeBreackpoints.EXTRALARGE,
};

interface IAvatarSidebar {
  name: string;
  surname: string;
  id?: string;
}

const AvatarSidebar = ({ name, surname, id }: IAvatarSidebar) => {
  return (
    <div className={styles.avatarContainer}>
      <Badge
        count={id && <MdOutlineModeEdit className={styles.editIcon} />}
        offset={[-25, 25]}
      >
        <Avatar
          className={`${styles.avatar} ${id ? styles.opacity : ""}`}
          size={breakpoints}
          shape="circle"
        >
          {getInitials(`${name} ${surname}`)}
        </Avatar>
      </Badge>
    </div>
  );
};
export default AvatarSidebar;
