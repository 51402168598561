import "./Steps.css";
import styles from "./TutorialPage.module.css";
import { useCallback, useState } from "react";
import {
  Col,
  Row,
  Button,
  Steps,
  Switch,
  Flex,
  Spin,
  notification,
} from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons/";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import aithoSymbol from "./../../../icon/aitho-symbol.svg";
import Education from "../education";
import Recap from "../recap";
import Languages from "../languages";
import AithoCV from "../../../components/aithoname/AithoCV";
import eduIcon from "./../../../icon/edu-icon.svg";
import langIcon from "./../../../icon/lang-icon.svg";
import { RootState } from "../../../store/store";
import { setTheme } from "../../../slices/themeSlice";
import { Theme } from "../../../constants/ThemeEnum";
import { usePostUserInfoByIdMutation } from "../../../services/api";
import { openNotification } from "../../../components/Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../../components/Notification/utils/constants";
import { IErrorData } from "../../../interfaces";

const Tutorial = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const stepsTutorial = [
    {
      title: t("tutorial.tutorialPage.steps.stepTwo"),
    },
    {
      title: t("tutorial.tutorialPage.steps.stepThree"),
    },
    {
      title: t("tutorial.tutorialPage.steps.stepFour"),
    },
  ];
  const [current, setCurrent] = useState(0);
  const isCompleted = useSelector(
    (state: RootState) => state.userInfoSlice.isCompleted,
  );
  const education = useSelector(
    (state: RootState) => state.userInfoSlice.education,
  );
  const languages = useSelector(
    (state: RootState) => state.userInfoSlice.languages,
  );
  const user = useSelector((state: RootState) => state.user);
  const [postUserInfoById, { isLoading }] = usePostUserInfoByIdMutation();
  const dispatch = useDispatch();
  const isDark = localStorage.getItem("theme") !== Theme.LIGHT;

  const educationToNumber = education.map((item) => {
    return {
      ...item,
      graduationYear: item.graduationYear ? parseInt(item.graduationYear) : 0,
    };
  });

  const next = useCallback(
    async (current: number) => {
      if (current === 2) {
        const response = await postUserInfoById({
          languages: languages.map((lang) => {
            return {
              name: lang.name,
              level:
                lang.level === t("tutorial.languagePage.native")
                  ? "native"
                  : lang.level,
            };
          }),

          educations: educationToNumber,
        });
        if ("error" in response && "data" in response.error) {
          openNotification(
            NotificationPosition.TOP_RIGHT,
            api,
            t(
              "homePage.professionalExperienceSection.form.notifications.error.title",
            ),
            (response.error.data as IErrorData).message,
            NotificationTypes.ERROR,
          );
          return;
        }
      } else if (current < 2) {
        if (isCompleted === true) {
          setCurrent((prevCurrent) => prevCurrent + 1);
        } else {
          return null;
        }
      }
    },
    [api, educationToNumber, isCompleted, languages, postUserInfoById, t],
  );

  const prev = useCallback((current: number) => {
    current === 0
      ? setCurrent(0)
      : setCurrent((prevCurrent) => prevCurrent - 1);
  }, []);

  const page = useCallback(() => {
    switch (current) {
      case 0:
        return <Education image={eduIcon} />;
      case 1:
        return <Languages image={langIcon} />;
      case 2:
        return <Recap />;
      default:
        return <h1>No pages found</h1>;
    }
  }, [current]);

  const onThemeChange = useCallback(
    (checked: boolean) => {
      dispatch(
        setTheme({
          themeName: checked ? Theme.DARK : Theme.LIGHT,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={styles.bg}>
      {contextHolder}
      <Flex align="flex-start" justify="space-between">
        <Col xl={21} lg={21} md={21} sm={21}>
          <p className={styles.titleLambda}>
            {t("tutorial.tutorialPage.paragraphs.tutorialTitle")} <AithoCV />,{" "}
            {user?.name} {user?.surname}
          </p>
          {current !== 3 ? (
            <div className={styles.containerTutorial}>
              <div className={styles.symbolBox}>
                <img
                  src={aithoSymbol}
                  className={styles.aithoSymbol}
                  alt="symbol"
                />
              </div>
              <div>
                <p className={styles.subtitleLambda}>
                  <span>
                    {t("tutorial.tutorialPage.paragraphs.tutorialSubtitle")}
                  </span>
                </p>
              </div>
            </div>
          ) : null}
        </Col>
        <Flex align="center" gap={10} style={{ padding: "0 20px 0 0" }}>
          <p className={styles.theme}>{t("darkMode")}</p>
          <Switch defaultChecked={isDark} onChange={onThemeChange} />
        </Flex>
      </Flex>
      <Row>
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          style={{ paddingRight: 24, paddingLeft: 24 }}
        >
          <Steps
            type="default"
            current={current}
            items={stepsTutorial}
            className={styles.stepsBg}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} className={styles.layout}>
          {page()}
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24}>
          <div className={styles.buttonContainer}>
            {current === 0 || current === 5 ? null : (
              <LeftOutlined
                onClick={() => prev(current)}
                className={styles.leftButton}
              />
            )}
            <Button
              type="primary"
              size="large"
              className={styles.nextBtn}
              onClick={() => next(current)}
              disabled={!isCompleted || isLoading}
            >
              {isLoading && <Spin size="large" />}
              {t("tutorial.tutorialPage.nextBtn")} <RightOutlined />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Tutorial;
