import dayjs from "dayjs";
import { IEducation } from "../../../../interfaces";

const initialFormValues = (specialization: IEducation) => ({
  qualification: specialization.qualification,
  institution: specialization.institution,
  studyField: specialization.studyField,
  graduationYear:
    specialization.graduationYear &&
    dayjs(specialization.graduationYear.toString(), "YYYY"),
});

export default initialFormValues;
