import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentsComponent } from "../constants/ContentsComponentEnum";
import { initialState } from "../components/models/componentType";

export const componentAdminSlice = createSlice({
  name: "ComponentAdmin",
  initialState: initialState,
  reducers: {
    setComponent(state, action: PayloadAction<ContentsComponent>) {
      state.component = action.payload;
    },
    resetComponent(state) {
      state.component = null;
    },
  },
});
export const { setComponent, resetComponent } = componentAdminSlice.actions;
export default componentAdminSlice.reducer;
