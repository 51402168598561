import { useTranslation } from "react-i18next";
import { Button, Popconfirm, Spin, Table } from "antd";
import { Columns } from "../../../constants/ColumnsEnum";
import { useGetLayoutPdfQuery } from "../../../services/api";
import { ColumnsType } from "antd/es/table";
import { IErrorData, IGetPdfName } from "../../../interfaces";
import styles from "./TableTemplate.module.css";
import { FiArrowDown, FiTrash, FiEdit } from "react-icons/fi";
import { RiArrowLeftRightLine, RiEdit2Line } from "react-icons/ri";
import dayjs from "dayjs";
import { FormatDate } from "../../../constants/FormatDateEnum";
import ErrorBox from "../../ErrorBox";
import { useCallback, useState } from "react";
import ModalSensitiveFields from "./ModalSensitiveFields";
import ModalDownload from "./ModalDownload";
import useTemplateActions from "./hook/useTemplateActions";
import { TemplateActions } from "../constants/enum";
import ModalUpdate from "./ModalUpdate";
import useLogOut from "../../../hooks/useLogOut";

const TableTemplate = () => {
  const { data, isLoading, isFetching, error } = useGetLayoutPdfQuery();
  const { t } = useTranslation();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [isModalOpenSensitiveFields, setIsModalOpenSensitiveFields] =
    useState(false);
  const [isModalOpenDownload, setIsModalOpenDownload] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [selectedRecordName, setSelectedRecordName] = useState<string>("");
  const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false);

  const { confirm, cancel, isErrorDelete, isLoadingDelete, isLoadingPatch } =
    useTemplateActions(setDeleteId);

  const logOut = useLogOut();

  const openUpdateModal = useCallback((id: string) => {
    setIsModalOpenUpdate(true);
    setSelectedRecordId(id);
  }, []);

  const handleDownloadClick = useCallback((record: IGetPdfName) => {
    const { id, name } = record;
    setSelectedRecordId(id);
    setIsModalOpenDownload(true);
    setSelectedRecordName(name);
  }, []);

  const handleSensitiveFieldsClick = useCallback((id: string) => {
    setSelectedRecordId(id);
    setIsModalOpenSensitiveFields(true);
  }, []);

  const columns: ColumnsType<IGetPdfName> = [
    {
      title: t("tutorial.personalDataPage.nameLabel"),
      dataIndex: Columns.NAME,
      key: Columns.NAME,
      sorter: (a: IGetPdfName, b: IGetPdfName) => a.name.localeCompare(b.name),
      defaultSortOrder: Columns.SORT,
      sortDirections: [],
    },
    {
      title: t("pdfOverview.isAnonymizable"),
      dataIndex: Columns.ISANONYMIZABLE,
      key: Columns.ISANONYMIZABLE,
      render: (_, record) => (
        <p>
          {record.isAnonymizable ? t("pdfOverview.yes") : t("pdfOverview.no")}
        </p>
      ),
    },
    {
      title: t("pdfOverview.sensitiveFields"),
      dataIndex: Columns.SENSITIVEFIELDS,
      key: Columns.SENSITIVEFIELDS,
      render: (_, record) => (
        <p>
          {record.sensitiveFields.length
            ? t("pdfOverview.containsSensitiveData")
            : t("pdfOverview.notContainsSensitiveData")}
        </p>
      ),
    },
    {
      title: t("homePage.lastEdit"),
      dataIndex: Columns.LASTMODIFIED,
      key: Columns.LASTMODIFIED,
      render: (_, record) => (
        <p>{`${dayjs(record.lastModifiedDate).format(
          FormatDate.DATE_MONTH_Y,
        )}`}</p>
      ),
    },
    {
      title: t("homePage.status"),
      key: Columns.STATUS,
      render: (_, record) => (
        <p>
          {record.isDraft
            ? t("homePage.professionalExperienceSection.visualization.draft")
            : t("homePage.professionalExperienceSection.visualization.online")}
        </p>
      ),
    },

    {
      title: t("pdfOverview.choose"),
      key: Columns.CHOOSE,
      render: (_, record) => (
        <div className={styles.buttons}>
          <Button type="primary" onClick={() => handleDownloadClick(record)}>
            <FiArrowDown />
            {t("pdfOverview.downloadTable")}
          </Button>

          <Button
            type="primary"
            onClick={() => handleSensitiveFieldsClick(record.id)}
          >
            <FiEdit />
            {t("pdfOverview.addSensitiveData")}
          </Button>

          <Popconfirm
            title={t("pdfOverview.titleDeletePopup")}
            description={t("pdfOverview.descriptionDeletePopup")}
            onConfirm={() => confirm(record.id, TemplateActions.DELETE)}
            onCancel={() => cancel(TemplateActions.DELETE)}
            okText={t("pdfOverview.yes")}
            cancelText={t("pdfOverview.no")}
          >
            <Button
              type="primary"
              disabled={
                !isErrorDelete && (deleteId === record.id || isLoadingDelete)
              }
            >
              <FiTrash />
              {!isErrorDelete && deleteId === record.id && (
                <Spin size="large" />
              )}
              {t("pdfOverview.delete")}
            </Button>
          </Popconfirm>
          <Popconfirm
            title={
              record.isDraft
                ? t(
                    "homePage.professionalExperienceSection.visualization.draft",
                  )
                : t(
                    "homePage.professionalExperienceSection.visualization.online",
                  )
            }
            description={
              record.isDraft
                ? t(
                    "homePage.professionalExperienceSection.visualization.changeStatusOnline",
                  )
                : t(
                    "homePage.professionalExperienceSection.visualization.changeStatusDraft",
                  )
            }
            onConfirm={() =>
              confirm(record.id, TemplateActions.PATCHDRAFT, record.isDraft)
            }
            onCancel={() => cancel(TemplateActions.PATCHDRAFT)}
            okText={t("pdfOverview.yes")}
            cancelText={t("pdfOverview.no")}
          >
            <Button type="primary" disabled={isLoadingPatch}>
              <RiArrowLeftRightLine />
              {t(
                "homePage.professionalExperienceSection.visualization.buttonStatus",
              )}
            </Button>
          </Popconfirm>
          <Button type="primary" onClick={() => openUpdateModal(record.id)}>
            <RiEdit2Line />
            {t("tutorial.editBtn")}
          </Button>
        </div>
      ),
    },
  ];

  if (error && "data" in error) {
    return (
      <ErrorBox
        title={t("homePage.professionalExperienceSection.visualization.error")}
        description={(error.data as IErrorData).message}
        onRetry={logOut}
        text={t("homePage.professionalExperienceSection.visualization.refresh")}
      />
    );
  }

  return (
    <div className={styles.tableTemplate}>
      <h3 className={styles.title}>{t("pdfOverview.editTemplate")}</h3>

      <Table
        className={styles.table}
        columns={columns}
        dataSource={data}
        loading={isLoading || isFetching}
      />
      <ModalDownload
        isModalOpenDownload={isModalOpenDownload}
        setIsModalOpenDownload={setIsModalOpenDownload}
        idTemplate={selectedRecordId}
        templateName={selectedRecordName}
      />
      <ModalSensitiveFields
        isModalOpen={isModalOpenSensitiveFields}
        setIsModalOpen={setIsModalOpenSensitiveFields}
        idTemplate={selectedRecordId}
      />
      <ModalUpdate
        isModalOpenUpdate={isModalOpenUpdate}
        setIsModalOpenUpdate={setIsModalOpenUpdate}
        selectedRecordId={selectedRecordId}
      />
    </div>
  );
};

export default TableTemplate;
