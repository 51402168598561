import { Modal, notification, Select } from "antd";
import { Columns } from "../../constants/ColumnsEnum";
import styles from "./RoleManagment.module.css";
import { useTranslation } from "react-i18next";
import {
  useGetSearchUserRoleQuery,
  usePatchUpdateUserRoleMutation,
} from "../../services/api";
import { RoleOptions, RoleEnum } from "../../constants/RoleEnum";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import { IErrorData } from "../../interfaces";
import { IUserRole } from "../models/IUserRole";
import { useCallback, useState } from "react";
import TablePaginationSearch from "../TablePaginationSearch";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import Search from "antd/es/input/Search";

const { Option } = Select;

const RoleManagment = () => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [pageValue, setPageValue] = useState(0);
  const {
    data: userRoles,
    error: errorRole,
    isLoading: isLoadingRole,
    isFetching: isFetchingRole,
  } = useGetSearchUserRoleQuery({
    page: pageValue,
    size: 10,
    value: inputValue,
  });
  const [updateUserRole, { isLoading }] = usePatchUpdateUserRoleMutation();
  const [api, contextHolder] = notification.useNotification();
  const user = useSelector((state: RootState) => state.user);

  const onSearch = useCallback(
    (value: string) => {
      setInputValue(value);
      setPageValue(0);
    },
    [setInputValue, setPageValue],
  );

  const columns = [
    {
      title: t("homePage.generalInfoSection.labels.userName"),
      dataIndex: Columns.NAME,
      key: Columns.NAME,
    },
    {
      title: t("homePage.generalInfoSection.labels.userSurname"),
      dataIndex: Columns.SURNAME,
      key: Columns.SURNAME,
      sorter: (a: IUserRole, b: IUserRole) =>
        a.surname.localeCompare(b.surname),
      defaultSortOrder: Columns.SORT,
      sortDirections: [],
      showSorterTooltip: false,
    },
    {
      title: t("homePage.generalInfoSection.labels.userEmail"),
      dataIndex: Columns.EMAIL,
      key: Columns.EMAIL,
    },
    {
      title: t("homePage.generalInfoSection.labels.userRoleManagment"),
      dataIndex: Columns.ROLE,
      key: Columns.ROLE,
      render: (_: unknown, record: IUserRole) => {
        return (
          <Select
            value={record.role as RoleEnum}
            style={{ width: 150 }}
            onChange={(value: RoleEnum) => showConfirm(record.email, value)}
            disabled={record.email === user.email}
          >
            {RoleOptions.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const handleRoleUpdate = useCallback(
    async (email: string, newRole: RoleEnum) => {
      if (newRole) {
        const response = await updateUserRole({ email, newRole });

        if ("error" in response && "data" in response.error) {
          openNotification(
            NotificationPosition.TOP_RIGHT,
            api,
            t(
              "homePage.professionalExperienceSection.form.notifications.error.title",
            ),
            (response.error.data as IErrorData).message,
            NotificationTypes.ERROR,
          );
          return;
        }
        openNotification(
          NotificationPosition.TOP_RIGHT,
          api,
          t(
            "homePage.professionalExperienceSection.form.notifications.success.title",
          ),
          t("roles.successUpdateMessage"),
          NotificationTypes.SUCCESS,
        );
      }
    },
    [api, t, updateUserRole],
  );

  const showConfirm = useCallback(
    (email: string, newRole: RoleEnum) => {
      Modal.confirm({
        okButtonProps: {
          className: styles.customOkButton,
          disabled: isLoading,
        },
        cancelButtonProps: { className: styles.customCancelButton },
        className: styles.customModal,
        title: t("roles.titleModal"),
        content: t("roles.descriptionModal"),
        okText: t("pdfOverview.yes"),
        cancelText: t("pdfOverview.no"),
        onOk() {
          handleRoleUpdate(email, newRole);
        },
      });
    },
    [handleRoleUpdate, isLoading, t],
  );

  return (
    <>
      {contextHolder}
      <TablePaginationSearch
        setPageValue={setPageValue}
        columns={columns}
        dataMapping={userRoles?.content as IUserRole[]}
        data={userRoles}
        isLoading={isLoadingRole}
        isFetching={isFetchingRole}
        pageValue={pageValue}
        error={errorRole}
        title={t("roles.employeeRoleManagement")}
      >
        <Search
          placeholder={t("roles.search")}
          allowClear
          style={{ width: "350px" }}
          onSearch={onSearch}
        />
      </TablePaginationSearch>
    </>
  );
};

export default RoleManagment;
