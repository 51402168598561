import i18n from "../../../../i18n";

export enum SkillsLevels {
  BASIC = "Basic",
  MODERATE = "Moderate",
  ADVANCE = "Advance",
}

interface ISkillsLevel {
  value: SkillsLevels;
  label: string;
}

export const languageLevelsList: ISkillsLevel[] = [
  {
    value: SkillsLevels.BASIC,
    label: i18n.t("homePage.softSkills.softSkillsLevel.basic"),
  },
  {
    value: SkillsLevels.MODERATE,
    label: i18n.t("homePage.softSkills.softSkillsLevel.moderate"),
  },
  {
    value: SkillsLevels.ADVANCE,
    label: i18n.t("homePage.softSkills.softSkillsLevel.advance"),
  },
];
