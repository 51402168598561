import { useCallback } from "react";
import {
  useLazyGetDownloadHtmlQuery,
  useLazyGetDownloadLogoQuery,
} from "../../../../../services/api";
import { downloadMappingFile, DownloadType } from "./constants/constants";
import { IUseDownloadTemplate } from "../../../../../interfaces";

const useDownloadTemplate = ({
  templateName,
  idTemplate,
}: IUseDownloadTemplate) => {
  const [
    getDownload,
    { isFetching: isFetchingHtml, isLoading: isLoadingHtml, error: errorHtml },
  ] = useLazyGetDownloadHtmlQuery();
  const [
    getDownloadLogo,
    { isFetching: isFetchingLogo, isLoading: isLoadingLogo, error: errorLogo },
  ] = useLazyGetDownloadLogoQuery();

  const downloadFile = useCallback(
    async (fileType: DownloadType) => {
      let response;

      if (fileType === DownloadType.HTML) {
        response = await getDownload(idTemplate);
      }
      if (fileType === DownloadType.JPEG) {
        response = await getDownloadLogo(idTemplate);
      }

      if (response && response.data) {
        const blob = new Blob([(response as { data: BlobPart }).data], {
          type: downloadMappingFile[fileType],
        });

        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${templateName}.${fileType}`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    },
    [getDownload, getDownloadLogo, idTemplate, templateName],
  );

  return {
    downloadFile,
    isFetchingHtml,
    isLoadingHtml,
    errorHtml,
    isFetchingLogo,
    isLoadingLogo,
    errorLogo,
  };
};

export default useDownloadTemplate;
