import "./App.css";
import { useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import Login from "./pages/login/Login";
import Tutorial from "./pages/tutorial/tutorialPage/TutorialPage";
import UserEditor from "./pages/userEditor";
import PdfOverview from "./pages/pdfOverview/PdfOverview";
import { RoutesPath } from "./constants/RoutesEnum";
import { setTheme } from "./slices/themeSlice";
import { RootState } from "./store/store";
import { Colors, ColorsDark } from "./constants/ColorsEnum";
import PdfViewer from "./pages/pdfViewer";
import { Theme } from "./constants/ThemeEnum";
import { useSetPageTitle } from "./hooks/useSetPageTitle";
import Redirect from "./components/Redirect/Redirect";
import SuccessPage from "./pages/tutorial/successPage/SuccessPage";
import successIcon from "./icon/success-icon.svg";
import Admin from "./pages/admin";
import {
  AdminRedirect,
  ProtectedRoute,
  TutorialRedirect,
} from "./components/ProtectNavigations";

function App() {
  const dispatch = useDispatch();
  const currentTheme = useSelector((state: RootState) => state.theme.themeName);
  const [firstSaving, setFirstSaving] = useState(false);
  const { t } = useTranslation();

  useSetPageTitle();

  const storeTheme = useCallback(() => {
    if (currentTheme) {
      localStorage.setItem("theme", currentTheme);
    }
  }, [currentTheme]);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme && !firstSaving) {
      dispatch(setTheme({ themeName: storedTheme }));
      setFirstSaving(true);
    } else if (currentTheme !== storedTheme) storeTheme();

    document.querySelector("#root")?.setAttribute("data-theme", currentTheme);
  }, [currentTheme, dispatch, firstSaving, storeTheme]);

  const {
    AITHO_RED,
    AITHO_DARK_RED,
    AITHO_TOMATO_RED,

    AITHO_BG_1,
    AITHO_BG_2,

    AITHO_TEXT_1,
    AITHO_TEXT_2,
    AITHO_TEXT_3,
    AITHO_TEXT_HOVER,
    AITHO_TEXT_DISABLED,

    WHITE,
    BLACK,
    TRANSPARENT,
  } = currentTheme === Theme.LIGHT ? Colors : ColorsDark;

  const tokenDark = {
    fontFamily: "Manrope Variable",
    colorBgBase: BLACK,
    colorPrimaryBg: AITHO_BG_1,
    colorTextBase: WHITE,
    colorPrimary: AITHO_RED,
    borderRadius: 5,
  };

  const tokenLight = {
    fontFamily: "Manrope Variable",
    colorBgBase: WHITE,
    colorTextBase: BLACK,
    colorPrimary: AITHO_RED,
    borderRadius: 5,
  };

  const components = {
    Descriptions: {
      titleColor: AITHO_TEXT_1,
    },
    Tabs: {
      colorBorderSecondary: AITHO_TEXT_3,
    },
    Checkbox: {
      colorBgContainer: AITHO_BG_2,
      colorBorder: AITHO_TEXT_2,
    },
    DatePicker: {
      colorBgContainer: AITHO_BG_2,
      colorBorder: TRANSPARENT,
      colorText: AITHO_TEXT_1,
      colorTextPlaceholder: AITHO_TEXT_HOVER,
    },
    Steps: {
      colorPrimary: AITHO_BG_2,
      colorText: AITHO_TEXT_2,
      colorTextDescription: AITHO_TEXT_2,
      colorTextLightSolid: AITHO_RED,
      fontSizeLG: 24,
      iconSize: 56,
      titleLineHeight: 55,
      iconFontSize: 18,
      colorTextLabel: AITHO_TEXT_2,
    },
    Input: {
      colorText: AITHO_TEXT_1,
      colorBgContainer: AITHO_BG_2,
      colorBorder: TRANSPARENT,
      colorTextPlaceholder: AITHO_TEXT_HOVER,
      fontSize: 15,
    },
    Button: {
      borderRadiusLG: 50,
      borderRadius: 5,

      // PRIMARY
      colorPrimary: AITHO_RED,
      colorPrimaryHover: AITHO_DARK_RED,
      colorPrimaryActive: AITHO_TOMATO_RED,
      primaryShadow: "0",

      // DEFAULT
      defaultBg: AITHO_BG_1,
      defaultColor: AITHO_TEXT_1,
      defaultBorderColor: AITHO_RED,
      defaultShadow: "0",

      // DISABLED
      borderColorDisabled: AITHO_TEXT_DISABLED,
      colorTextDisabled: AITHO_TEXT_DISABLED,
      colorBgContainerDisabled: TRANSPARENT,
    },
    Select: {
      selectorBg: AITHO_BG_2,
      colorText: AITHO_TEXT_1,
      colorBorder: TRANSPARENT,
      colorTextPlaceholder: AITHO_TEXT_HOVER,
      optionSelectedBg: AITHO_BG_2,
    },
    Form: {
      labelColor: AITHO_TEXT_1,
      colorText: BLACK,
    },
  };

  return (
    <ConfigProvider
      theme={{
        token: currentTheme === Theme.LIGHT ? tokenLight : tokenDark,
        components: components,
      }}
    >
      <Routes>
        <Route path={RoutesPath.ROOT} element={<Login />} />
        <Route
          path={RoutesPath.TUTORIAL}
          element={
            <ProtectedRoute>
              <TutorialRedirect>
                <Tutorial />
              </TutorialRedirect>
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.SUCCESS_PAGE}
          element={
            <ProtectedRoute>
              <TutorialRedirect>
                <SuccessPage
                  title={t("tutorial.tutorialPage.successPageTitle")}
                  image={successIcon}
                />
              </TutorialRedirect>
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.USEREDITOR}
          element={
            <ProtectedRoute>
              <TutorialRedirect>
                <UserEditor />
              </TutorialRedirect>
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.PDF_OVERVIEW}
          element={
            <ProtectedRoute>
              <PdfOverview />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutesPath.ADMIN}
          element={
            <ProtectedRoute>
              <AdminRedirect>
                <Admin />
              </AdminRedirect>
            </ProtectedRoute>
          }
        />
        <Route path={RoutesPath.LOGIN} element={<Redirect />} />

        <Route path={RoutesPath.PDF} element={<PdfViewer />} />

        <Route path="*" element={<Navigate to={RoutesPath.ROOT} />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
