export const initialState: TokenPayload = {
  idToken: null,
  exp: null,
  iat: null,
  name: "",
  role: "",
  sub: "",
  surname: "",
};

export interface IToken {
  token: string;
}

export interface TokenPayload {
  idToken: string | null;
  exp: number | null;
  iat: number | null;
  name: string;
  role: string;
  sub: string;
  surname: string;
}
