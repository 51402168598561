import styles from "./FormLanguagesContent.module.css";
import { Form, Select } from "antd";
import {
  Language,
  LanguageNameList,
  languageLevelsList,
  languageNameList,
} from "../utils/constants";
import { useTranslation } from "react-i18next";
import { LangType } from "../../models/langType";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IFormLanguagesContentProps } from "../../../interfaces";
import { SelectFlagMap } from "../../../pages/tutorial/languages/utils/constants";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";

const FormLanguagesContent = ({
  languages,
  index,
  setLanguages,
}: IFormLanguagesContentProps) => {
  const { t } = useTranslation();
  const [selectLanguageList, setSelectLanguageList] =
    useState<LanguageNameList>([]);

  const initialValuesSelect = {
    name: languages[index].name,
    level:
      languages[index].level === "native"
        ? t("tutorial.languagePage.native")
        : languages[index].level,
  };

  useEffect(() => {
    setSelectLanguageList(
      languageNameList.filter(
        (lang) => !languages.some((language) => language.name === lang.value),
      ),
    );
  }, [languages]);

  const handleOnChangeTemp = useCallback(
    (_: any, values: LangType) => {
      setLanguages((prev) => {
        const previous = [...prev];
        if (values.level === t("tutorial.languagePage.native")) {
          previous[index] = { ...values, level: "native" };
        } else {
          previous[index] = values;
        }

        return previous;
      });
    },
    [index, setLanguages, t],
  );

  const showDeleteIcon = useMemo(
    () => _.values(languages[index]).some(_.isNull),
    [index, languages],
  );

  const removeEmptyLanguage = useCallback(() => {
    setLanguages((prevLanguages) =>
      prevLanguages.filter(
        (language) => language.name !== null && language.level !== null,
      ),
    );
  }, [setLanguages]);

  return (
    <Form
      className={styles.form}
      initialValues={initialValuesSelect}
      onValuesChange={handleOnChangeTemp}
    >
      <Form.Item className={styles.langFormItem} name={Language.NAME}>
        <Select
          showSearch={true}
          options={selectLanguageList.map((lang) => {
            return {
              ...lang,
              label: (
                <span>
                  {SelectFlagMap[lang.label]} {lang.label}
                </span>
              ),
            };
          })}
          placeholder={t("tutorial.languagePage.placeholderLangSelect")}
        />
      </Form.Item>
      <Form.Item className={styles.langFormItem} name={Language.LEVEL}>
        <Select
          options={[
            ...languageLevelsList,
            {
              value: t("tutorial.languagePage.native"),
              label: t("tutorial.languagePage.native"),
            },
          ]}
          placeholder={t("tutorial.languagePage.placeholderLvlSelect")}
        />
      </Form.Item>

      {showDeleteIcon && (
        <DeleteOutlined className={styles.icon} onClick={removeEmptyLanguage} />
      )}
    </Form>
  );
};

export default FormLanguagesContent;
