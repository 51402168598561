import { Button, Flex, Form, Input, Modal, Select } from "antd";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetLiveSearchQuery,
  useGetOccupationsQuery,
} from "../../../services/api";
import { IApiParams, IErrorData } from "../../../interfaces";
import _ from "lodash";
import { filteredTools } from "../../../utils/filteredTools";
import styles from "./ModalSearchUser.module.css";
import { BsSearch } from "react-icons/bs";
import { NameFormEnum } from "./constants/NameFormEnum";

interface IModalSearchProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setChangeValue: Dispatch<
    SetStateAction<{
      name: string;
      professionalProfile: string;
      technicalSkill: string[];
    }>
  >;
  setPageValue: Dispatch<SetStateAction<number>>;
}

const ModalSearchUser = ({
  isModalOpen,
  setIsModalOpen,
  setChangeValue,
  setPageValue,
}: IModalSearchProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isDisable, setIsDisable] = useState(true);
  const { data: dataOccupations, error } = useGetOccupationsQuery();
  const [selectedTools, setSelectedTools] = useState<string[]>([]);
  const { data: dataLiveSearch, error: errorLiveSearch } =
    useGetLiveSearchQuery();
  const [liveSearch, setLiveSearch] = useState<string[] | undefined>([]);
  const [value, setValue] = useState({
    name: "",
    professionalProfile: "",
    technicalSkill: [""],
  });

  const closeModal = useCallback(() => {
    form.resetFields();
    setIsModalOpen(false);
    setIsDisable(true);
  }, [form, setIsModalOpen]);

  const clearModal = useCallback(() => {
    form.resetFields();
    setChangeValue({
      name: "",
      professionalProfile: "",
      technicalSkill: [""],
    });
    setIsModalOpen(false);
    setIsDisable(true);
  }, [form, setChangeValue, setIsModalOpen]);

  const handleOnChange = useCallback((_: any, allValues: IApiParams) => {
    setValue((prev) => {
      return { ...prev, ...allValues };
    });
    const isAnyFieldPopulated =
      allValues.name?.trim() !== "" ||
      allValues.professionalProfile?.trim() !== "" ||
      (allValues.technicalSkill && allValues.technicalSkill[0].trim() !== "");

    setIsDisable(!isAnyFieldPopulated);
  }, []);

  const handleOnFinish = useCallback(() => {
    const objParams = {
      name: value.name,
      professionalProfile: value.professionalProfile,
      technicalSkill: value.technicalSkill,
    };
    setChangeValue(objParams);
    setPageValue(0);
    setIsModalOpen(false);
  }, [
    setChangeValue,
    setIsModalOpen,
    setPageValue,
    value.name,
    value.professionalProfile,
    value.technicalSkill,
  ]);

  return (
    <Modal
      title={t("homePage.search")}
      open={isModalOpen}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        className={styles.form}
        form={form}
        onValuesChange={handleOnChange}
        onFinish={handleOnFinish}
      >
        <Form.Item
          name={NameFormEnum.NAME}
          label={t("homePage.generalInfoSection.labels.userName")}
          className={styles.formItem}
        >
          <Input placeholder={t("homePage.namePlaceholderSearch")} />
        </Form.Item>
        <Form.Item
          name={NameFormEnum.PROFESSIONALPROFILE}
          label={t("homePage.roleSection.title")}
          className={styles.formItem}
        >
          <Select
            showSearch={true}
            allowClear
            filterOption={(input, option) =>
              (option?.props.children as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={t("homePage.rolePlaceholderSearch")}
            notFoundContent={
              error &&
              "data" in error && (
                <p>
                  {t(
                    "homePage.professionalExperienceSection.visualization.error",
                  )}
                  <br />
                  {(error.data as IErrorData).message}
                </p>
              )
            }
          >
            {dataOccupations?.map((level) => (
              <Select.Option key={_.uniqueId("id_")} value={level}>
                {level}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={NameFormEnum.TECHNICALSKILL}
          label={t("homePage.technicalSkillsSection.title")}
          className={styles.formItem}
        >
          <Select
            onSelect={() => setLiveSearch([])}
            listItemHeight={32}
            mode="tags"
            placeholder={t("homePage.technicalSkillsPlaceholderSearch")}
            value={selectedTools}
            onChange={(selected) => setSelectedTools(selected)}
            onSearch={(input) =>
              filteredTools(input, dataLiveSearch, setLiveSearch)
            }
            notFoundContent={
              errorLiveSearch &&
              "data" in errorLiveSearch && (
                <p>
                  {t(
                    "homePage.professionalExperienceSection.visualization.error",
                  )}
                  <br />
                  {(errorLiveSearch.data as IErrorData).message}
                </p>
              )
            }
          >
            {liveSearch?.map((tool) => (
              <Select.Option key={_.uniqueId("id_")} value={tool}>
                {tool}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Flex
            gap="small"
            align="baseline"
            style={{ width: "100%", marginTop: "10px" }}
            justify="flex-end"
          >
            <Button
              type="primary"
              size="middle"
              htmlType="submit"
              className={styles.iconButton}
              disabled={isDisable}
            >
              {t("homePage.searchButton")}
              <BsSearch />
            </Button>

            <Button
              size="middle"
              type="primary"
              onClick={clearModal}
              disabled={isDisable}
            >
              {t("homePage.professionalExperienceSection.form.clearButton")}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalSearchUser;
