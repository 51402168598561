export enum TemplateActions {
  DELETE = "delete",
  PATCHDRAFT = "patchDraft",
}

export enum LabelName {
  TEMPLATENAME = "templateName",
  TEMPLATEFILE = "templateFile",
  LOGO = "logo",
  ISANONYMIZABLE = "isAnonymizable",
}
