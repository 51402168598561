import { Button } from "antd";
import styles from "./ErrorBox.module.css";
import React from "react";

interface ErrorBoxProps {
  title: string;
  description?: string;
  onRetry?: () => void;
  text?: string;
}

const ErrorBox = ({ title, description, onRetry, text }: ErrorBoxProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.errorBox}>
        <h2>{title}</h2>
        <p>{description}</p>
        {text && <Button onClick={onRetry}>{text}</Button>}
      </div>
    </div>
  );
};

export default React.memo(ErrorBox);
