import styles from "./EducationPage.module.css";
import { useEffect, useState } from "react";
import { Col, Form, Row, Flex, DatePickerProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { eduType } from "../../../components/models/eduType";
import Results from "../../../components/Results/Results";
import {
  Completed,
  addEducation,
  editEducation,
  removeEducation,
} from "../../../slices/tutorialSlice";
import EduHeader from "../../../components/TutorialEduHeader/EduHeader";
import EduForm from "../../../components/TutorialEduForm/EduForm";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface IEducationPage {
  image: string;
}
const Education = ({ image }: IEducationPage) => {
  const { t } = useTranslation();
  const [qualificationEducation, setQualificationEducation] =
    useState<string>("");
  const [descriptionEducation, setDescriptionEducation] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [place, setPlace] = useState<string>("");
  const [form] = Form.useForm();
  const [educationListInfo, setEducationListInfo] = useState<eduType[]>([]);
  const educationUser = useSelector(
    (state: RootState) => state.userInfoSlice.education,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    setEducationListInfo(educationUser);
    educationUser.length === 0
      ? dispatch(Completed(false))
      : dispatch(Completed(true));
  }, [dispatch, educationUser, educationListInfo]);

  const handleQualificationChange = (event: any) => {
    setQualificationEducation(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescriptionEducation(event.target.value);
  };
  const handlePlaceChange = (event: any) => {
    setPlace(event.target.value);
  };

  const handleDataChange: DatePickerProps["onChange"] = (_, dateString) => {
    setDate(dateString);
  };

  const handleDelete = (index: number) => {
    dispatch(removeEducation(index));
  };

  const handleSave = (
    index: number,
    editedQualification?: string,
    place?: string,
    editedDescription?: string,
    graduationYear?: string,
  ) => {
    dispatch(
      editEducation({
        index,
        editedEducation: {
          ...educationListInfo[index],
          qualification: editedQualification,
          institution: place,
          studyField: editedDescription,
          graduationYear: graduationYear,
        },
      }),
    );
  };

  const handleInsert = async () => {
    try {
      await form.validateFields();
      const newEducation = {
        qualification: qualificationEducation,
        institution: place,
        studyField: descriptionEducation,
        graduationYear: date,
      };

      dispatch(addEducation(newEducation));

      form.resetFields();

      setQualificationEducation("");
      setDescriptionEducation("");
      setDate("");
      setPlace("");
      dispatch(Completed(true));
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <Row style={{ height: "100%" }}>
      <p className={styles.sectionDescription}>
        {t("tutorial.educationPage.sectionDescription")}
      </p>
      <Flex justify="center" align="center" style={{ width: "50%" }}>
        <Col>
          <EduForm
            date={date}
            descriptionEducation={descriptionEducation}
            placeEducation={place}
            form={form}
            handlePlaceChange={handlePlaceChange}
            handleDataChange={handleDataChange}
            handleDescriptionChange={handleDescriptionChange}
            handleInsert={handleInsert}
            handleQualificationChange={handleQualificationChange}
            qualificationEducation={qualificationEducation}
          />
        </Col>
      </Flex>
      {educationListInfo.length > 0 ? (
        <Flex
          vertical
          align="start"
          justify="start"
          style={{ height: "100%", width: "50%" }}
        >
          <EduHeader />
          <Flex
            align="center"
            justify="center"
            vertical
            gap="10px"
            style={{ width: "100%" }}
          >
            {educationListInfo.map((item, index) => (
              <Results
                key={_.uniqueId("id_")}
                index={index}
                item={item}
                onDelete={handleDelete}
                onSave={handleSave}
              />
            ))}
          </Flex>
        </Flex>
      ) : (
        <Flex
          align="center"
          justify="center"
          style={{ width: "50%", height: "100%" }}
        >
          <img src={image} alt="icon" className={styles.icon} />
        </Flex>
      )}
    </Row>
  );
};

export default Education;
