import React from 'react'

const AithoCV = () => {
  return (
    <b>
        <span className="lambda">Λ</span>ITHO{" "}
        <span className="lambda">CV</span>
    </b>
  )
}

export default AithoCV