import cvIcon from "../../icon/cv-icon.png";
import styles from "./Header.module.css";

interface IHeaderProps {
  description?: string;
  title: string;
}

const Header = ({ description, title }: IHeaderProps) => {
  return (
    <div className={styles.header}>
      <img className={styles.cvIcon} src={cvIcon} alt="cvIcon" />
      <h4 className={styles.title}>
        {title} <br /> <br />
        {description}
      </h4>
    </div>
  );
};

export default Header;
