import {
  Avatar,
  Button,
  Empty,
  Flex,
  Form,
  List,
  notification,
  Switch,
} from "antd";
import { useGetLayoutPdfQuery } from "../../services/api";
import styles from "./ExportPDFEmployees.module.css";
import { ConvertBase64 } from "../../constants/ConvertBase64";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IErrorData, IGetPdfName } from "../../interfaces";
import ModalDownloadCV from "../ModalDownloadCV";
import pdfIconTop from "../../icon/png.icon2.svg";
import pdfIconBottom from "../../icon/pdf.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useHandleClickTemplateCard from "../../hooks";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import ErrorBox from "../ErrorBox";
import WarningDialog from "../WarningDialog";
import useLogOut from "../../hooks/useLogOut";
import { RoutesPath } from "../../constants/RoutesEnum";

const ExportPDFEmployees = () => {
  const { data, isLoading, isFetching, error } = useGetLayoutPdfQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [item, setItem] = useState<null | IGetPdfName>(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [formValuesSensitiveFields, setFormValuesSensitiveFields] = useState(
    {},
  );
  const [isAnonymous, setIsAnonymous] = useState<Record<string, boolean>>({});
  const info = useSelector((state: RootState) => state.infoEmployee);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const { isFetchingAdmin, isLoadingAdmin, errorAdmin } =
    useHandleClickTemplateCard();

  const logOut = useLogOut();

  const closeWarningDialogModal = useCallback(() => {
    setIsWarningDialogOpen(false);
  }, []);

  if (errorAdmin && "data" in errorAdmin) {
    openNotification(
      NotificationPosition.TOP_LEFT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.error.title",
      ),
      (errorAdmin.data as IErrorData).message,
      NotificationTypes.ERROR,
    );
  }

  const openModalConfirm = useCallback((item: IGetPdfName) => {
    setItem(item);
    setIsWarningDialogOpen(true);
  }, []);

  const preDownload = useCallback(() => {
    if (item?.sensitiveFields.length) {
      setIsModalOpen(true);
    } else {
      navigate(RoutesPath.PDF, {
        state: {
          templateName: item?.name,
          sensitiveFields: item?.sensitiveFields,
          isAnonymous: isAnonymous[item?.id as string],
          employeeEmail: info.email,
        },
      });
    }
    setIsWarningDialogOpen(false);
  }, [
    info.email,
    isAnonymous,
    item?.id,
    item?.name,
    item?.sensitiveFields,
    navigate,
  ]);

  useEffect(() => {
    if (data) {
      data.forEach((item) => {
        setIsAnonymous((prev) => ({
          ...prev,
          [item.id]: false,
        }));
      });
    }
  }, [data]);

  const onChange = useCallback(
    (id: string) => {
      setIsAnonymous((prev) => ({
        ...prev,
        [id]: !isAnonymous[id],
      }));
    },
    [isAnonymous],
  );

  const handleOnFinish = useCallback(() => {
    if (item) {
      navigate(RoutesPath.PDF, {
        state: {
          templateName: item.name,
          sensitiveFields: formValuesSensitiveFields,
          isAnonymous: isAnonymous[item.id],
          employeeEmail: info.email,
        },
      });
    }
    setIsModalOpen(false);
    form.resetFields();
  }, [
    form,
    formValuesSensitiveFields,
    navigate,
    info.email,
    isAnonymous,
    item,
  ]);

  if (error && "data" in error) {
    return (
      <ErrorBox
        title={t("homePage.professionalExperienceSection.visualization.error")}
        description={(error.data as IErrorData).message}
        onRetry={logOut}
        text={t("homePage.professionalExperienceSection.visualization.refresh")}
      />
    );
  }

  return (
    <>
      {contextHolder}
      <h3 className={styles.titleExportPDFEmployees}>
        {`${t("pdfOverview.downloadEmployeeName")} ${info.name} ${
          info.surname
        }`}
      </h3>
      {data?.every((template) => template.isDraft) ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("emptyTemplate")}
        />
      ) : (
        <List
          className={styles.list}
          loading={isLoading || isFetching}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) =>
            !item.isDraft && (
              <div key={item.id}>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    item.isAnonymizable && (
                      <Flex align="center" gap={15}>
                        <p>{t("pdfOverview.isAnonymousCVEmployee")}</p>
                        <Switch
                          onChange={() => onChange(item.id)}
                          defaultChecked={isAnonymous[item.id]}
                        />
                      </Flex>
                    ),

                    item.sensitiveFields.length ? (
                      <p>{t("pdfOverview.containsSensitiveDataEmployee")}</p>
                    ) : null,

                    <Button
                      key={item.id}
                      type="primary"
                      size={"middle"}
                      onClick={() => openModalConfirm(item)}
                      disabled={isFetchingAdmin || isLoadingAdmin}
                    >
                      {t("pdfOverview.downloadTable")}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    className={styles.listItemAvatar}
                    avatar={
                      <Avatar
                        size={60}
                        src={`${ConvertBase64.CONVERTBASE64}${item.logo.data}`}
                        shape="circle"
                      />
                    }
                    title={item.name}
                  />
                </List.Item>
                <div className={styles.line}></div>
              </div>
            )
          }
        />
      )}

      <img src={pdfIconTop} alt="img" className={styles.pdfIconTop} />
      <img src={pdfIconBottom} alt="img" className={styles.pdfIconBottom} />
      <ModalDownloadCV
        sensitiveFields={item?.sensitiveFields}
        handleOnFinish={handleOnFinish}
        setFormValues={setFormValuesSensitiveFields}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        form={form}
      />
      <WarningDialog
        isWarningDialogOpen={isWarningDialogOpen}
        closeWarningDialogModal={closeWarningDialogModal}
        preDownload={preDownload}
        title={t(
          "homePage.professionalExperienceSection.form.notifications.warning.title",
        )}
        okText={t("continueBtn")}
        cancelText={t("cancelBtn")}
        description={t("pdfOverview.loadTemplatePlaceholder.alertModalNDA")}
      />
    </>
  );
};

export default ExportPDFEmployees;
