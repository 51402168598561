import { IProExperience } from "../../../interfaces";

export const initialProExperience: IProExperience = {
  isNDA: false,
  role: "",
  company: "",
  finalCustomer: "",
  finalCustomerNDA: "",
  projectName: "",
  description: "",
  tools: [],
  place: "",
  onGoing: true,
  startDate: "",
  endDate: "",
  priority: 1,
  id: "1",
  engRole: "",
  engCompany: "",
  engFinalCustomer: "",
  engFinalCustomerNDA: "",
  engProjectName: "",
  engDescription: "",
  engTools: "",
};
