export enum Colors {
    AITHO_RED = "#EF233C",
    AITHO_DARK_RED = "#be1d30",
    AITHO_TOMATO_RED = "#A63241",
    AITHO_LIGHT_RED = "#c54b5b",

    AITHO_BG_1 = "#f8f8f8",
    AITHO_BG_2 = "#e9e9e9",
    AITHO_BG_3 = "#c7c7c7",
    AITHO_BG_4 = "#6d6d6d",

    AITHO_BORDER_HOVER = "#252535",
    AITHO_BORDER_ACTIVE = "#333347",

    AITHO_TEXT_1 = "#222222",
    AITHO_TEXT_2 = "#232324",
    AITHO_TEXT_3 = "#494747",
    AITHO_TEXT_HOVER= "#afafaf",
    AITHO_TEXT_DISABLED= "#d9d9d9",

    WHITE = "#ffffff",
    GREY = "grey",
    BLACK = "black",
    TRANSPARENT = "transparent"
}





export enum ColorsDark{
    AITHO_RED = "#EF233C",
    AITHO_DARK_RED = "#be1d30",
    AITHO_TOMATO_RED = "#A63241",
    AITHO_LIGHT_RED = "#c54b5b",

    AITHO_BG_1 = "#1C1C21",
    AITHO_BG_2 = "#26262C",
    AITHO_BG_3 = "#252535",

    AITHO_BORDER_HOVER = "#333347",
    AITHO_BORDER_ACTIVE = "#252534",

    AITHO_TEXT_1 = "#d1d1d1",
    AITHO_TEXT_2 = "#7F7F83",
    AITHO_TEXT_3 = "#707070",
    AITHO_TEXT_HOVER= "#686868",

    AITHO_TEXT_DISABLED= "#535353",

    WHITE = "#ffffff",
    GREY = "grey",
    BLACK = "black",
    TRANSPARENT = "transparent"
}