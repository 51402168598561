import { useMemo, useState } from "react";
import { Tag, Button } from "antd";
import _ from "lodash";
import styles from "./ExpandableTagList.module.css";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useTranslation } from "react-i18next";

interface IExpandableTagListProps {
  tags: string[];
}

const MAX_VISIBLE_TAGS = 15;

const ExpandableTagList = ({ tags }: IExpandableTagListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const visibleTags = useMemo(
    () => (isExpanded ? tags : tags?.slice(0, MAX_VISIBLE_TAGS)),
    [isExpanded, tags],
  );

  return (
    <>
      {visibleTags?.map((item) => (
        <Tag key={_.uniqueId("id_")} style={{ fontSize: "15px" }}>
          {item}
        </Tag>
      ))}
      {tags?.length > MAX_VISIBLE_TAGS && (
        <Button
          type="link"
          onClick={handleToggle}
          className={styles.buttonLink}
        >
          {isExpanded ? (
            <p>
              {t(
                "homePage.professionalExperienceSection.visualization.viewButtonUp",
              )}
              <BsChevronUp style={{ fontSize: "20px" }} />
            </p>
          ) : (
            <p>
              {t(
                "homePage.professionalExperienceSection.visualization.viewButtonDown",
              )}
              <BsChevronDown style={{ fontSize: "20px" }} />
            </p>
          )}
        </Button>
      )}
    </>
  );
};

export default ExpandableTagList;
