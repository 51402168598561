export const en = {
  login: {
    placeholderEmail: "Email",
    placeholderPassword: "Password",
    placeholderUsername: "Username",
    placeholderLoginBtn: "Log In",
    placeholderOpenId: "Open ID",
    privacyMessage: `I authorize the processing of my personal data present in the CV in accordance with art. 13 of Legislative Decree 30 June 2003, no. 196 "Code regarding the protection of personal data" and art. 13 of the GDPR (EU Regulation 2016/679).`,
  },
  tooltip: {
    tooltipQualification:
      "Enter your qualification. For example: Bachelor's degree, High school diploma, Master's degree, etc.",
    tooltipInstitution:
      "Enter the institution attended. For example: State High School, University, I.T.C. Luigi Sturzo, etc.",
    tooltipFieldStudy:
      "Enter the field of study pursued. For example: Computer Science, Classical Studies, Computer Engineering, Scientific Studies, etc.",
    tooltipYearAttainment: "Enter the year of attainment.",
    tooltipLanguage:
      "Enter the language. For example: English, French, Spanish, etc.",
    tooltipLevel:
      "Enter the proficiency level. Choose from Native Speaker, C1, C2, B1, B2, etc. Please enter at least one native language.",
  },
  tutorial: {
    tutorialPage: {
      steps: {
        stepOne: "Who are you?",
        stepTwo: "Education",
        stepThree: "Language",
        stepFour: "Summary",
        stepFive: "Finish",
      },
      paragraphs: {
        tutorialTitle: "Welcome to",
        tutorialSubtitle: "To start, fill in the following fields:",
      },
      nextBtn: "Next",
      successPageTitle:
        "Congratulations! You have completed the personal information section!",
      successPageButton: "Enter AithoCV",
    },
    personalDataPage: {
      sectionDescription: "I am AithoCV, who are you?",
      birthDatePlaceholder: "Select date",
      nameLabel: "Name",
      surnameLabel: "Surname",
      nameInputPlaceholder: "Enter name",
      surnameInputPlaceholder: "Enter surname",
    },

    insertBtn: "Add",
    editBtn: "Edit",
    educationPage: {
      sectionDescription:
        "Enter the educational paths you have taken (e.g., Diploma, Degree):",
      labelQualification: "Qualification",
      placeholderQualification: "e.g., Diploma, Undergraduate degree",
      placeholderPlace: "es. University of Catania",
      labelPlace: "Institution",
      labelDescription: "Study field",
      placeholderDescription: "e.g., Computer technician, Computer engineering",
      labelDate: "Year of attainment",
      placeholderRangePicker: "Select year",
      placeholderAddonFrom: "From: ",
      placeholderAddonTo: "To: ",
      tableHeader: {
        qualification: "Qualification",
        place: "Institution",
        description: "Study field",
        date: "Year",
      },
    },
    languagePage: {
      emptyLanguages: "There are no languages added",
      sectionDescription: "What languages do you speak?",
      labelLanguage: "Language",
      placeholderLangSelect: "Select language",
      labelLevel: "Level",
      placeholderLvlSelect: "Level",
      native: "Native language",
      tableHeader: {
        language: "Language",
        level: "Level",
      },
      languageList: {
        english: "English",
        french: "French",
        german: "German",
        italian: "Italian",
        russian: "Russian",
        ukrainian: "Ukrainian",
        spanish: "Spanish",
        portuguese: "Portuguese",
        chinese_mandarin: "Chinese Mandarin",
      },
    },
    recapPage: {
      userDataTitle: "Who you are:",
      educationTitle: "Education:",
      languagesTitle: "Languages:",
      recapTitle: "Is everything correct?",
    },
  },
  homePage: {
    welcome: "Welcome to AITHOJobJourney!",
    welcomeAdmin: "Employee Resume Management",
    welcomeMessage:
      "Manage your resume easily and quickly. Update your experiences whenever you want and download a PDF copy ready to be shared with employers. Simple, fast, and professional.",
    welcomeMessageAdmin:
      "You are editing an employee's resume. Update and modify their experiences",
    logoutBtn: "Log out",
    exportPdf: "Export PDF",
    impersonationSidebarName: "You are editing the CV of:",
    lastEdit: "Last Edit",
    status: "Status",
    editRole: "Manage roles",
    changeProfile: "Go to your home",
    titleAdminPage: "All employees",
    search: "Search for employee, role or skill",
    searchButton: "Search",
    filter: "Filter",
    namePlaceholderSearch:
      "Enter the name or first and last name of the employee to search for",
    rolePlaceholderSearch: "Enter the role of the employee to search for",
    technicalSkillsPlaceholderSearch:
      "Enter the technical skills of the employee to search for",
    generalInfoSection: {
      sectionTitle: "PERSONAL DATA",
      labels: {
        userName: "Name",
        userSurname: "Surname",
        userNameAndSurname: "Name and Surname",
        userEmail: "Email",
        userPhoneNumber: "Phone",
        userGitHub: "GitHub",
        userCity: "City",
        userLinkedIn: "LinkedIn",
        userRoleManagment: "Role Managment",
      },
      placeholders: {
        userPlaceholder: "Enter Name and Surname",
        emailPlaceholder: "Enter email",
        phonePlaceholder: "Enter phone number",
        gitPlaceholder: "GitHub URL",
        cityPlaceholder: "City",
        linkedInPlaceholder: "LinkedIn URL",
      },
      titles: {
        generality: "General Information",
        contacts: "Contacts",
      },
    },
    roleSection: {
      sectionTitle: "ROLE",
      title: "Role",
      labelProfessionalProfile: "Professional profile",
      placeholderProfessionalProfile: "es. Frontend",
      emptyRole: "No roles entered",
      tooltip: "You can add a maximum limit of two roles",
      alertLimit: "Maximum role limit reached",
    },
    professionalExperienceSection: {
      sectionTitle: "PROFESSIONAL EXPERIENCES",
      years: "years",
      year: "year",
      month: "month",
      months: "months",
      form: {
        itaTab: "Italian",
        engTab: "English",
        addButton: "Add",
        cancelButton: "Cancel",
        clearButton: "Clear",
        labels: {
          role: "Role",
          place: "Place",
          finalCustomer: "Final customer",
          finalCustomerNDA: "Final client under NDA",
          company: "Employer",
          projectName: "Project Name",
          tools: "Technologies used",
          description: "Description",
          currentProjectCheckBoxLabel: "Currently hold this role",
          currentNDACheckBoxLabel:
            "Was this professional experience subject to a Non-Disclosure Agreement (NDA)?",
          startDateLabel: "Start Date",
          endDateLabel: "End Date",
          visible: "View professional experience",
          invisible: "Do not display professional experience",
          tooltipVisible:
            "By choosing not to display professional experience, it will not be shown in the PDF",
        },
        placeholders: {
          rolePlaceholder: "Enter your role in the project",
          finalCustomerPlaceholder: "Client's name",
          finalCustomerNDAPlaceholder: "Undisclosed client",
          placeAgencyPlaceholder: "Company place",
          companyPlaceholder: "Employer",
          projectNamePlaceholder: "Example: FabrikWeb",
          toolsPlaceholder: "Enter the technology used and press enter",
          descriptionPlaceholder: "Describe what you did in the project",
          startDatePlaceholder: "Start Date",
          endDatePlaceholder: "End Date",
        },
        tooltip: {
          role: "Indicate the position you were hired for in this professional experience.",
          company:
            "Enter the name of the company you worked for. If you are currently employed by Aitho and your role involves collaborations with other clients (e.g., you were hired by Capgemini for a project at Nexi), enter Aitho as the company.",
          place:
            "Specify the location of the company where you worked or are currently working, for example, Catania for Aitho.",
          finalCustomer:
            "Enter the name of the final client for whom you completed the work.",
          finalCustomerNDA:
            "Enter the name of the final client for whom you completed the work. In cases of collaborations involving multiple companies (e.g., you were hired by Capgemini to work on a project for Nexi), the final client is Nexi. Note: If you have signed an NDA, make sure to use the correct terminology specified in the contract. In some cases, the company may have signed the NDA on behalf of the employees, so it is advisable to check with administration for clarification.",
          projectName:
            "Specify the name of the project. If the project is covered by an NDA and the name might reveal the client's identity, use generic descriptions such as Website, Mobile Application, etc.",
          tools: "List all the technologies used in the project.",
          description: "Describe the tasks you performed within this project.",
        },
        notifications: {
          success: {
            title: "Ok!",
            description: "Insertion successful.",
          },
          successPatch: {
            title: "Ok!",
            description: "Edit made",
          },
          successProfessionalProfile: {
            title: "Ok!",
            description: "Insertion or modification made.",
          },
          successDelete: {
            title: "OK!",
            description: "Professional experience deleted",
          },
          successDeleteEducation: {
            title: "OK!",
            description: "Education deleted",
          },
          successDeleteLanguage: {
            title: "OK!",
            description: "Language deleted",
          },
          successDeleteCertification: {
            title: "OK!",
            description: "Certification deleted",
          },
          successDeleteSoftSkills: {
            title: "OK!",
            description: "Soft skills deleted",
          },
          warning: {
            title: "Attention!",
            description: "Insertion canceled.",
          },
          warningProfessionalProfile: {
            title: "Attention!",
            description: "Insertion or Edit canceled.",
          },
          warningPatch: {
            title: "Attention!",
            description: "Edit canceled.",
          },
          error: {
            title: "Error!",
            description: "Insertion failed.",
          },
          errorPut: {
            title: "Error!",
            description: "Insertion or modification failed.",
          },
          errorDelete: {
            title: "Error!",
            description: "Impossible to delete the professional experience",
          },
          errorDeleteEducation: {
            title: "Error!",
            description: "Impossible to delete or modify the education",
          },
          errorDeleteCertification: {
            title: "Errore!",
            description: "Impossible to delete the certification",
          },
          errorDeleteLanguage: {
            title: "Error!",
            description: "Impossible to delete the language",
          },
          errorEditCertification: {
            title: "Error!",
            description: "Impossible to modify the certification",
          },
          errorPatch: {
            title: "Error!",
            description: "Impossible to edit the professional experience",
          },
          errorProfessionalProfile: {
            title: "Error!",
            description: "Impossible to add or modify the role.",
          },
          errorDeleteSoftSkills: {
            title: "Error!",
            description: "Impossible to delete the soft skills.",
          },
          errorEditSoftSkills: {
            title: "Error!",
            description: "Impossible to edit the soft skills.",
          },
          errorSpecialCharacters: "Cannot insert special characters",
          warningLogout: "You have been disconnected. Please log in again.",
        },
      },
      visualization: {
        emptyExperiences: "No experiences found",
        today: "Today",
        ongoing: "Ongoing",
        error: "Error loading data",
        refresh: "Refresh",
        draft: "Draft",
        online: "Online",
        changeStatusDraft:
          "Are you sure you want to change the template status from online to draft?",
        changeStatusOnline:
          "Are you sure you want to change the template status from draft to online?",
        buttonStatus: "Change state",
        viewButtonUp: "View less",
        viewButtonDown: "View more",
      },
    },
    educationSection: {
      sectionTitle: "EDUCATION",
      emptyEducation: "No education inserted",
    },
    languagesSection: {
      sectionTitle: "LANGUAGES",
    },
    certificationSection: {
      sectionTitle: "CERTIFICATION",
      emptyCertification: "No certification",
      certificationsNameLabel: "Type of certification",
      certificationsUrlLabel: "Certification link",
      certificationsReleaseYearLabel: "Year of release",
      certificationsExpireYearLabel: "Expiration year",
      certificationsNamePlaceholder: "Enter the type of certification",
      certificationsUrlPlaceholder: "Enter the link of your certification",
      certificationsReleaseYearPlaceholder: "Enter the year of release",
      certificationsExpireYearPlaceholder: "Enter the expiration year",
      linkCertification: "Certification link",
    },
    technicalSkillsSection: {
      sectionTitle: "TECHNICAL SKILLS",
      title: "Technical Skills",
      programmingLanguagesLabel: "Programming languages",
      programmingLanguagesPlaceholder: "Ex. Java, PHP, JavaScript, etc",
      frameworksLabel: "Frameworks",
      frameworksPlaceholder: "Ex. React, Angular, Spring, etc",
      databasesLabel: "Databases",
      databasesPlaceholder: "Ex. MySQL, MongoDB, Oracle Database, etc",
      operativeSystemsLabel: "Operative systems",
      operativeSystemsPlaceholder: "Ex. Microsoft Windows, Mac OS, Linux, etc",
      softwareLabel: "Software",
      softwarePlaceholder:
        "Ex. Visual Studio Code, IntelliJ IDEA, Adobe Photoshop, etc",
      emptySoftware: "Nessun software inserito",
      emptyProgrammingLanguages: "No programming language entered",
      emptyFrameworks: "No frameworks entered",
      emptydatabases: "No databases entered",
      emptyoperativeSystems: "No operative systems entered",
      emptyTechnicalSkills: "There are no technical skills",
    },
    softSkills: {
      sectionTitle: "SOFT SKILLS",
      nameSoftSkillsLabel: "Skill",
      nameSoftSkillsPlaceholder: "es. Teamwork, Creativity",
      levelSoftSkillsLabel: "Level",
      levelSoftSkillsPlaceholder: "es. Basic",
      emptySoftSkills: "No relational skills included",
      softSkillsLevel: {
        basic: "Basic",
        moderate: "Moderate",
        advance: "Advance",
      },
    },
  },
  pdfOverview: {
    addTemplate: "Add template",
    editTemplate: "Template management",
    templateTitleSidebar: "Template",
    pdfTitle: "CV -",
    back: "Back to home",
    backAllEmployees: "Return to the All Employees table",
    layouts: "Layouts",
    describe: "Download your CV",
    download: "Download",
    downloadEmployeeName: "Download CV",
    errorTitle: "Error",
    errorType: "Error in retrieving PDF",
    description:
      "Choose from a selection of templates for your resume and get a customized version with just one click! Easy and quick to use.",
    titlePdfOverview: "Choose your CV",
    isAnonymousCV: "Make your name anonymous",
    isAnonymousCVEmployee: "Make the employee's name anonymous",
    isAnonymizable: "It can be anonymized",
    sensitiveFields: "Sensitive data",
    notIsAnonymousCV: "Do not make your name anonymous",
    titleModalPdfSensitiveFields: "Sensitive Fields",
    yes: "Yes",
    no: "No",
    containsSensitiveData: "Contains sensitive data",
    containsSensitiveDataEmployee: "Contains the insertion of sensitive data",
    notContainsSensitiveData: "It does not contain sensitive data",
    choose: "Choose",
    delete: "Delete",
    downloadTable: "Download",
    addSensitiveData: "Add sensitive data",
    deleteTemplate: "Template deleted",
    notDeleteTemplate: "Template not deleted",
    titleDeletePopup: "Delete template",
    descriptionDeletePopup: "Are you sure you want to delete the template?",
    modalDownloadTitle:
      "Choose whether to download the logo or the HTML template",
    buttonDownloadLogo: "Download logo",
    buttonDownloadTemplate: "Download HTML template",
    modalUpdateTitle: "Edit the logo or HTML template",
    buttonUpdateLogo: "Edit the logo",
    buttonUpdateTemplate: "Edit the HTML template",
    loadTemplateLabel: {
      templateNameLabel: "Template name",
      templateFileLabel: "Template file",
      templateLogoLabel: "Template logo",
      templateIsAnonymizableLabel: "Possibility to make it anonymous",
      labelIt: "Label in Italian",
      labelEn: "Label in English",
    },
    loadTemplatePlaceholder: {
      alertModalNDA:
        "If you have worked for a client with whom you signed a non-disclosure agreement (NDA), please remember not to include the client's name or project details if they could reveal the client's identity. In some cases, the company may have signed the NDA on behalf of the employees, so it is advisable to check with administration for clarification. If in doubt, we recommend carefully reviewing the information entered or consulting the signed agreement before proceeding with the CV download. Thank you.",
      documentationTemplateLink:
        "Consult the documentation to insert templates",
      templateNamePlaceholder: "Add the name of the template",
      templateFilePlaceholder: "Upload the template file in html format",
      templateLogoPlaceholder:
        "Upload the template logo in png, jpg or jpeg format",
      modalSensitiveFieldsNamePlaceholder:
        "Enter the name of the variable that will be used in the already loaded template",
      labelItPlaceholder:
        "Enter what to request as a sensitive field in Italian",
      labelEnPlaceholder:
        "Enter what to request as a sensitive field in English",
    },
    layoutsName: {
      layoutNameAitho: "Layout Aitho",
      layoutNameIntesa: "Layout Intesa",
      layoutName3: "Layout 3",
    },
  },
  emptyTemplate: "No template loaded",
  saveBtn: "Save",
  saveEditBtn: "Save changes",
  cancelBtn: "Cancel",
  continueBtn: "Continue",
  darkMode: "Dark mode",
  actions: "Actions",
  cv: "CV",
  profile: "Profile",
  info: "Info",
  errormsg: {
    genericInput: "Fill in the field.",
    genericDate: "Date required.",
    missingPword: "Enter your password.",
    missingUsername: "Enter your username.",
    missingPhoneNumber: "Enter your phone number.",
    invalidPhoneNumber: "Your phone number is not valid.",
    missingEmail: "Enter your email.",
    invalidEmail: "Your email is not valid.",
    missingGitHub: "Enter GitHub link.",
    invalidGitHub: "Your GitHub link is not valid.",
    missingLinkedIn: "Enter LinkedIn link.",
    invalidLinkedIn: "Your LinkedIn link is not valid.",
  },
  notifications: {
    errorTitle: "Error",
    warningTitle: "Attention",
    successTitle: "Ok",
  },
  roles: {
    updateRole: "Update Role",
    employeeRoleManagement: "Employee Role Management",
    successUpdateMessage: "Role updated successfully",
    errorUpdateMessage: "Error updating role",
    errorNotSelectedRole: "Error, role not selected",
    titleModal: "Confirmation of role change",
    descriptionModal: "Are you sure you want to change this user's role?",
    search: "Search for user or role",
  },
  languagePicker: {
    italian: "ITA",
    english: "ENG",
  },
};
