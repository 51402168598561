import styles from "./Results.module.css";
import React, { useCallback, useState } from "react";
import { Button, Flex, DatePickerProps } from "antd";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import { Colors } from "../../constants/ColorsEnum";
import TableRow from "./TableRow";
import ResultEdu from "../ResultEdu/ResultEdu";
import ResultLang from "../ResultLang/ResultLang";
import { useTranslation } from "react-i18next";
import { IResultsProps } from "../../interfaces";

const { AITHO_TEXT_3, AITHO_RED } = Colors;
const Results: React.FC<IResultsProps> = ({
  item,
  index,
  onDelete,
  onSave,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedQualification, setEditedQualification] = useState<string>(
    item.qualification ?? "",
  );
  const [editedDescription, setEditedDescription] = useState<string>(
    item.studyField ?? "",
  );
  const [editedPlace, setEditedPlace] = useState<string>(
    item.institution ?? "",
  );
  const [editedLanguage, setEditedLanguage] = useState<string>(item.name ?? "");
  const [editedLevel, setEditedLevel] = useState<string>(item.level ?? "");

  const [editedGraduationYear, setEditedGraduationYear] = useState<string>(
    item.graduationYear ?? "",
  );

  const handleSave = useCallback(() => {
    if (!item.level) {
      onSave(
        index,
        editedQualification,
        editedPlace,
        editedDescription,
        editedGraduationYear,
      );
      setIsEditing(false);
    } else {
      onSave(index, editedLanguage, editedLevel);
      setIsEditing(false);
    }
  }, [
    editedDescription,
    editedGraduationYear,
    editedLanguage,
    editedLevel,
    editedPlace,
    editedQualification,
    index,
    item.level,
    onSave,
  ]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditGraduation: DatePickerProps["onChange"] = (_, dateString) => {
    setEditedGraduationYear(dateString);
  };

  const handleSelect = useCallback((value: string) => {
    setEditedLevel(value);
  }, []);

  const handleSelectName = useCallback((value: string) => {
    setEditedLanguage(value);
  }, []);

  return (
    <Flex key={index} gap={5} align="center" style={{ width: "100%" }}>
      {isEditing ? (
        <>
          {item.studyField ? (
            <ResultEdu
              editedDescription={editedDescription}
              editedQualification={editedQualification}
              editedPlace={editedPlace}
              setEditedPlace={setEditedPlace}
              handleEditGraduation={handleEditGraduation}
              item={item}
              setEditedDescription={setEditedDescription}
              setEditedQualification={setEditedQualification}
            />
          ) : (
            <ResultLang
              handleSelectName={handleSelectName}
              handleSelect={handleSelect}
              item={item}
            />
          )}
        </>
      ) : (
        <TableRow item={item} />
      )}
      {isEditing ? (
        <div className={styles.resultIcon}>
          <Button onClick={handleSave} size="small">
            {t("saveBtn")}
          </Button>
        </div>
      ) : (
        <EditOutlined
          onClick={handleEdit}
          style={{
            color: AITHO_TEXT_3,
          }}
          className={styles.resultIcon}
        />
      )}
      <DeleteFilled
        onClick={() => onDelete(index)}
        style={{
          color: AITHO_RED,
        }}
        className={styles.resultIcon}
      />
    </Flex>
  );
};
export default Results;
