import { useTranslation } from "react-i18next";
import GeneralInfoBox from "../GeneralInfoBox";
import ProfessionalProfile from "../ProfessionalProfile";
import ProfessionalExperience from "../ProfessionalExperience";
import EducationHome from "../EducationHome";
import Certifications from "../Certifications";
import TechnicalSkills from "../TechnicalSkills";
import SoftSkills from "../SoftSkills";
import LanguagesContent from "../LanguagesContent";

const ContainerUserInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <GeneralInfoBox />
      <ProfessionalProfile title={t("homePage.roleSection.sectionTitle")} />
      <ProfessionalExperience
        title={t("homePage.professionalExperienceSection.sectionTitle")}
      />
      <EducationHome title={t("homePage.educationSection.sectionTitle")} />
      <Certifications title={t("homePage.certificationSection.sectionTitle")} />
      <TechnicalSkills
        title={t("homePage.technicalSkillsSection.sectionTitle")}
      />
      <SoftSkills title={t("homePage.softSkills.sectionTitle")} />
      <LanguagesContent title={t("homePage.languagesSection.sectionTitle")} />
    </>
  );
};
export default ContainerUserInfo;
