export enum RoutesPath {
  ROOT = "/",
  TUTORIAL = "/tutorial",
  USEREDITOR = "/home",
  ADMIN = "/admin",
  PDF_OVERVIEW = "/pdfOverview",
  LOGIN = "/login",
  SUCCESS_PAGE = "/success",
  PDF = "/pdf",
}
