import styles from "./Recap.module.css";
import { Descriptions, Divider, Flex } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../store/store";
import receiptImg from "./../../../icon/recap-icon.svg";
import _ from "lodash";

const Recap = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const education = useSelector(
    (state: RootState) => state.userInfoSlice.education,
  );

  const languages = useSelector(
    (state: RootState) => state.userInfoSlice.languages,
  );

  return (
    <Flex vertical={false} justify="space-around">
      <div className={styles.recapContainer}>
        <p className={styles.titleRecap}>
          <span>{t("tutorial.recapPage.recapTitle")}</span>
        </p>
        <div className={styles.descriptionWrapper}>
          <Descriptions title={t("tutorial.recapPage.userDataTitle")} />
          <div className={styles.userdataRow}>
            <p className={`${styles.userdataItem} ${styles.first}`}>
              {user?.name}
            </p>
            <p className={`${styles.userdataItem} ${styles.second}`}>
              {user?.surname}
            </p>
          </div>
          <Divider />
          <Descriptions title={t("tutorial.recapPage.educationTitle")} />
          {education.map((education) => (
            <div className={styles.userdataRow} key={_.uniqueId("id_")}>
              <p className={`${styles.userdataItem} ${styles.first}`}>
                {education?.qualification}
              </p>
              <p className={`${styles.userdataItem} ${styles.second}`}>
                {education?.institution}
              </p>
              <p className={`${styles.userdataItem} ${styles.second}`}>
                {education?.studyField}
              </p>
              <p className={`${styles.userdataItem} ${styles.second}`}>
                {education?.graduationYear}
              </p>
            </div>
          ))}
          <Divider />
          <Descriptions title={t("tutorial.recapPage.languagesTitle")} />
          {languages.map((language) => (
            <div className={styles.userdataRow} key={_.uniqueId("id_")}>
              <p className={`${styles.userdataItem} ${styles.first}`}>
                {language?.name}
              </p>
              <p className={`${styles.userdataItem} ${styles.second}`}>
                {language?.level}
              </p>
            </div>
          ))}
          <br></br>
          <Divider />
        </div>
      </div>
      <div className={styles.receiptImg}>
        <img
          alt="sideimage"
          className={styles.receiptStyle}
          src={receiptImg}
          style={{ height: 490 }}
        />
      </div>
    </Flex>
  );
};

export default Recap;
