import { Dispatch, SetStateAction, useCallback } from "react";
import { formatDate } from "../../../utils/formatDate";
import {
  usePatchProfessionalExperiencesMutation,
  usePatchVisibilityProfessionalExperiencesMutation,
} from "../../../services/api";
import { NotificationTypes } from "../../Notification/utils/constants";
import { IErrorData, IExperienceDetails } from "../../../interfaces";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

interface IUseHandleOnFinishProps {
  tempProExperience: IExperienceDetails;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  id: string;
  showNotification: (
    title: string,
    description: string,
    type: NotificationTypes,
  ) => void;
  isVisible: boolean;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
}

const useHandleOnFinish = ({
  tempProExperience,
  setIsEditing,
  id,
  showNotification,
  isVisible,
  setIsFormValid,
}: IUseHandleOnFinishProps) => {
  const [patchProfessionalExperiences, { isLoading: isLoadingPatch }] =
    usePatchProfessionalExperiencesMutation();
  const [
    patchVisibilityProfessionalExperience,
    { isLoading: isLoandingPatchVisibility },
  ] = usePatchVisibilityProfessionalExperiencesMutation();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const handleOnFinish = useCallback(async () => {
    const newProExperience: IExperienceDetails = {
      ...tempProExperience,
      finalCustomerNDA: tempProExperience.isNDA
        ? tempProExperience.finalCustomerNDA
        : null,
      startDate: formatDate(tempProExperience.startDate as string),
      endDate: tempProExperience.onGoing
        ? null
        : formatDate(tempProExperience.endDate as string),
    };

    const response = await patchProfessionalExperiences({
      content: newProExperience,
      id,
      employeeId: idEmployee.employeeId,
    });

    const responseVisibility = await patchVisibilityProfessionalExperience({
      visible: isVisible,
      id,
      employeeId: idEmployee.employeeId,
    });
    setIsEditing((prev) => !prev);
    setIsFormValid(true);

    if ("error" in response && "data" in response.error) {
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.errorPatch.title",
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    if ("error" in responseVisibility && "data" in responseVisibility.error) {
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.errorPatch.title",
        (responseVisibility.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    showNotification(
      "homePage.professionalExperienceSection.form.notifications.successPatch.title",
      "homePage.professionalExperienceSection.form.notifications.successPatch.description",
      NotificationTypes.SUCCESS,
    );
  }, [
    id,
    idEmployee.employeeId,
    isVisible,
    patchProfessionalExperiences,
    patchVisibilityProfessionalExperience,
    setIsEditing,
    setIsFormValid,
    showNotification,
    tempProExperience,
  ]);

  return { handleOnFinish, isLoadingPatch, isLoandingPatchVisibility };
};

export default useHandleOnFinish;
