import { Dispatch, SetStateAction, useCallback } from "react";
import { message } from "antd";
import {
  useDeleteTemplateMutation,
  usePatchIsDraftMutation,
} from "../../../../services/api";
import i18n from "../../../../i18n";
import { IErrorData } from "../../../../interfaces";
import { TemplateActions } from "../../constants/enum";

const useTemplateActions = (
  setDeleteId: Dispatch<SetStateAction<string | null>>,
) => {
  const [
    deleteTemplate,
    { isLoading: isLoadingDelete, isError: isErrorDelete },
  ] = useDeleteTemplateMutation();
  const [patchIsDraft, { isLoading: isLoadingPatch }] =
    usePatchIsDraftMutation();

  const confirm = useCallback(
    async (id: string, actionType: string, isDraft?: boolean) => {
      let response;
      let succesMessage;

      if (actionType === TemplateActions.DELETE) {
        response = await deleteTemplate(id);
        succesMessage = i18n.t("pdfOverview.deleteTemplate");
        setDeleteId(id);
      }
      if (actionType === TemplateActions.PATCHDRAFT) {
        response = await patchIsDraft({
          isDraft: !isDraft,
          id,
        });
        succesMessage = i18n.t(
          "homePage.professionalExperienceSection.form.notifications.successPatch.description",
        );
      }

      if (response && "error" in response && "data" in response.error) {
        message.error((response.error.data as IErrorData).message);
        return;
      }

      message.success(succesMessage);
    },

    [deleteTemplate, patchIsDraft, setDeleteId],
  );

  const cancel = useCallback((actionType: string) => {
    if (actionType === TemplateActions.DELETE) {
      message.warning(i18n.t("pdfOverview.notDeleteTemplate"));
    }
    if (actionType === TemplateActions.PATCHDRAFT) {
      message.warning(
        i18n.t(
          "homePage.professionalExperienceSection.form.notifications.warningPatch.description",
        ),
      );
    }
  }, []);

  return {
    confirm,
    cancel,
    isErrorDelete,
    isLoadingDelete,
    isLoadingPatch,
  };
};

export default useTemplateActions;
