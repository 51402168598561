import dayjs from "dayjs";
import { FormatDate } from "../constants/FormatDateEnum";
import i18n from "../i18n";

export const formatDate = (date: string | undefined): string | null => {
  if (date) {
    return dayjs(date).format(FormatDate.DATE_MONTH_Y);
  }
  return null;
};

export const formatDateIntoNumber = (date: number | string): number | null => {
  if (date) {
    return parseInt(dayjs(date).format(FormatDate.DATE_Y));
  }
  return null;
};

export const convertMonthsToYears = (mesi: number) => {
  let years = Math.floor(mesi / 12);
  let monthsRemaining = mesi % 12;

  if (monthsRemaining === 11) {
    years++;
    monthsRemaining = 0;
  }
  if (years === 0) {
    return `${monthsRemaining + 1} ${
      monthsRemaining + 1 === 1
        ? i18n.t("homePage.professionalExperienceSection.month")
        : i18n.t("homePage.professionalExperienceSection.months")
    }`;
  } else if (monthsRemaining === 0) {
    return `${years} ${
      years === 1
        ? i18n.t("homePage.professionalExperienceSection.year")
        : i18n.t("homePage.professionalExperienceSection.years")
    }`;
  } else {
    return `${years} ${
      years === 1
        ? i18n.t("homePage.professionalExperienceSection.year")
        : i18n.t("homePage.professionalExperienceSection.years")
    } - ${monthsRemaining} ${
      monthsRemaining === 1
        ? i18n.t("homePage.professionalExperienceSection.month")
        : i18n.t("homePage.professionalExperienceSection.months")
    }`;
  }
};
