import { Button, DatePicker, Flex, Form, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./FormCertifications.module.css";
import { InputName } from "./utils/costants";
import { IFormCertificationsProps } from "../../../interfaces";
import { FormatDate } from "../../../constants/FormatDateEnum";
import {
  dateDisabledMinorEnteredYear,
  disabledDateGreaterCurrentYear,
} from "../../../utils/disabledDate";

const FormCertifications = ({
  closeForm,
  handleOnChangeTemp,
  handleOnFinish,
  isValid,
  initialValues,
  isLoading,
  title,
  isEditing,
  releaseYear,
}: IFormCertificationsProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Form
      className={!isEditing ? styles.form : styles.formEdit}
      onValuesChange={handleOnChangeTemp}
      onFinish={handleOnFinish}
      initialValues={initialValues}
      form={form}
    >
      <Form.Item
        className={styles.formItem}
        name={InputName.NAME}
        label={`${t("homePage.certificationSection.certificationsNameLabel")}:`}
        rules={[
          {
            required: true,
            message: t("errormsg.genericInput"),
          },
        ]}
      >
        <Input
          placeholder={t(
            "homePage.certificationSection.certificationsNamePlaceholder",
          )}
        />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={InputName.RELEASEYEAR}
        label={`${t(
          "homePage.certificationSection.certificationsReleaseYearLabel",
        )}:`}
        rules={[
          {
            required: true,
            message: t("errormsg.genericInput"),
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%", padding: "8px 11px 8px" }}
          placeholder={t(
            "homePage.certificationSection.certificationsReleaseYearPlaceholder",
          )}
          format={FormatDate.DATE_Y}
          picker="year"
          disabledDate={disabledDateGreaterCurrentYear}
        />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={InputName.URL}
        label={`${t("homePage.certificationSection.certificationsUrlLabel")}:`}
      >
        <Input
          placeholder={t(
            "homePage.certificationSection.certificationsUrlPlaceholder",
          )}
        />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={InputName.EXPIREYEAR}
        label={`${t(
          "homePage.certificationSection.certificationsExpireYearLabel",
        )}:`}
      >
        <DatePicker
          style={{ width: "100%", padding: "8px 11px 8px" }}
          placeholder={t(
            "homePage.certificationSection.certificationsExpireYearPlaceholder",
          )}
          format={FormatDate.DATE_Y}
          picker="year"
          disabledDate={(current) =>
            dateDisabledMinorEnteredYear(current, releaseYear as number)
          }
        />
      </Form.Item>
      <Form.Item>
        <Flex
          gap="small"
          align="baseline"
          style={{ width: "100%" }}
          justify="flex-end"
        >
          <Flex gap="small" wrap="wrap">
            <Button
              type="primary"
              size={"middle"}
              htmlType="submit"
              disabled={!isValid || isLoading}
            >
              {isLoading && <Spin size="large" />}
              {t(title)}
            </Button>
            <Button size={"middle"} onClick={closeForm}>
              {t("homePage.professionalExperienceSection.form.cancelButton")}
            </Button>
          </Flex>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default FormCertifications;
