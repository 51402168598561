import styles from "./BoxTechnicalSkills.module.css";
import {
  IoCodeSlashOutline,
  IoLibraryOutline,
  IoLaptopOutline,
} from "react-icons/io5";
import { PiDatabase, PiGearSix } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import _ from "lodash";
import { IUser } from "../../models/IUser";

interface IBoxTechnicalsSkills {
  user: IUser;
}

const BoxTechnicalSkills = ({ user }: IBoxTechnicalsSkills) => {
  const { t } = useTranslation();

  if (!user.technicalInformation) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t("homePage.technicalSkillsSection.emptyTechnicalSkills")}
      />
    );
  }

  return (
    <div className={styles.cardTechnicalSkills}>
      <div className={styles.iconTitleSkill}>
        <IoCodeSlashOutline style={{ fontSize: "20px" }} />
        <h3>
          {t("homePage.technicalSkillsSection.programmingLanguagesLabel")}:
        </h3>
      </div>
      <div className={styles.contentSingleSkill}>
        {user.technicalInformation.programmingLanguages.length ? (
          user.technicalInformation.programmingLanguages.map((item) => (
            <p key={_.uniqueId("id_")} className={styles.singleSkill}>
              {item}
            </p>
          ))
        ) : (
          <p className={styles.empty}>
            {t("homePage.technicalSkillsSection.emptyProgrammingLanguages")}
          </p>
        )}
      </div>

      <div className={styles.iconTitleSkill}>
        <IoLibraryOutline style={{ fontSize: "20px" }} />
        <h3>{t("homePage.technicalSkillsSection.frameworksLabel")}:</h3>
      </div>
      <div className={styles.contentSingleSkill}>
        {user.technicalInformation.frameworks.length ? (
          user.technicalInformation.frameworks.map((item) => (
            <p key={_.uniqueId("id_")} className={styles.singleSkill}>
              {item}
            </p>
          ))
        ) : (
          <p className={styles.empty}>
            {t("homePage.technicalSkillsSection.emptyFrameworks")}
          </p>
        )}
      </div>

      <div className={styles.iconTitleSkill}>
        <PiDatabase style={{ fontSize: "20px" }} />
        <h3>{t("homePage.technicalSkillsSection.databasesLabel")}:</h3>
      </div>
      <div className={styles.contentSingleSkill}>
        {user.technicalInformation.databases.length ? (
          user.technicalInformation.databases.map((item) => (
            <p key={_.uniqueId("id_")} className={styles.singleSkill}>
              {item}
            </p>
          ))
        ) : (
          <p className={styles.empty}>
            {t("homePage.technicalSkillsSection.emptydatabases")}
          </p>
        )}
      </div>

      <div className={styles.iconTitleSkill}>
        <IoLaptopOutline style={{ fontSize: "20px" }} />
        <h3>{t("homePage.technicalSkillsSection.operativeSystemsLabel")}:</h3>
      </div>
      <div className={styles.contentSingleSkill}>
        {user.technicalInformation.operativeSystems.length ? (
          user.technicalInformation.operativeSystems.map((item) => (
            <p key={_.uniqueId("id_")} className={styles.singleSkill}>
              {item}
            </p>
          ))
        ) : (
          <p className={styles.empty}>
            {t("homePage.technicalSkillsSection.emptyoperativeSystems")}
          </p>
        )}
      </div>

      <div className={styles.iconTitleSkill}>
        <PiGearSix style={{ fontSize: "20px" }} />
        <h3>{t("homePage.technicalSkillsSection.softwareLabel")}:</h3>
      </div>
      <div className={styles.contentSingleSkill}>
        {user.technicalInformation.software.length ? (
          user.technicalInformation.software.map((item) => (
            <p key={_.uniqueId("id_")} className={styles.singleSkill}>
              {item}
            </p>
          ))
        ) : (
          <p className={styles.empty}>
            {t("homePage.technicalSkillsSection.emptySoftware")}
          </p>
        )}
      </div>
    </div>
  );
};

export default BoxTechnicalSkills;
