import React from "react";
import { SelectFlagMap } from "../../pages/tutorial/languages/utils/constants";
import styles from "./Results.module.css";
import { ITableRowProps } from "../../interfaces";

const TableRow: React.FC<ITableRowProps> = ({ item }) => {
  return !item.level ? (
    <div className={styles.eduRow}>
      <p className={`${styles.eduItem} ${styles.first}`}>
        {item.qualification}
      </p>
      <p className={`${styles.eduItem} ${styles.third}`}>{item.institution}</p>
      <p className={`${styles.eduItem} ${styles.second}`}>{item.studyField}</p>
      <p className={`${styles.eduItem} ${styles.third}`}>
        {item.graduationYear}
      </p>
    </div>
  ) : (
    <div className={styles.langRow}>
      <p className={`${styles.langItem} ${styles.first}`}>
        {SelectFlagMap[item.name as string]} {item.name}
      </p>
      <p className={`${styles.langItem} ${styles.second}`}>{item.level}</p>
    </div>
  );
};

export default TableRow;
