import { Button, Flex, Spin } from "antd";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePatchLanguagesMutation } from "../../../services/api";
import { IButtonLanguagesProps } from "../../../interfaces/ILanguagesContentProps";
import { NotificationTypes } from "../../Notification/utils/constants";
import styles from "./ButtonLanguages.module.css";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { IErrorData } from "../../../interfaces";
import _ from "lodash";

const ButtonLanguages = ({
  languages,
  prevLanguages,
  setIsEdit,
  setLanguages,
  showNotification,
}: IButtonLanguagesProps) => {
  const { t } = useTranslation();
  const [patchLanguages, { isLoading }] = usePatchLanguagesMutation();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const isDisableButton = useMemo(
    () =>
      !!_.isEqual(languages, prevLanguages) ||
      languages.some((lang) => lang.name === null || lang.level === null),
    [languages, prevLanguages],
  );

  const disableAddBtn = useCallback(
    (): boolean =>
      !languages.every((lang) => lang.name !== null && lang.level !== null),
    [languages],
  );
  const handleOnFinish = useCallback(async () => {
    const response = await patchLanguages({
      content: languages,
      id: idEmployee.employeeId,
    });
    setIsEdit(false);

    if ("error" in response && "data" in response.error) {
      showNotification(
        t(
          "homePage.professionalExperienceSection.form.notifications.error.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    showNotification(
      t(
        "homePage.professionalExperienceSection.form.notifications.successProfessionalProfile.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.successProfessionalProfile.description",
      ),
      NotificationTypes.SUCCESS,
    );
  }, [
    idEmployee.employeeId,
    languages,
    patchLanguages,
    setIsEdit,
    showNotification,
    t,
  ]);

  const addEmptyLanguage = useCallback(() => {
    const emptyLanguage = {
      name: null,
      level: null,
    };
    setLanguages((prev) => [...prev, emptyLanguage]);
  }, [setLanguages]);

  const closeForm = useCallback(() => {
    setIsEdit(false);
    showNotification(
      t(
        "homePage.professionalExperienceSection.form.notifications.warningProfessionalProfile.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.warningProfessionalProfile.description",
      ),
      NotificationTypes.WARNING,
    );
    setLanguages(prevLanguages);
  }, [prevLanguages, setIsEdit, setLanguages, showNotification, t]);

  return (
    <>
      <Flex justify="center">
        <Button
          size="large"
          className={styles.addButton}
          onClick={addEmptyLanguage}
          disabled={disableAddBtn() || isLoading}
        >
          {isLoading && <Spin size="large" />}
          {t("homePage.professionalExperienceSection.form.addButton")}
        </Button>
      </Flex>
      <Flex justify="end" gap={10} style={{ marginBottom: "10px" }}>
        <Button
          type="primary"
          size={"middle"}
          htmlType="submit"
          onClick={handleOnFinish}
          disabled={isDisableButton || isLoading}
        >
          {isLoading && <Spin size="large" />}
          {t("saveBtn")}
        </Button>
        <Button size={"middle"} onClick={closeForm}>
          {t("homePage.professionalExperienceSection.form.cancelButton")}
        </Button>
      </Flex>
    </>
  );
};

export default ButtonLanguages;
