import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { eduType } from '../components/models/eduType';
import { LangType } from '../components/models/langType';
import { personalDataType } from '../components/models/personalDataType';

const initialState = {
  personalData: {} as personalDataType,
  education: [] as eduType[],
  languages: [] as LangType[],
  isCompleted: false
};

export type RootState = {
  personalData: personalDataType;
  education: eduType[];
  languages: LangType[];
  isCompleted: boolean;
};

const userInfoSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    addPersonalData: (state, action) => {
      state.personalData = action.payload;
    },
    addEducation: (state, action : PayloadAction<eduType>) => {
     state.education = [...state.education, action.payload];
    },
    addLanguage: (state, action : PayloadAction<LangType>) => {
      state.languages= [...state.languages, action.payload]
    },
    removeEducation: (state, action) => {
      state.education = state.education.filter((_, index) => index !== action.payload);
    },
    removeLanguage: (state, action) => {
      state.languages = state.languages.filter((_, index) => index !== action.payload);
    },
    editEducation: (state, action: PayloadAction<{ index: number; editedEducation: eduType }>) => {
      const { index, editedEducation } = action.payload;
      state.education[index] = editedEducation;
    },
    editLanguage: (state, action: PayloadAction<{ index: number; editedLanguage: LangType }>) => {
      const { index, editedLanguage } = action.payload;
      state.languages[index] = editedLanguage;
    },
    Completed: (state, action) => {
      state.isCompleted = action.payload
    },
    resetState: (state) => {
      return initialState;
    },
  },
});

export const {
  addPersonalData,
  addEducation,
  addLanguage,
  removeEducation,
  removeLanguage,
  editEducation,
  editLanguage,
  Completed,
  resetState
} = userInfoSlice.actions;

export default userInfoSlice.reducer;