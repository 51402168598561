import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IIdEmployee {
  employeeId?: string;
}

interface IInfoEmployee extends IIdEmployee {
  email: string;
  name: string;
  surname: string;
}
const initialState: IInfoEmployee = {
  email: "",
  name: "",
  surname: "",
  employeeId: undefined,
};

export const infoEmployeeSlice = createSlice({
  name: "infoEmployee",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IInfoEmployee>) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.surname = action.payload.surname;
    },
    setIdEmployee: (state, action: PayloadAction<IIdEmployee>) => {
      state.employeeId = action.payload.employeeId;
    },
    deleteIdEmployee: (state) => {
      state.employeeId = undefined;
    },
  },
});
export const { setUser, setIdEmployee, deleteIdEmployee } =
  infoEmployeeSlice.actions;
export default infoEmployeeSlice.reducer;
