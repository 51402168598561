import { useTranslation } from "react-i18next";
import { Button, Flex, Tag } from "antd";
import { IApiResponse, IColumns, IContent } from "../../interfaces";
import { Columns } from "../../constants/ColumnsEnum";
import { useGetFilterQuery } from "../../services/api";
import { useCallback, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ContentsComponent } from "../../constants/ContentsComponentEnum";
import {
  resetComponent,
  setComponent,
} from "../../slices/componentNavigationSidebarAdminSlice";
import { setIdEmployee, setUser } from "../../slices/infoEmployeeSlice";
import { useNavigate } from "react-router-dom";
import { RoutesPath } from "../../constants/RoutesEnum";
import TablePaginationSearch from "../TablePaginationSearch";
import { RootState } from "../../store/store";
import { FormatDate } from "../../constants/FormatDateEnum";
import dayjs from "dayjs";
import ModalSearchUser from "./ModalSearchUser";
import { BsSliders } from "react-icons/bs";
import styles from "./ContainerAllEmployees.module.css";
import ExpandableTagList from "./ExpandableTagList";

const ContainerAllEmployees = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageValue, setPageValue] = useState(0);
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeValue, setChangeValue] = useState({
    name: "",
    professionalProfile: "",
    technicalSkill: [""],
  });

  const {
    data: dataFilter,
    isLoading: isLoadingFilter,
    isFetching: isFetchingFilter,
    error: errorFilter,
  } = useGetFilterQuery({
    page: pageValue,
    size: 10,
    name: changeValue.name,
    professionalProfile: changeValue.professionalProfile,
    technicalSkill: changeValue.technicalSkill,
  });

  const getID = useCallback(
    (value: IContent) => {
      if (user.id !== value.id) {
        dispatch(resetComponent());
        dispatch(
          setIdEmployee({
            employeeId: value.id,
          }),
        );
      }

      navigate(`${RoutesPath.USEREDITOR}?id=${value.id}`);
    },
    [dispatch, navigate, user.id],
  );

  const getInfoEmployee = useCallback(
    (value: IContent) => {
      dispatch(setComponent(ContentsComponent.EXPORTPDFEMPLOYEES));
      dispatch(
        setUser({
          email: value.email,
          name: value.name,
          surname: value.surname,
        }),
      );
    },
    [dispatch],
  );

  const columns: IColumns[] = [
    {
      title: t("homePage.generalInfoSection.labels.userNameAndSurname"),
      key: Columns.NAME,
      sorter: (a, b) => a.surname.localeCompare(b.surname),
      defaultSortOrder: Columns.SORT,
      sortDirections: [],
      showSorterTooltip: false,
      render: (record) => (
        <span>
          {(record as unknown as IContent).name}{" "}
          {(record as unknown as IContent).surname}
        </span>
      ),
    },
    {
      title: t("homePage.roleSection.title"),
      dataIndex: Columns.PROFESSIONALPROFILES,
      key: Columns.PROFESSIONALPROFILES,
      render: (value: string[]) => {
        return (
          <div>
            {value?.map((item) => (
              <Tag key={_.uniqueId("id_")} style={{ fontSize: "15px" }}>
                {item}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: t("homePage.lastEdit"),
      dataIndex: Columns.LASTMODIFIED,
      key: Columns.LASTMODIFIED,
      render: (value) => (
        <p>{dayjs(value as unknown as string).format(FormatDate.DATE_D_M_Y)}</p>
      ),
    },
    {
      title: t("homePage.technicalSkillsSection.title"),
      dataIndex: Columns.TECHNICALINFORMATION,
      key: Columns.TECHNICALINFORMATION,
      render: (value: string[]) => <ExpandableTagList tags={value} />,
    },
    {
      title: t("actions"),
      key: Columns.ACTIONS,
      render: (value) => (
        <Flex>
          <Button
            type="primary"
            style={{ margin: "0 10px 10px 0" }}
            onClick={() => getInfoEmployee(value as unknown as IContent)}
          >
            {t("pdfOverview.downloadTable")}
          </Button>

          <Button
            type="primary"
            onClick={() => getID(value as unknown as IContent)}
          >
            {t("profile")}
          </Button>
        </Flex>
      ),
    },
  ];

  const dataMapping = (responseData: IApiResponse) => {
    const dataMapped = responseData?.content.map((item) => {
      const newItem = {
        ...item,
        technicalInformation: [
          ...(item.technicalInformation?.frameworks ?? []),
          ...(item.technicalInformation?.programmingLanguages ?? []),
          ...(item.technicalInformation?.operativeSystems ?? []),
          ...(item.technicalInformation?.databases ?? []),
          ...(item.technicalInformation?.software ?? []),
        ],
      };
      return newItem;
    });
    return dataMapped;
  };

  return (
    <>
      <ModalSearchUser
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setChangeValue={setChangeValue}
        setPageValue={setPageValue}
      />
      <TablePaginationSearch
        setPageValue={setPageValue}
        columns={columns}
        dataMapping={(dataFilter && dataMapping(dataFilter)) ?? []}
        data={dataFilter}
        isLoading={isLoadingFilter}
        isFetching={isFetchingFilter}
        pageValue={pageValue}
        error={errorFilter}
        title={t("homePage.titleAdminPage")}
      >
        <Button
          type="primary"
          className={styles.buttonSearch}
          onClick={() => setIsModalOpen(true)}
        >
          <BsSliders className={styles.icon} />
          {t("homePage.filter")}
        </Button>
      </TablePaginationSearch>
    </>
  );
};

export default ContainerAllEmployees;
