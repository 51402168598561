import { useCallback } from "react";
import { IDynamicObjectSensitiveFields } from "../interfaces/IPdfParams";
import {
  useLazyGetEmployeeInfoPdfQuery,
  useLazyGetUserInfoPdfQuery,
} from "../services/api";
import { RoleEnum } from "../constants/RoleEnum";

const useHandleClickTemplateCard = () => {
  const [
    getDownloadAdmin,
    {
      isFetching: isFetchingAdmin,
      isLoading: isLoadingAdmin,
      error: errorAdmin,
    },
  ] = useLazyGetEmployeeInfoPdfQuery();
  const [
    getDownloadUser,
    { isFetching: isFetchingUser, isLoading: isLoadingUser, error: errorUser },
  ] = useLazyGetUserInfoPdfQuery();

  const handleClick = useCallback(
    async (
      templateName: string,
      sensitiveFields: IDynamicObjectSensitiveFields,
      isAnonymous: boolean,
      actionType: string,
      employeeEmail?: string,
    ) => {
      let response;

      if (actionType === RoleEnum.USER) {
        response = await getDownloadUser({
          templateName,
          sensitiveFields,
          isAnonymous,
        });
      }

      if (actionType === RoleEnum.ADMIN) {
        response = await getDownloadAdmin({
          templateName,
          sensitiveFields,
          isAnonymous,
          employeeEmail,
        });
      }

      if (response && response.data) {
        const newWindow = window.open();
        newWindow?.document.write(response?.data);
        setTimeout(() => {
          newWindow?.print();
          newWindow?.close();
        }, 500);
      }
    },
    [getDownloadAdmin, getDownloadUser],
  );
  return {
    handleClick,
    isFetchingUser,
    isLoadingUser,
    isFetchingAdmin,
    isLoadingAdmin,
    errorAdmin,
    errorUser,
  };
};

export default useHandleClickTemplateCard;
