import { ICertificationsApi } from "../../interfaces";
import dayjs from "dayjs";

export const initialCertifications = {
  name: "",
  url: "",
  releaseYear: 0,
  expireYear: 0,
};

export const initialFormValues = (certification: ICertificationsApi) => ({
  name: certification.name,
  url: certification.url,
  releaseYear: dayjs(certification.releaseYear?.toString(), "YYYY"),
  expireYear: certification.expireYear
    ? dayjs(certification.expireYear?.toString(), "YYYY")
    : "",
});
