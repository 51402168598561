import { Button, Modal, Spin } from "antd";
import styles from "./ModalDownload.module.css";
import { useTranslation } from "react-i18next";
import { IErrorData, IModalDownload } from "../../../../interfaces";
import useDownloadTemplate from "./hook/useDownloadTemplate";
import { DownloadType } from "./hook/constants/constants";

const ModalDownload = ({
  isModalOpenDownload,
  setIsModalOpenDownload,
  idTemplate,
  templateName,
}: IModalDownload) => {
  const { t } = useTranslation();

  const {
    downloadFile,
    isFetchingHtml,
    isLoadingHtml,
    errorHtml,
    isFetchingLogo,
    isLoadingLogo,
    errorLogo,
  } = useDownloadTemplate({
    templateName,
    idTemplate,
  });

  return (
    <Modal
      title={t("pdfOverview.modalDownloadTitle")}
      open={isModalOpenDownload}
      footer={null}
      onCancel={() => setIsModalOpenDownload(false)}
    >
      {errorHtml && (
        <p className={styles.error}>
          {"data" in errorHtml && (errorHtml.data as IErrorData).message},
        </p>
      )}
      {errorLogo && (
        <p className={styles.error}>
          {"data" in errorLogo && (errorLogo.data as IErrorData).message},
        </p>
      )}
      <div className={styles.buttonsModalDownload}>
        <Button
          type="primary"
          size={"middle"}
          onClick={() => downloadFile(DownloadType.JPEG)}
          disabled={isFetchingLogo || isLoadingLogo}
        >
          {isFetchingLogo || (isLoadingLogo && <Spin size="default" />)}
          {t("pdfOverview.buttonDownloadLogo")}
        </Button>

        <Button
          type="primary"
          size={"middle"}
          onClick={() => downloadFile(DownloadType.HTML)}
          disabled={isFetchingHtml || isLoadingHtml}
        >
          {isFetchingHtml || (isLoadingHtml && <Spin size="default" />)}
          {t("pdfOverview.buttonDownloadTemplate")}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDownload;
