import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  Form,
  FormInstance,
  Modal,
  notification,
  Upload,
  UploadFile,
} from "antd";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import styles from "./ModalUpdate.module.css";
import {
  usePatchUpdateHTMLMutation,
  usePatchUpdateLogoMutation,
} from "../../../../services/api";
import { useTranslation } from "react-i18next";
import { openNotification } from "../../../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../../Notification/utils/constants";
import { IErrorData, IModalUpdate } from "../../../../interfaces";
import { AcceptType } from "../ModalDownload/hook/constants/constants";
import { LabelName } from "../../constants/enum";

const ModalUpdate = ({
  isModalOpenUpdate,
  setIsModalOpenUpdate,
  selectedRecordId,
}: IModalUpdate) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [htmlFile, setHtmlFile] = useState<File | null>(null);
  const [patchUpdateLogo, { isLoading: isLoadingLogo }] =
    usePatchUpdateLogoMutation();
  const [patchUpdateHTML, { isLoading: isLoadingHtml }] =
    usePatchUpdateHTMLMutation();
  const [formLogo] = Form.useForm();
  const [formTemplate] = Form.useForm();
  const { t } = useTranslation();
  const [disabledBtnLogo, setDisabledBtnLogo] = useState(true);
  const [disabledBtnHTML, setDisabledBtnHTML] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  const closeModal = useCallback(() => {
    formLogo.resetFields();
    formTemplate.resetFields();
    setIsModalOpenUpdate(false);
    setDisabledBtnHTML(true);
    setDisabledBtnLogo(true);
  }, [formLogo, formTemplate, setIsModalOpenUpdate]);

  const handleFormChange = useCallback(
    (
      form: FormInstance<any>,
      setDisabled: Dispatch<SetStateAction<boolean>>,
      file: File | null,
    ) => {
      const hasErrors =
        form.getFieldsError().some(({ errors }) => errors.length) ||
        file === null;

      setDisabled(hasErrors);
    },
    [],
  );

  const handleChange = useCallback(
    (
      fileList: UploadFile[],
      setFile: Dispatch<SetStateAction<File | null>>,
    ) => {
      if (fileList.length > 0 && fileList[0].originFileObj) {
        setFile(fileList[0].originFileObj as File);
      } else {
        setFile(null);
      }
    },
    [],
  );

  const handleOnFinishUpdateContent = useCallback(
    async (values: string) => {
      const formData = new FormData();
      if (htmlFile) formData.append(LabelName.TEMPLATEFILE, htmlFile);
      setDisabledBtnHTML(Object.values(values).some((value) => value));

      const response = await patchUpdateHTML({
        id: selectedRecordId,
        content: formData,
      });

      if ("error" in response && "data" in response.error) {
        openNotification(
          NotificationPosition.TOP_RIGHT,
          api,
          t(
            "homePage.professionalExperienceSection.form.notifications.error.title",
          ),
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.successPatch.title",
        ),
        t(
          "homePage.professionalExperienceSection.form.notifications.successPatch.description",
        ),
        NotificationTypes.SUCCESS,
      );
      formTemplate.resetFields();
    },
    [api, formTemplate, htmlFile, patchUpdateHTML, selectedRecordId, t],
  );

  const handleOnFinishUpdateLogo = useCallback(
    async (values: string) => {
      const formData = new FormData();
      if (imageFile) formData.append(LabelName.LOGO, imageFile);
      setDisabledBtnLogo(Object.values(values).some((value) => value));

      const response = await patchUpdateLogo({
        id: selectedRecordId,
        content: formData,
      });

      if ("error" in response && "data" in response.error) {
        openNotification(
          NotificationPosition.TOP_RIGHT,
          api,
          t(
            "homePage.professionalExperienceSection.form.notifications.error.title",
          ),
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.successPatch.title",
        ),
        t(
          "homePage.professionalExperienceSection.form.notifications.successPatch.description",
        ),
        NotificationTypes.SUCCESS,
      );
      formLogo.resetFields();
    },
    [api, formLogo, imageFile, patchUpdateLogo, selectedRecordId, t],
  );

  return (
    <>
      {contextHolder}
      <Modal
        title={t("pdfOverview.modalUpdateTitle")}
        open={isModalOpenUpdate}
        footer={null}
        onCancel={closeModal}
      >
        <Form
          className={styles.form}
          onFinish={handleOnFinishUpdateContent}
          form={formTemplate}
          onFieldsChange={() =>
            handleFormChange(formTemplate, setDisabledBtnHTML, htmlFile)
          }
        >
          <Form.Item
            className={styles.formItem}
            name={LabelName.TEMPLATEFILE}
            label={t("pdfOverview.buttonUpdateTemplate")}
            rules={[
              {
                required: true,
                message: t("errormsg.genericInput"),
              },
            ]}
          >
            <Upload
              name={LabelName.TEMPLATEFILE}
              beforeUpload={() => false}
              accept={AcceptType.HTML}
              maxCount={1}
              onRemove={() => setHtmlFile(null)}
              onChange={({ fileList }) => handleChange(fileList, setHtmlFile)}
            >
              <Button icon={<UploadOutlined />}>
                {t(
                  "pdfOverview.loadTemplatePlaceholder.templateFilePlaceholder",
                )}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Flex
              gap="small"
              align="baseline"
              style={{ width: "100%" }}
              justify="flex-end"
            >
              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                disabled={disabledBtnHTML || isLoadingHtml}
              >
                {t("tutorial.editBtn")}
              </Button>
            </Flex>
          </Form.Item>
        </Form>

        <Form
          className={styles.form}
          form={formLogo}
          onFinish={handleOnFinishUpdateLogo}
          onFieldsChange={() =>
            handleFormChange(formLogo, setDisabledBtnLogo, imageFile)
          }
        >
          <Form.Item
            className={styles.formItem}
            name={LabelName.LOGO}
            label={t("pdfOverview.buttonUpdateLogo")}
            rules={[
              {
                required: true,
                message: t("errormsg.genericInput"),
              },
            ]}
          >
            <Upload
              name={LabelName.LOGO}
              beforeUpload={() => false}
              accept={AcceptType.IMAGE}
              maxCount={1}
              onRemove={() => setImageFile(null)}
              onChange={({ fileList }) => handleChange(fileList, setImageFile)}
            >
              <Button icon={<UploadOutlined />}>
                {t(
                  "pdfOverview.loadTemplatePlaceholder.templateLogoPlaceholder",
                )}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Flex
              gap="small"
              align="baseline"
              style={{ width: "100%" }}
              justify="flex-end"
            >
              <Button
                type="primary"
                size={"middle"}
                htmlType="submit"
                disabled={disabledBtnLogo || isLoadingLogo}
              >
                {t("tutorial.editBtn")}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalUpdate;
