import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../Loading/Loading";
import { LoadingType } from "../Loading/LoadingType";
import { useDispatch, useSelector } from "react-redux";
import { useGetAuthQuery, useGetUserInfoQuery } from "../../services/api";
import { setCurrentUser } from "../../slices/userSlice";
import { RoutesPath } from "../../constants/RoutesEnum";
import { setIdToken } from "../../slices/tokenSlice";
import { RootState } from "../../store/store";
import { jwtDecode } from "jwt-decode";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import { IErrorData } from "../../interfaces";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.token);
  const { data: auth, isLoading: isFirstLoading } = useGetAuthQuery();
  const { data, isError, error, isLoading } = useGetUserInfoQuery(
    token ? (token.idToken as string) : skipToken,
  );
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    if (!isDispatched && auth) {
      dispatch(setIdToken({ ...jwtDecode(auth.token), idToken: auth.token }));
      setIsDispatched(true);
    }
  }, [auth, dispatch, isDispatched, token.role]);

  const setUser = useCallback(() => {
    if (!isLoading && data) {
      dispatch(setCurrentUser(data));
    }
  }, [data, dispatch, isLoading]);

  useEffect(() => {
    if (idEmployee.employeeId === undefined) {
      setUser();
    }
  }, [setUser, idEmployee.employeeId, location, token.role]);

  const protectNavigation = useMemo(() => {
    if (token.idToken) {
      return <>{children}</>;
    } else {
      return (
        <Navigate
          to={RoutesPath.ROOT}
          state={{
            from: location,
            error: {
              title: t(
                "homePage.professionalExperienceSection.form.notifications.error.title",
              ),
              message:
                error && "data" in error && (error.data as IErrorData).message,
            },
          }}
          replace
        />
      );
    }
  }, [token, children, location, t, error]);

  if (isError) {
    return (
      <Navigate
        to={RoutesPath.ROOT}
        state={{
          from: location,
          error: error && "data" in error && (error.data as IErrorData).message,
        }}
        replace
      />
    );
  }
  if (isLoading || isFirstLoading) {
    return <Loading type={LoadingType.MODIFY} loading={true} />;
  }

  return protectNavigation;
};

export default ProtectedRoute;
