import styles from "./PdfOverview.module.css";
import pdfIconTop from "../../icon/png.icon2.svg";
import pdfIconBottom from "../../icon/pdf-icon.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { RoutesPath } from "../../constants/RoutesEnum";
import LayoutListPdf from "../../components/Pdf/LayoutListPdf";
import ButtonGoBack from "../../components/ButtonGoBack";

const PdfOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = useCallback(() => {
    navigate(RoutesPath.USEREDITOR);
  }, [navigate]);

  return (
    <div className={styles.pdfContainer}>
      <ButtonGoBack goBack={goHome} text={t("pdfOverview.back")} />

      <div className={styles.title_description_content}>
        <h4 className={styles.titlePdfOverview}>
          {t("pdfOverview.titlePdfOverview")}
        </h4>
        <p className={styles.description}>{t("pdfOverview.description")}</p>
      </div>

      <img src={pdfIconTop} alt="img" className={styles.pdfIconTop} />
      <img src={pdfIconBottom} alt="img" className={styles.pdfIconBottom} />

      <div className={styles.pdfView}>
        <LayoutListPdf />
      </div>
    </div>
  );
};

export default PdfOverview;
