import { Button, Flex, Form, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./FormTechnicalSkills.module.css";
import { IErrorData, IFormTechnicalSkills } from "../../../interfaces";
import { KeyNameForm } from "./utils/costants";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  useGetDatabasesQuery,
  useGetFrameworkQuery,
  useGetOperativeSystemsQuery,
  useGetProgrammingLanguagesQuery,
  useGetSoftwareQuery,
} from "../../../services/api";
import { useState } from "react";
import { filteredTools } from "../../../utils/filteredTools";
import _ from "lodash";

const FormTechinicalSkills = ({
  handleOnChangeTemp,
  handleOnFinish,
  closeForm,
  isValid,
  isLoading,
}: IFormTechnicalSkills) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const [searchPragrammingLanguages, setSearchPragrammingLanguages] = useState<
    string[] | undefined
  >([]);
  const [searchFramework, setSearchFramework] = useState<string[] | undefined>(
    [],
  );
  const [searchDatabases, setSearchDatabases] = useState<string[] | undefined>(
    [],
  );
  const [searchOperativeSystems, setSearchOperativeSystems] = useState<
    string[] | undefined
  >([]);
  const [searchSoftware, setSearchSoftware] = useState<string[] | undefined>(
    [],
  );
  const { data: dataPragrammingLanguages, error: errorPragrammingLanguages } =
    useGetProgrammingLanguagesQuery();
  const { data: dataOperativeSystems, error: errorOperativeSystems } =
    useGetOperativeSystemsQuery();
  const { data: dataFramework, error: errorFramework } = useGetFrameworkQuery();
  const { data: dataDatabases, error: errorDatabases } = useGetDatabasesQuery();
  const { data: dataSoftware, error: errorSoftware } = useGetSoftwareQuery();

  return (
    <Form
      form={form}
      className={styles.form}
      initialValues={user.technicalInformation}
      onValuesChange={handleOnChangeTemp}
      onFinish={handleOnFinish}
    >
      <Form.Item
        className={styles.formItem}
        name={KeyNameForm.PROGRAMMINGLANGUAGES}
        label={t("homePage.technicalSkillsSection.programmingLanguagesLabel")}
      >
        <Select
          onSelect={() => setSearchPragrammingLanguages([])}
          listItemHeight={32}
          mode="tags"
          placeholder={t(
            "homePage.technicalSkillsSection.programmingLanguagesPlaceholder",
          )}
          value={user.technicalInformation}
          onSearch={(input) =>
            filteredTools(
              input,
              dataPragrammingLanguages,
              setSearchPragrammingLanguages,
            )
          }
          notFoundContent={
            errorPragrammingLanguages &&
            "data" in errorPragrammingLanguages && (
              <p className={styles.error}>
                {t(
                  "homePage.professionalExperienceSection.visualization.error",
                )}
                <br />
                {(errorPragrammingLanguages.data as IErrorData).message}
              </p>
            )
          }
        >
          {searchPragrammingLanguages?.map((tool) => (
            <Select.Option key={_.uniqueId("id_")} value={tool}>
              {tool}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={KeyNameForm.FRAMEWORKS}
        label={t("homePage.technicalSkillsSection.frameworksLabel")}
      >
        <Select
          onSelect={() => setSearchFramework([])}
          listItemHeight={32}
          mode="tags"
          placeholder={t(
            "homePage.technicalSkillsSection.frameworksPlaceholder",
          )}
          value={user.technicalInformation}
          onSearch={(input) =>
            filteredTools(input, dataFramework, setSearchFramework)
          }
          notFoundContent={
            errorFramework &&
            "data" in errorFramework && (
              <p className={styles.error}>
                {t(
                  "homePage.professionalExperienceSection.visualization.error",
                )}
                <br />
                {(errorFramework.data as IErrorData).message}
              </p>
            )
          }
        >
          {searchFramework?.map((tool) => (
            <Select.Option key={_.uniqueId("id_")} value={tool}>
              {tool}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={KeyNameForm.DATABASES}
        label={t("homePage.technicalSkillsSection.databasesLabel")}
      >
        <Select
          onSelect={() => setSearchDatabases([])}
          listItemHeight={32}
          mode="tags"
          placeholder={t(
            "homePage.technicalSkillsSection.databasesPlaceholder",
          )}
          value={user.technicalInformation}
          onSearch={(input) =>
            filteredTools(input, dataDatabases, setSearchDatabases)
          }
          notFoundContent={
            errorDatabases &&
            "data" in errorDatabases && (
              <p className={styles.error}>
                {t(
                  "homePage.professionalExperienceSection.visualization.error",
                )}
                <br />
                {(errorDatabases.data as IErrorData).message}
              </p>
            )
          }
        >
          {searchDatabases?.map((tool) => (
            <Select.Option key={_.uniqueId("id_")} value={tool}>
              {tool}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={KeyNameForm.OPERATIVESYSTEMS}
        label={t("homePage.technicalSkillsSection.operativeSystemsLabel")}
      >
        <Select
          onSelect={() => setSearchOperativeSystems([])}
          listItemHeight={32}
          mode="tags"
          placeholder={t(
            "homePage.technicalSkillsSection.operativeSystemsPlaceholder",
          )}
          value={user.technicalInformation}
          onSearch={(input) =>
            filteredTools(
              input,
              dataOperativeSystems,
              setSearchOperativeSystems,
            )
          }
          notFoundContent={
            errorOperativeSystems &&
            "data" in errorOperativeSystems && (
              <p className={styles.error}>
                {t(
                  "homePage.professionalExperienceSection.visualization.error",
                )}
                <br />
                {(errorOperativeSystems.data as IErrorData).message}
              </p>
            )
          }
        >
          {searchOperativeSystems?.map((tool) => (
            <Select.Option key={_.uniqueId("id_")} value={tool}>
              {tool}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={KeyNameForm.SOFTWARE}
        label={t("homePage.technicalSkillsSection.softwareLabel")}
      >
        <Select
          onSelect={() => setSearchSoftware([])}
          listItemHeight={32}
          mode="tags"
          placeholder={t("homePage.technicalSkillsSection.softwarePlaceholder")}
          value={user.technicalInformation}
          onSearch={(input) =>
            filteredTools(input, dataSoftware, setSearchSoftware)
          }
          notFoundContent={
            errorSoftware &&
            "data" in errorSoftware && (
              <p className={styles.error}>
                {t(
                  "homePage.professionalExperienceSection.visualization.error",
                )}
                <br />
                {(errorSoftware.data as IErrorData).message}
              </p>
            )
          }
        >
          {searchSoftware?.map((tool) => (
            <Select.Option key={_.uniqueId("id_")} value={tool}>
              {tool}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Flex
          gap="small"
          align="baseline"
          style={{ width: "100%" }}
          justify="flex-end"
        >
          <Flex gap="small" wrap="wrap">
            <Button
              type="primary"
              size={"middle"}
              htmlType="submit"
              disabled={!isValid || isLoading}
            >
              {isLoading && <Spin size="large" />}
              {t("tutorial.insertBtn")}
            </Button>
            <Button size={"middle"} onClick={closeForm}>
              {t("homePage.professionalExperienceSection.form.cancelButton")}
            </Button>
          </Flex>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default FormTechinicalSkills;
