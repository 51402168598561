import styles from "./ExperienceCard.module.css";
import { useCallback, useEffect, useState } from "react";
import {
  Divider,
  Flex,
  Form,
  Input,
  DatePicker,
  Button,
  Checkbox,
  Select,
  Spin,
  Tooltip,
} from "antd";
import ReactFlagsSelect from "react-flags-select";
import { CountryMap, FormName, LanguagesType } from "./utils/constants";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { FormatDate } from "../../constants/FormatDateEnum";
import { useTranslation } from "react-i18next";
import { IErrorData, IExperienceDetails } from "../../interfaces";
import useDeleteExperience from "./hook/useDeleteExperience";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { NotificationTypes } from "../Notification/utils/constants";
import { useHandleOnFinish } from "./hook";
import { MdWorkOutline } from "react-icons/md";
import { convertMonthsToYears } from "../../utils/formatDate";
import {
  useGetLiveSearchQuery,
  useGetOccupationsQuery,
} from "../../services/api";
import initialFormValues from "./utils/initialFormValues";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { filteredTools } from "../../utils/filteredTools";
import {
  disabledDateGreaterCurrentYearAndMonths,
  disabledEndDate,
} from "../../utils/disabledDate";
import { shortDescription } from "./utils/shortDescription";
import _ from "lodash";
import { IExperienceCardProps } from "./interface/IExperienceCard";

const ExperienceCard = ({
  id,
  role,
  company,
  place,
  projects,
  startDate,
  endDate,
  priority,
  showNotification,
  visible,
  totalMonths,
}: IExperienceCardProps) => {
  const [localRole, setLocalRole] = useState<string>(role);
  const [localCompany, setLocalCompany] = useState<string>(company);
  const [localPlace, setLocalPlace] = useState<string>(place);
  const [localProjects, setLocalProjects] = useState(projects);
  const [isVisible, setIsVisible] = useState(visible);
  const [language, setLanguage] = useState<LanguagesType>(LanguagesType.IT);
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [form] = useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [selectedTools, setSelectedTools] = useState<string[]>([]);
  const [liveSearch, setLiveSearch] = useState<string[] | undefined>([]);
  const [isShort, setIsShort] = useState(true);
  const [tempProExperience, setTempProExperience] =
    useState<IExperienceDetails>(
      initialFormValues({
        role,
        company,
        place,
        projects,
        startDate,
        endDate,
        priority,
      }),
    );

  const { deleteExperience, isLoadingDelete } = useDeleteExperience(
    id,
    showNotification,
  );

  const { data: dataLiveSearch, error: errorLiveSearch } =
    useGetLiveSearchQuery();
  const { data: dataOccupations, error } = useGetOccupationsQuery();

  const viewDescription = useCallback(() => {
    setIsShort((prev) => !prev);
  }, []);

  const toggleIsVisible = useCallback(() => {
    setIsVisible((prev) => !prev);
    if (isFormValid) {
      setIsFormValid((prev) => !prev);
    }
  }, [isFormValid]);

  const closeForm = useCallback(() => {
    showNotification(
      "homePage.professionalExperienceSection.form.notifications.warningPatch.title",
      "homePage.professionalExperienceSection.form.notifications.warningPatch.description",
      NotificationTypes.WARNING,
    );
    setIsEditing((prev) => !prev);
    setIsVisible(visible);
    setIsFormValid(true);
    setTempProExperience(
      initialFormValues({
        role,
        company,
        place,
        projects,
        startDate,
        endDate,
        priority,
      }),
    );
  }, [
    company,
    endDate,
    place,
    priority,
    projects,
    role,
    showNotification,
    startDate,
    visible,
  ]);

  const { handleOnFinish, isLoadingPatch, isLoandingPatchVisibility } =
    useHandleOnFinish({
      tempProExperience,
      setIsEditing,
      id,
      showNotification,
      isVisible,
      setIsFormValid,
    });

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: IExperienceCardProps) => {
      setTempProExperience((prev) => {
        return { ...prev, ...allValues };
      });
    },
    [],
  );

  const setDatas = useCallback(() => {
    if (language === LanguagesType.IT) {
      setLocalRole(role);
      setLocalCompany(company);
      setLocalPlace(place);
      setLocalProjects(
        projects.map((project) => ({
          ...project,
          tools: project.tools.map((tool: string) => tool),
        })),
      );
    } else if (language === LanguagesType.GB) {
      return null;
    }
  }, [company, language, place, projects, role]);

  const handleEditBox = useCallback(() => {
    setIsEditing((prev) => !prev);
    form.setFieldsValue(tempProExperience);
  }, [form, tempProExperience]);

  const onChangeCheck = useCallback(() => {
    setIsFormValid(tempProExperience.onGoing);
    setTempProExperience({
      ...tempProExperience,
      onGoing: !tempProExperience.onGoing,
    });
  }, [tempProExperience]);

  const onChangeCheckNDA = useCallback(() => {
    setIsFormValid(!tempProExperience.isNDA);
    setTempProExperience({
      ...tempProExperience,
      isNDA: !tempProExperience.isNDA,
    });
    if (!tempProExperience.isNDA) {
      form.setFieldsValue({ finalCustomerNDA: "" });
    }
  }, [form, tempProExperience]);

  useEffect(() => {
    setDatas();
  }, [setDatas]);

  const handleFormChange = useCallback(() => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsFormValid(hasErrors);
  }, [form]);

  return (
    <div className={styles.cardWrapper}>
      {!isEditing ? (
        <div className={`${!isVisible && styles.opacity}`}>
          <div className={styles.cardHeader}>
            <div className={`${!isVisible && styles.experienceCardInvisible}`}>
              <h3 className={styles.cardRole}>
                {!isVisible && (
                  <FiEyeOff
                    className={styles.visibilityIcon}
                    style={{ fontSize: "25px", marginRight: "6px" }}
                  />
                )}
                <MdWorkOutline />

                {localRole}
              </h3>
            </div>

            <div className={styles.rightIcons}>
              <ReactFlagsSelect
                disabled
                selected={language}
                onSelect={(code) => setLanguage(code as LanguagesType)}
                searchable
                countries={Object.values(LanguagesType)}
                customLabels={CountryMap}
                className={styles.explangSelect}
              />
              <EditOutlined onClick={handleEditBox} />
              {!isLoadingDelete ? (
                <DeleteOutlined onClick={deleteExperience} />
              ) : (
                <LoadingOutlined />
              )}
            </div>
          </div>
          <div
            className={`${styles.bottom} ${
              !isVisible && styles.experienceCardInvisible
            }`}
          >
            <div>
              <div className={styles.cardDate}>
                <p>{` ${startDate} - ${
                  endDate ??
                  t(
                    "homePage.professionalExperienceSection.visualization.today",
                  )
                }`}</p>
                <p>{` (${
                  endDate
                    ? convertMonthsToYears(totalMonths)
                    : t(
                        "homePage.professionalExperienceSection.visualization.ongoing",
                      )
                })`}</p>
              </div>

              <h3
                className={styles.cardCompany}
              >{`${localCompany} | ${localPlace}`}</h3>
              {localProjects.map((project) => (
                <div key={project.id}>
                  {project.isNDA ? (
                    <h4
                      className={styles.cardCompany}
                    >{`${project.finalCustomer},${project.finalCustomerNDA}:`}</h4>
                  ) : (
                    <h4
                      className={styles.cardCompany}
                    >{`${project.finalCustomer}:`}</h4>
                  )}

                  <ul className={styles.listCard}>
                    <li className={styles.listItem}>
                      <p className={styles.cardProject}>{project.name}</p>

                      {project.description && (
                        <button
                          onClick={viewDescription}
                          className={`${styles.cardDescription} ${
                            project.description.split(" ").length > 30 &&
                            styles.truncated
                          }`}
                        >
                          {isShort
                            ? shortDescription(project.description)
                            : project.description}
                        </button>
                      )}
                    </li>
                    <li className={styles.listItem}>
                      <p className={styles.cardProject}>
                        {project.tools.join(", ")}
                      </p>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <Divider />
        </div>
      ) : (
        <Form
          form={form}
          onFinish={handleOnFinish}
          onValuesChange={handleOnChangeTemp}
          className={styles.infoFormContainer}
          initialValues={tempProExperience}
          onFieldsChange={handleFormChange}
        >
          <Flex
            align="flex-start"
            gap={60}
            style={{ width: "80%", marginTop: "30px" }}
          >
            <div style={{ width: "50%" }}>
              <Form.Item className={styles.formItem}>
                <Checkbox
                  checked={tempProExperience.isNDA}
                  onChange={onChangeCheckNDA}
                >
                  {t(
                    "homePage.professionalExperienceSection.form.labels.currentNDACheckBoxLabel",
                  )}
                </Checkbox>
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.role",
                )}
                name={FormName.ROLE}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.role",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.props.children as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={t(
                    "homePage.roleSection.placeholderProfessionalProfile",
                  )}
                  notFoundContent={
                    error &&
                    "data" in error && (
                      <p className={styles.error}>
                        {t(
                          "homePage.professionalExperienceSection.visualization.error",
                        )}
                        <br />
                        {(error.data as IErrorData).message}
                      </p>
                    )
                  }
                >
                  {dataOccupations?.map((level) => (
                    <Select.Option key={_.uniqueId("id_")} value={level}>
                      {level}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.company",
                )}
                name={FormName.COMPANY}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.company",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "homePage.professionalExperienceSection.form.labels.company",
                  )}
                  value={tempProExperience.company}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.place",
                )}
                name={FormName.PLACE}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.place",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "homePage.professionalExperienceSection.form.labels.place",
                  )}
                  value={tempProExperience.place}
                />
              </Form.Item>
            </div>
            <div style={{ width: "55%" }}>
              <Divider
                orientation="left"
                style={{
                  marginTop: "40px",
                }}
              ></Divider>
              <Form.Item
                className={styles.formItemRight}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.finalCustomer",
                )}
                name={FormName.FINALCUSTOMER}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.finalCustomer",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "homePage.professionalExperienceSection.form.labels.finalCustomer",
                  )}
                />
              </Form.Item>
              {tempProExperience.isNDA && (
                <Form.Item
                  name={FormName.FINALCUSTOMERNDA}
                  tooltip={t(
                    "homePage.professionalExperienceSection.form.tooltip.finalCustomerNDA",
                  )}
                  label={t(
                    "homePage.professionalExperienceSection.form.labels.finalCustomerNDA",
                  )}
                  className={styles.formItemRight}
                  rules={[
                    {
                      required: true,
                      message: t("errormsg.genericInput"),
                    },
                  ]}
                >
                  <Input
                    className={styles.experienceInput}
                    placeholder={t(
                      "homePage.professionalExperienceSection.form.placeholders.finalCustomerNDAPlaceholder",
                    )}
                  />
                </Form.Item>
              )}
              <Form.Item
                className={styles.formItemRight}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.projectName",
                )}
                name={FormName.PROJECTNAME}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.projectName",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "homePage.professionalExperienceSection.form.labels.projectName",
                  )}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItemRight}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.tools",
                )}
                name={FormName.TOOLS}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.tools",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Select
                  onSelect={() => setLiveSearch([])}
                  listItemHeight={32}
                  mode="tags"
                  placeholder={t(
                    "homePage.professionalExperienceSection.form.labels.tools",
                  )}
                  value={selectedTools}
                  onChange={(selected) => setSelectedTools(selected)}
                  onSearch={(input) =>
                    filteredTools(input, dataLiveSearch, setLiveSearch)
                  }
                  notFoundContent={
                    errorLiveSearch &&
                    "data" in errorLiveSearch && (
                      <p className={styles.error}>
                        {t(
                          "homePage.professionalExperienceSection.visualization.error",
                        )}
                        <br />
                        {(errorLiveSearch.data as IErrorData).message}
                      </p>
                    )
                  }
                >
                  {liveSearch?.map((tool) => (
                    <Select.Option key={_.uniqueId("id_")} value={tool}>
                      {tool}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Flex>
          <div style={{ width: "80%" }}>
            <Form.Item
              className={styles.formItem}
              tooltip={t(
                "homePage.professionalExperienceSection.form.tooltip.description",
              )}
              name={FormName.DESCRIPTION}
              label={t(
                "homePage.professionalExperienceSection.form.labels.description",
              )}
              rules={[
                {
                  required: true,
                  message: t("errormsg.genericDate"),
                },
              ]}
            >
              <TextArea
                rows={4}
                className={styles.experienceTextArea}
                placeholder={t(
                  "homePage.professionalExperienceSection.form.labels.description",
                )}
              />
            </Form.Item>
            <Checkbox
              style={{ marginBottom: "30px" }}
              checked={tempProExperience.onGoing}
              onChange={onChangeCheck}
            >
              {t(
                "homePage.professionalExperienceSection.form.labels.currentProjectCheckBoxLabel",
              )}
            </Checkbox>
            <Flex align="flex-end" gap={10}>
              <Form.Item
                name={FormName.STARTDATE}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.startDateLabel",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericDate"),
                  },
                ]}
                style={{ width: "50%" }}
              >
                <DatePicker
                  format={FormatDate.DATE_MONTH_Y}
                  picker="month"
                  className={styles.inputDate}
                  disabledDate={disabledDateGreaterCurrentYearAndMonths}
                />
              </Form.Item>
              {!tempProExperience.onGoing && (
                <Form.Item
                  name={FormName.ENDDATE}
                  label={t(
                    "homePage.professionalExperienceSection.form.labels.endDateLabel",
                  )}
                  rules={[
                    {
                      required: true,
                      message: t("errormsg.genericDate"),
                    },
                  ]}
                  style={{ width: "50%" }}
                >
                  <DatePicker
                    format={FormatDate.DATE_MONTH_Y}
                    picker="month"
                    className={styles.inputDate}
                    disabledDate={(current) =>
                      disabledEndDate(
                        current,
                        tempProExperience.startDate as string,
                      )
                    }
                  />
                </Form.Item>
              )}
            </Flex>
          </div>

          <div className={styles.buttonBox}>
            <Flex gap="small" align="flex-end" vertical>
              <Flex gap="small" wrap="wrap">
                <Flex align="center">
                  <Tooltip
                    style={{ marginBottom: "0" }}
                    title={t(
                      "homePage.professionalExperienceSection.form.labels.tooltipVisible",
                    )}
                  >
                    <Button
                      onClick={toggleIsVisible}
                      className={styles.visibility}
                      disabled={isLoandingPatchVisibility}
                    >
                      {isLoandingPatchVisibility && <Spin size="large" />}
                      {isVisible ? (
                        <>
                          {t(
                            "homePage.professionalExperienceSection.form.labels.invisible",
                          )}
                          <FiEye className={styles.visibilityIcon} />
                        </>
                      ) : (
                        <>
                          {t(
                            "homePage.professionalExperienceSection.form.labels.visible",
                          )}
                          <FiEyeOff className={styles.visibilityIcon} />
                        </>
                      )}
                    </Button>
                  </Tooltip>
                </Flex>
                <Button
                  type="primary"
                  size={"middle"}
                  htmlType="submit"
                  disabled={isFormValid || isLoadingPatch}
                >
                  {isLoadingPatch && <Spin size="large" />}
                  {t("saveEditBtn")}
                </Button>
                <Button type="default" size={"middle"} onClick={closeForm}>
                  {t("cancelBtn")}
                </Button>
              </Flex>
            </Flex>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ExperienceCard;
