import { ArrowLeftOutlined } from "@ant-design/icons";
import styles from "./ButtonGoBack.module.css";

interface IButtonGoBack {
  goBack: () => void;
  text: string;
}

const ButtonGoBack = ({ goBack, text }: IButtonGoBack) => {
  return (
    <button className={styles.goBack} onClick={goBack}>
      <ArrowLeftOutlined style={{ fontSize: "20px" }} />
      <p>{text}</p>
    </button>
  );
};

export default ButtonGoBack;
