import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Col, Flex, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import aithoSymbol from "./../../../icon/aitho-symbol.svg";
import styles from "./SuccessPage.module.css";
import { RoutesPath } from "../../../constants/RoutesEnum";
import { RootState } from "../../../store/store";
import { useTranslation } from "react-i18next";

interface ISuccessPageProps {
  title: string;
  image: string;
}

const SuccessPage = ({ title, image }: ISuccessPageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);

  return (
    <Row className={styles.successContainer}>
      <Flex align="center">
        <Col>
          <div className={styles.containerTutorial}>
            <div className={styles.symbolBox}>
              <img
                src={aithoSymbol}
                className={styles.aithoSymbol}
                alt="symbol"
              />
            </div>
            <div className={styles.subtitleContainer}>
              <p className={styles.subtitleLambda}>
                <span>{title}</span>
              </p>
            </div>
          </div>
        </Col>
        <Col>
          <img src={image} alt="success" />
        </Col>
      </Flex>

      <Col xl={24} lg={24} md={24} sm={24}>
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.nextBtn}
            onClick={() => navigate(`${RoutesPath.USEREDITOR}?id=${user?.id}`)}
          >
            {t("tutorial.tutorialPage.successPageButton")} <RightOutlined />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(SuccessPage);
