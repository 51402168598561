import { useCallback, useEffect, useMemo } from "react";
import ContainerAllEmployees from "../../components/ContainerAllEmployees";
import Header from "../../components/Header";
import LoadTemplate from "../../components/LoadTemplate";
import Sidebar from "../../components/Sidebar";
import ContentSidebarAdmin from "../../components/Sidebar/ContentSidebarAdmin";
import styles from "./Admin.module.css";
import { ContentsComponent } from "../../constants/ContentsComponentEnum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UserEditor from "../userEditor";
import ExportPDFEmployees from "../../components/ExportPDFEmployees";
import RoleManagment from "../../components/RoleManagment";
import { useTranslation } from "react-i18next";
import ButtonGoBack from "../../components/ButtonGoBack";
import { setComponent } from "../../slices/componentNavigationSidebarAdminSlice";
import { useNavigate } from "react-router-dom";
import { deleteIdEmployee } from "../../slices/infoEmployeeSlice";
import { RoutesPath } from "../../constants/RoutesEnum";

const Admin = () => {
  const component = useSelector(
    (state: RootState) => state.component.component,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    navigate(`${RoutesPath.ADMIN}?id=${user?.id}`);
  }, [navigate, user?.id]);

  useEffect(() => {
    const handlePopState = (event: Event) => {
      navigate(`${RoutesPath.ADMIN}?id=${user?.id}`);
      event.preventDefault();
      dispatch(deleteIdEmployee());
      dispatch(setComponent(ContentsComponent.CONTAINERALLEMPLOYEES));
    };

    window.addEventListener("popstate", handlePopState);
  }, [dispatch, navigate, user?.id]);

  const renderComponent = useMemo(() => {
    const components: Record<ContentsComponent, JSX.Element> = {
      [ContentsComponent.CONTAINERALLEMPLOYEES]: <ContainerAllEmployees />,
      [ContentsComponent.LOADTEMPLATE]: <LoadTemplate />,
      [ContentsComponent.USEREDITOR]: <UserEditor />,
      [ContentsComponent.EXPORTPDFEMPLOYEES]: <ExportPDFEmployees />,
      [ContentsComponent.ROLEMANAGMENT]: <RoleManagment />,
    };
    return components[component as ContentsComponent];
  }, [component]);

  const goBack = useCallback(() => {
    dispatch(setComponent(ContentsComponent.CONTAINERALLEMPLOYEES));
  }, [dispatch]);

  return (
    <div className={styles.admin}>
      <Sidebar>
        <ContentSidebarAdmin />
      </Sidebar>
      <div className={styles.content}>
        <Header title={t("homePage.welcome")} />
        {component === ContentsComponent.EXPORTPDFEMPLOYEES && (
          <ButtonGoBack
            text={t("pdfOverview.backAllEmployees")}
            goBack={goBack}
          />
        )}
        {renderComponent}
      </div>
    </div>
  );
};

export default Admin;
