import { useTranslation } from "react-i18next";
import {
  ICardCertificationsProps,
  ICertificationsApi,
  IErrorData,
} from "../../../interfaces";
import styles from "./CardCertifications.module.css";
import { GiDiploma } from "react-icons/gi";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { FaScroll } from "react-icons/fa";
import { usePatchCertificationsMutation } from "../../../services/api";
import { useCallback, useState } from "react";
import { NotificationTypes } from "../../Notification/utils/constants";
import FormCertifications from "../FormCertifications";
import { initialFormValues } from "../../models/initialCertifications";
import { formatDateIntoNumber } from "../../../utils/formatDate";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const CardCertifications = ({
  certification,
  user,
  index,
  showNotification,
}: ICardCertificationsProps) => {
  const { t } = useTranslation();
  const [patchCertifications, { isLoading }] = usePatchCertificationsMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [tempCertifications, setTempCertifications] =
    useState<ICertificationsApi>(initialFormValues(certification));
  const [isValid, setIsValid] = useState(false);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const deleteCertification = useCallback(
    async (deleteIndex: number) => {
      const deleteObj = user.certifications.filter(
        (_, index) => index !== deleteIndex,
      );
      const response = await patchCertifications({
        content: deleteObj,
        id: idEmployee.employeeId,
      });

      if ("error" in response && "data" in response.error) {
        showNotification(
          "homePage.professionalExperienceSection.form.notifications.errorDeleteCertification.title",
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }

      showNotification(
        "homePage.professionalExperienceSection.form.notifications.successDeleteCertification.title",
        "homePage.professionalExperienceSection.form.notifications.successDeleteCertification.description",
        NotificationTypes.SUCCESS,
      );
    },
    [
      idEmployee.employeeId,
      patchCertifications,
      showNotification,
      user.certifications,
    ],
  );

  const closeForm = useCallback(() => {
    showNotification(
      "homePage.professionalExperienceSection.form.notifications.warningPatch.title",
      "homePage.professionalExperienceSection.form.notifications.warningPatch.description",
      NotificationTypes.WARNING,
    );
    setIsEditing(false);
  }, [showNotification]);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: ICertificationsApi) =>
      setTempCertifications((prev) => {
        setIsValid(!!allValues.releaseYear && !!allValues.name);
        return { ...prev, ...allValues };
      }),
    [],
  );

  const handleOnFinish = useCallback(async () => {
    const editCertification = [...user.certifications];
    editCertification[index] = {
      ...tempCertifications,
      name: tempCertifications.name,
      url: tempCertifications.url,
      releaseYear: formatDateIntoNumber(
        tempCertifications.releaseYear as number,
      ) as number,
      expireYear: formatDateIntoNumber(
        tempCertifications.expireYear as number,
      ) as number,
    };
    const response = await patchCertifications({
      content: editCertification,
      id: idEmployee.employeeId,
    });
    setIsEditing(false);
    setIsValid(false);

    if ("error" in response && "data" in response.error) {
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.errorEditCertification.title",
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    showNotification(
      "homePage.professionalExperienceSection.form.notifications.successPatch.title",
      "homePage.professionalExperienceSection.form.notifications.successPatch.description",
      NotificationTypes.SUCCESS,
    );
  }, [
    idEmployee.employeeId,
    index,
    patchCertifications,
    showNotification,
    tempCertifications,
    user.certifications,
  ]);

  const handleEditBox = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  return (
    <div className={styles.cardCertifications}>
      {!isEditing ? (
        <>
          <div>
            <h3>
              <FaScroll style={{ fontSize: "20px" }} /> {certification.name}
            </h3>
            <p>{`${t(
              "homePage.certificationSection.certificationsReleaseYearLabel",
            )}: ${certification.releaseYear}`}</p>
            <p>
              {certification.expireYear &&
                `${t(
                  "homePage.certificationSection.certificationsExpireYearLabel",
                )}: ${certification.expireYear}`}
            </p>
            {certification.url && (
              <div className={styles.linkCertification}>
                <a
                  href={certification.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("homePage.certificationSection.linkCertification")}

                  <GiDiploma style={{ fontSize: "20px" }} />
                </a>
              </div>
            )}
          </div>
          <div className={styles.icons}>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={handleEditBox}
            />

            {!isLoading ? (
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => deleteCertification(index)}
              />
            ) : (
              <LoadingOutlined />
            )}
          </div>
        </>
      ) : (
        <FormCertifications
          closeForm={closeForm}
          handleOnChangeTemp={handleOnChangeTemp}
          handleOnFinish={handleOnFinish}
          isValid={isValid}
          initialValues={tempCertifications}
          isLoading={isLoading}
          title={"saveEditBtn"}
          isEditing={isEditing}
          releaseYear={formatDateIntoNumber(
            tempCertifications.releaseYear as number,
          )}
        />
      )}
    </div>
  );
};

export default CardCertifications;
