import { Gb, It } from "react-flags-select";
export enum LanguagesType {
  GB = "GB",
  IT = "IT",
}

export const CountryMap: Record<LanguagesType, string> = {
  [LanguagesType.GB]: "ENG",
  [LanguagesType.IT]: "ITA",
};

export const CountryFlagMap: Record<LanguagesType, JSX.Element> = {
  [LanguagesType.GB]: <Gb />,
  [LanguagesType.IT]: <It />,
};

export enum FormName {
  ROLE = "role",
  COMPANY = "company",
  PLACE = "place",
  FINALCUSTOMER = "finalCustomer",
  FINALCUSTOMERNDA = "finalCustomerNDA",
  PROJECTNAME = "projectName",
  TOOLS = "tools",
  DESCRIPTION = "description",
  STARTDATE = "startDate",
  ENDDATE = "endDate",
}
