import { Gb, It, Ua, Ru, De, Fr, Es, Pt, Cn } from "react-flags-select";
import i18n from "../../../../i18n";

export const SelectFlagMap: Record<string, JSX.Element> = {
  [i18n.t("tutorial.languagePage.languageList.english")]: <Gb />,
  [i18n.t("tutorial.languagePage.languageList.french")]: <Fr />,
  [i18n.t("tutorial.languagePage.languageList.german")]: <De />,
  [i18n.t("tutorial.languagePage.languageList.italian")]: <It />,
  [i18n.t("tutorial.languagePage.languageList.russian")]: <Ru />,
  [i18n.t("tutorial.languagePage.languageList.ukrainian")]: <Ua />,
  [i18n.t("tutorial.languagePage.languageList.spanish")]: <Es />,
  [i18n.t("tutorial.languagePage.languageList.portuguese")]: <Pt />,
  [i18n.t("tutorial.languagePage.languageList.chinese_mandarin")]: <Cn />,
};
