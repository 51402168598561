import styles from "./EduForm.module.css";
import React from "react";
import { Form, Input, DatePicker, Button, Col } from "antd";
import { useTranslation } from "react-i18next";
import { IEduFormProps } from "../../interfaces";
import { FormatDate } from "../../constants/FormatDateEnum";
import { disabledDateGreaterCurrentYear } from "../../utils/disabledDate";

const EduForm: React.FC<IEduFormProps> = ({
  descriptionEducation,
  handleDataChange,
  form,
  placeEducation,
  handleDescriptionChange,
  handlePlaceChange,
  handleInsert,
  handleQualificationChange,
  qualificationEducation,
  date,
}) => {
  const { t } = useTranslation();

  return (
    <Form form={form} layout="vertical">
      <Col>
        <Form.Item
          name="education"
          required
          tooltip={t("tooltip.tooltipQualification")}
          label={`${t("tutorial.educationPage.labelQualification")}:`}
          rules={[
            {
              required: qualificationEducation.length === 0,
              message: t("errormsg.genericInput"),
            },
          ]}
        >
          <Input
            placeholder={t("tutorial.educationPage.placeholderQualification")}
            onChange={handleQualificationChange}
            value={qualificationEducation}
            className={styles.formInput}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="place"
          tooltip={t("tooltip.tooltipInstitution")}
          label={`${t("tutorial.educationPage.labelPlace")}:`}
          rules={[
            {
              required: placeEducation.length === 0,
              message: t("errormsg.genericInput"),
            },
          ]}
        >
          <Input
            placeholder={t("tutorial.educationPage.placeholderPlace")}
            onChange={handlePlaceChange}
            value={placeEducation}
            className={styles.formInput}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="description"
          tooltip={t("tooltip.tooltipFieldStudy")}
          label={`${t("tutorial.educationPage.labelDescription")}:`}
          rules={[
            {
              required: descriptionEducation.length === 0,
              message: t("errormsg.genericInput"),
            },
          ]}
        >
          <Input
            placeholder={t("tutorial.educationPage.placeholderDescription")}
            onChange={handleDescriptionChange}
            value={descriptionEducation}
            className={styles.formInput}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label={`${t("tutorial.educationPage.labelDate")}:`}
          tooltip={t("tooltip.tooltipYearAttainment")}
          name="date"
          rules={[
            {
              required: date.length === 0,
              message: t("errormsg.genericDate"),
            },
          ]}
        >
          <div className={styles.date}>
            <DatePicker
              onChange={handleDataChange}
              placeholder={t("tutorial.educationPage.placeholderRangePicker")}
              format={FormatDate.DATE_Y}
              className={styles.dateInput}
              picker="year"
              disabledDate={disabledDateGreaterCurrentYear}
            />
          </div>
        </Form.Item>
      </Col>
      <Col>
        <Form.Item>
          <Button type="primary" onClick={handleInsert}>
            {t("tutorial.insertBtn")}
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default EduForm;
