import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { RoutesPath } from "../../../constants/RoutesEnum";
import { FormatDate } from "../../../constants/FormatDateEnum";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import styles from "./ContentSidebarUser.module.css";
import ButtonSidebar from "../ButtonSidebar";
import { RoleEnum } from "../../../constants/RoleEnum";
import { ContentsComponent } from "../../../constants/ContentsComponentEnum";
import { setComponentEditor } from "../../../slices/componentNavigationSidebarTemplateEditor";

const ContentSidebarUser = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const component = useSelector(
    (state: RootState) => state.componentTemplateEditor.component,
  );
  const token = useSelector((state: RootState) => state.token);

  const goPdfPage = useCallback(() => {
    navigate(`${RoutesPath.PDF_OVERVIEW}?id=${user?.id}`);
  }, [navigate, user?.id]);

  const onClickChangeComponent = useCallback(
    (component: ContentsComponent) => {
      dispatch(setComponentEditor(component));
    },
    [dispatch],
  );

  return (
    <div className={styles.contentSidebar}>
      <p className={styles.pModifiedDate}>{`${t("homePage.lastEdit")}: ${dayjs(
        user?.lastModifiedDate,
      ).format(FormatDate.DATE_MONTH_Y)}`}</p>

      {token.role === RoleEnum.TEMPLATE_EDITOR ? (
        <div style={{ margin: "40px 5px" }}>
          <ButtonSidebar
            text={t("homePage.changeProfile")}
            onClickChangeComponent={() =>
              onClickChangeComponent(ContentsComponent.USEREDITOR)
            }
            active={component === ContentsComponent.USEREDITOR}
          />
          <ButtonSidebar
            text={t("pdfOverview.templateTitleSidebar")}
            onClickChangeComponent={() =>
              onClickChangeComponent(ContentsComponent.LOADTEMPLATE)
            }
            active={component === ContentsComponent.LOADTEMPLATE}
          />
          <ButtonSidebar
            text={t("homePage.exportPdf")}
            onClickChangeComponent={goPdfPage}
          />
        </div>
      ) : (
        <div style={{ margin: "50px 5px" }}>
          <ButtonSidebar
            text={t("homePage.exportPdf")}
            onClickChangeComponent={goPdfPage}
          />
        </div>
      )}
    </div>
  );
};

export default ContentSidebarUser;
