import styles from "./LayoutCardPdf.module.css";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa6";
import ButtonDownload from "../../../components/ButtonDownload/ButtonDownload";
import { IErrorData, IGetPdfName } from "../../../interfaces";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, notification, Switch, Tooltip } from "antd";
import useHandleClickTemplateCard from "../../../hooks";
import ModalDownloadCV from "../../ModalDownloadCV";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../Notification/utils/constants";
import { openNotification } from "../../Notification/Notification";
import WarningDialog from "../../WarningDialog";
import { RoutesPath } from "../../../constants/RoutesEnum";

interface ILayoutCardPdfProps {
  item: IGetPdfName;
}

const LayoutCardPdf = ({ item }: ILayoutCardPdfProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const { isFetchingUser, isLoadingUser, errorUser } =
    useHandleClickTemplateCard();

  const closeWarningDialogModal = useCallback(() => {
    setIsWarningDialogOpen(false);
  }, []);

  if (errorUser && "data" in errorUser) {
    openNotification(
      NotificationPosition.TOP_LEFT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.error.title",
      ),
      (errorUser.data as IErrorData).message,
      NotificationTypes.ERROR,
    );
  }

  const onChange = useCallback(() => {
    setIsAnonymous((prev) => !prev);
  }, []);

  const preDownload = useCallback(() => {
    if (item?.sensitiveFields.length) {
      setIsModalOpen(true);
    } else {
      navigate(RoutesPath.PDF, {
        state: {
          templateName: item?.name,
          sensitiveFields: item?.sensitiveFields,
          isAnonymous: isAnonymous,
        },
      });
    }
    setIsWarningDialogOpen(false);
  }, [isAnonymous, item?.name, item?.sensitiveFields, navigate]);

  const handleOnFinish = useCallback(() => {
    if (item) {
      navigate(RoutesPath.PDF, {
        state: {
          templateName: item.name,
          sensitiveFields: formValues,
          isAnonymous: isAnonymous,
        },
      });
    }
    setIsModalOpen(false);
    form.resetFields();
  }, [form, formValues, isAnonymous, item, navigate]);

  return (
    <div className={styles.card}>
      {contextHolder}
      {item.isAnonymizable && (
        <Tooltip
          title={
            !isAnonymous
              ? t("pdfOverview.isAnonymousCV")
              : t("pdfOverview.notIsAnonymousCV")
          }
        >
          <Switch
            className={styles.switch}
            defaultChecked={isAnonymous}
            onChange={onChange}
          />
        </Tooltip>
      )}
      {item.isAnonymizable && (
        <p className={styles.descriptionButton}>
          {!isAnonymous
            ? t("pdfOverview.isAnonymousCV")
            : t("pdfOverview.notIsAnonymousCV")}
        </p>
      )}
      <div className={styles.line}></div>
      <div className={styles.logo}>
        {item.logo.data ? (
          <img alt="Logo" src={`data:image/png;base64,${item.logo.data}`} />
        ) : (
          <FaFilePdf className={styles.emptyLogo} />
        )}
      </div>
      <div className={styles.contenLabel}>
        <p className={styles.describe}>{t("pdfOverview.describe")}</p>
        <p className={styles.nameTemplate}>{item.name}</p>
      </div>
      <ButtonDownload
        isDownload={isLoadingUser || isFetchingUser}
        handleClick={() => setIsWarningDialogOpen(true)}
      />
      <ModalDownloadCV
        sensitiveFields={item.sensitiveFields}
        handleOnFinish={handleOnFinish}
        setFormValues={setFormValues}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        form={form}
      />
      <WarningDialog
        isWarningDialogOpen={isWarningDialogOpen}
        closeWarningDialogModal={closeWarningDialogModal}
        preDownload={preDownload}
        title={t(
          "homePage.professionalExperienceSection.form.notifications.warning.title",
        )}
        okText={t("continueBtn")}
        cancelText={t("cancelBtn")}
        description={t("pdfOverview.loadTemplatePlaceholder.alertModalNDA")}
      />
    </div>
  );
};

export default LayoutCardPdf;
