import { useTranslation } from "react-i18next";
import {
  ICardSoftSkillsProps,
  IErrorData,
  ISoftSkillsApi,
} from "../../../interfaces";
import styles from "./CardSoftSkills.module.css";
import { SkillsLevels } from "../FormSoftSkills/utils/costants";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { usePatchSoftSkillsMutation } from "../../../services/api";
import { useCallback, useState } from "react";
import { NotificationTypes } from "../../Notification/utils/constants";
import FormSoftSkills from "../FormSoftSkills";
import initialFormValues from "./utils/initialFormValues";
import { useForm } from "antd/es/form/Form";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

const CardSoftSkills = ({
  skill,
  index,
  skills,
  showNotification,
}: ICardSoftSkillsProps) => {
  const { t } = useTranslation();
  const [patchSoftSkills, { isLoading }] = usePatchSoftSkillsMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [tempSoftSkills, setTempSoftSkills] = useState<ISoftSkillsApi>(
    initialFormValues(skill),
  );
  const [form] = useForm();
  const [isValid, setIsValid] = useState<boolean>(false);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const handleEditBox = useCallback(async () => {
    setIsEditing((prev) => !prev);
  }, [setIsEditing]);

  const getLevelClass = (index: number) => {
    switch (skill.level) {
      case SkillsLevels.BASIC:
        return index === 0 && styles.green;
      case SkillsLevels.MODERATE:
        return index < 2 && styles.yellow;
      case SkillsLevels.ADVANCE:
        return styles.red;
      default:
        return "";
    }
  };

  const deleteSoftSkills = useCallback(
    async (deleteIndex: number) => {
      const deleteObj = skills.filter((_, index) => index !== deleteIndex);
      const response = await patchSoftSkills({
        content: deleteObj,
        id: idEmployee.employeeId,
      });
      if ("error" in response && "data" in response.error) {
        showNotification(
          "homePage.professionalExperienceSection.form.notifications.errorDeleteSoftSkills.title",
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }

      showNotification(
        "homePage.professionalExperienceSection.form.notifications.successDeleteSoftSkills.title",
        "homePage.professionalExperienceSection.form.notifications.successDeleteSoftSkills.description",
        NotificationTypes.SUCCESS,
      );
    },
    [idEmployee.employeeId, patchSoftSkills, showNotification, skills],
  );

  const closeFormEdit = useCallback(() => {
    showNotification(
      "homePage.professionalExperienceSection.form.notifications.warningPatch.title",
      "homePage.professionalExperienceSection.form.notifications.warningPatch.description",
      NotificationTypes.WARNING,
    );
    setIsEditing(false);
  }, [showNotification]);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: ISoftSkillsApi) =>
      setTempSoftSkills((prev) => {
        return { ...prev, ...allValues };
      }),
    [],
  );

  const handleOnFinish = useCallback(async () => {
    const newSoftSkills = [...skills];
    newSoftSkills[index] = {
      name: tempSoftSkills.name,
      level: tempSoftSkills.level,
    };
    const softSkillsList: ISoftSkillsApi[] = newSoftSkills;
    const response = await patchSoftSkills({
      content: softSkillsList,
      id: idEmployee.employeeId,
    });
    setIsEditing(false);
    setIsValid(false);

    if ("error" in response && "data" in response.error) {
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.errorEditSoftSkills.title",
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    showNotification(
      "homePage.professionalExperienceSection.form.notifications.successPatch.title",
      "homePage.professionalExperienceSection.form.notifications.successPatch.description",
      NotificationTypes.SUCCESS,
    );
  }, [
    idEmployee.employeeId,
    index,
    patchSoftSkills,
    showNotification,
    skills,
    tempSoftSkills.level,
    tempSoftSkills.name,
  ]);

  const handleFormChange = useCallback(() => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isChanged = form.isFieldsTouched();
    setIsValid(!hasErrors && isChanged);
  }, [form]);

  return (
    <>
      {!isEditing ? (
        <div className={styles.card}>
          <p>{skill.name}:</p>
          <div className={styles.contentLevel}>
            <div className={styles.level}>
              <div className={`${styles.lineLevel} ${getLevelClass(0)}`}></div>
              <div className={`${styles.lineLevel} ${getLevelClass(1)}`}></div>
              <div className={`${styles.lineLevel} ${getLevelClass(2)}`}></div>
            </div>
            <p>
              {t(
                `homePage.softSkills.softSkillsLevel.${skill.level.toLowerCase()}`,
              )}
            </p>
          </div>
          <div className={styles.icons}>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={handleEditBox}
            />

            {!isLoading ? (
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => deleteSoftSkills(index)}
              />
            ) : (
              <LoadingOutlined />
            )}
          </div>
        </div>
      ) : (
        <FormSoftSkills
          title={"saveEditBtn"}
          closeForm={closeFormEdit}
          handleOnChangeTemp={handleOnChangeTemp}
          initialValues={tempSoftSkills}
          handleOnFinish={handleOnFinish}
          isLoading={isLoading}
          isValid={isValid}
          isEditing={isEditing}
          handleFormChange={handleFormChange}
          form={form}
        />
      )}
    </>
  );
};

export default CardSoftSkills;
