import { IProfessionalExperiencesGet } from "../interfaces";

export const getPriority = (list: IProfessionalExperiencesGet[]) => {
  if (list.length === 0) {
    return 1;
  }

  const maxPriority = list.reduce(
    (max, obj) => Math.max(max, obj.priority as number),
    -Infinity,
  );
  return maxPriority + 1;
};
