export enum Columns {
  NAME = "name",
  SURNAME = "surname",
  EMAIL = "email",
  PROFESSIONALPROFILES = "professionalProfiles",
  TECHNICALINFORMATION = "technicalInformation",
  ACTIONS = "actions",
  ISANONYMIZABLE = "isAnonymizable",
  SENSITIVEFIELDS = "sensitiveFields",
  CHOOSE = "choose",
  LASTMODIFIED = "lastModifiedDate",
  STATUS = "status",
  ROLE = "role",
  SORT = "ascend",
}
