import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./CustomDivider.module.css";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../ButtonIcon";

interface CustomDividerProps {
  title: string;
  onEdit: () => void;
  isInsert?: boolean;
}
const CustomDivider = ({ title, onEdit, isInsert }: CustomDividerProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.dividerContainer}>
      <h3>{title}</h3>
      {isInsert ? (
        <ButtonIcon onClick={onEdit} text={t("tutorial.insertBtn")}>
          <PlusOutlined />
        </ButtonIcon>
      ) : (
        <ButtonIcon onClick={onEdit} text={t("tutorial.editBtn")}>
          <EditOutlined />
        </ButtonIcon>
      )}
    </div>
  );
};

export default CustomDivider;
