import { useCallback, useState } from "react";
import CustomDivider from "../CustomDivider";
import FormSoftSkills from "./FormSoftSkills";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import { Empty, notification } from "antd";
import { useTranslation } from "react-i18next";
import { IErrorData, ISoftSkillsApi } from "../../interfaces";
import { initalSoftSkills } from "../models/initalSoftSkills";
import { usePostSoftSkillsMutation } from "../../services/api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CardSoftSkills from "./CardSoftSkills";
import _ from "lodash";

interface ISoftSkillsProps {
  title: string;
}

const SoftSkills = ({ title }: ISoftSkillsProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [tempSoftSkills, setTempSoftSkills] = useState(initalSoftSkills);
  const [postSoftSkills, { isLoading }] = usePostSoftSkillsMutation();
  const [isValid, setIsValid] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const handleVisibleEditBox = useCallback(() => {
    setIsDisabled((prev) => !prev);
  }, []);

  const closeForm = useCallback(() => {
    setIsDisabled(false);
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.description",
      ),
      NotificationTypes.WARNING,
    );
  }, [api, t]);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: ISoftSkillsApi) =>
      setTempSoftSkills((prev) => {
        setIsValid(Object.values(allValues).every((value) => !!value));
        return { ...prev, ...allValues };
      }),
    [],
  );

  const handleOnFinish = useCallback(async () => {
    const objSoftSkills = {
      name: tempSoftSkills.name,
      level: tempSoftSkills.level,
    };

    const response = await postSoftSkills({
      content: objSoftSkills,
      id: idEmployee.employeeId,
    });
    setIsDisabled(false);

    if ("error" in response && "data" in response.error) {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.error.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.success.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.success.description",
      ),
      NotificationTypes.SUCCESS,
    );
  }, [
    api,
    idEmployee.employeeId,
    postSoftSkills,
    t,
    tempSoftSkills.level,
    tempSoftSkills.name,
  ]);

  const showNotification = useCallback(
    (title: string, description: string, type: NotificationTypes) => {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(title),
        t(description),
        type,
      );
    },
    [api, t],
  );

  return (
    <>
      <CustomDivider title={title} onEdit={handleVisibleEditBox} isInsert />
      {contextHolder}
      {isDisabled && (
        <FormSoftSkills
          title={"tutorial.insertBtn"}
          closeForm={closeForm}
          handleOnChangeTemp={handleOnChangeTemp}
          handleOnFinish={handleOnFinish}
          isLoading={isLoading}
          isValid={isValid}
        />
      )}

      {user.skills.length ? (
        user.skills.map((skill, index) => (
          <CardSoftSkills
            skill={skill}
            index={index}
            skills={user.skills}
            showNotification={showNotification}
            key={_.uniqueId("id_")}
          />
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("homePage.softSkills.emptySoftSkills")}
        />
      )}
    </>
  );
};

export default SoftSkills;
