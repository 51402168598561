import {
  Button,
  Flex,
  Form,
  FormInstance,
  Input,
  Modal,
  Spin,
  Switch,
  Tooltip,
  Upload,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./FormLoadTemplate.module.css";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { usePostTemplatesMutation } from "../../../services/api";
import { IErrorData, ITemplateLoad } from "../../../interfaces";
import { openNotification } from "../../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../Notification/utils/constants";
import { UploadFile } from "antd/lib/upload/interface";
import { AcceptType } from "../TableTemplate/ModalDownload/hook/constants/constants";
import { templateDocumentationLink } from "../../../constants/documentationLink";
import { GrDocumentText } from "react-icons/gr";
import { LabelName } from "../constants/enum";

interface IModalFormLoadTemplate {
  setViewFormTemplate: Dispatch<SetStateAction<boolean>>;
  viewFormTemplate: boolean;
  toggleFormLoadTemplate: () => void;
  form: FormInstance;
}

const FormLoadTemplate = ({
  setViewFormTemplate,
  viewFormTemplate,
  toggleFormLoadTemplate,
  form,
}: IModalFormLoadTemplate) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [htmlFile, setHtmlFile] = useState<File | null>(null);
  const [postTemplates, { isLoading }] = usePostTemplatesMutation();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  const handleChange = useCallback(
    (
      fileList: UploadFile[],
      setFile: Dispatch<SetStateAction<File | null>>,
    ) => {
      if (fileList.length > 0 && fileList[0].originFileObj) {
        setFile(fileList[0].originFileObj as File);
      } else {
        setFile(null);
      }
    },
    [],
  );

  const handleOnFinish = useCallback(
    async (values: ITemplateLoad) => {
      const formData = new FormData();
      if (imageFile) formData.append(LabelName.LOGO, imageFile);
      if (htmlFile) formData.append(LabelName.TEMPLATEFILE, htmlFile);
      formData.append(LabelName.TEMPLATENAME, values.templateName);
      formData.append(LabelName.ISANONYMIZABLE, isAnonymous ? "true" : "false");
      setDisabled(Object.values(values).some((value) => value));

      const response = await postTemplates(formData);
      form.resetFields();
      setDisabled(true);

      if ("error" in response && "data" in response.error) {
        openNotification(
          NotificationPosition.TOP_RIGHT,
          api,
          t(
            "homePage.professionalExperienceSection.form.notifications.error.title",
          ),
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }

      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.success.title",
        ),
        t(
          "homePage.professionalExperienceSection.form.notifications.success.description",
        ),
        NotificationTypes.SUCCESS,
      );
    },
    [api, form, htmlFile, imageFile, isAnonymous, postTemplates, t],
  );

  const handleFormChange = useCallback(() => {
    const hasErrors =
      form.getFieldsError().some(({ errors }) => errors.length) ||
      imageFile === null ||
      htmlFile === null;

    setDisabled(hasErrors);
  }, [form, htmlFile, imageFile]);

  const onChange = useCallback(() => {
    setIsAnonymous((prev) => !prev);
  }, []);

  const cleanForm = useCallback(() => {
    form.resetFields();
    setDisabled(true);
    setViewFormTemplate(false);
  }, [form, setViewFormTemplate]);

  return (
    <>
      {contextHolder}
      <Modal
        title={t("pdfOverview.addTemplate")}
        open={viewFormTemplate}
        footer={null}
        onCancel={toggleFormLoadTemplate}
      >
        <div className={styles.formLoadTemplate}>
          <Form
            className={styles.form}
            onFinish={handleOnFinish}
            form={form}
            name="validateOnly"
            onFieldsChange={handleFormChange}
          >
            <Form.Item
              className={styles.formItem}
              name={LabelName.TEMPLATENAME}
              label={`${t("pdfOverview.loadTemplateLabel.templateNameLabel")}:`}
              rules={[
                {
                  required: true,
                  message: t("errormsg.genericInput"),
                },
              ]}
            >
              <Input
                placeholder={t(
                  "pdfOverview.loadTemplatePlaceholder.templateNamePlaceholder",
                )}
              />
            </Form.Item>

            <Form.Item
              className={styles.formItem}
              name={LabelName.TEMPLATEFILE}
              label={`${t("pdfOverview.loadTemplateLabel.templateFileLabel")}:`}
              rules={[
                {
                  required: true,
                  message: t("errormsg.genericInput"),
                },
              ]}
            >
              <Upload
                name={LabelName.TEMPLATEFILE}
                beforeUpload={() => false}
                accept={AcceptType.HTML}
                maxCount={1}
                onRemove={() => setHtmlFile(null)}
                onChange={({ fileList }) => handleChange(fileList, setHtmlFile)}
              >
                <Button icon={<UploadOutlined />}>
                  {t(
                    "pdfOverview.loadTemplatePlaceholder.templateFilePlaceholder",
                  )}
                </Button>
              </Upload>
            </Form.Item>
            <a
              href={templateDocumentationLink}
              target="_blank"
              className={styles.link}
              rel="noreferrer"
            >
              {t(
                "pdfOverview.loadTemplatePlaceholder.documentationTemplateLink",
              )}
              <GrDocumentText />
            </a>
            <Form.Item
              className={styles.formItem}
              name={LabelName.LOGO}
              label={`${t("pdfOverview.loadTemplateLabel.templateLogoLabel")}:`}
              rules={[
                {
                  required: true,
                  message: t("errormsg.genericInput"),
                },
              ]}
            >
              <Upload
                name={LabelName.LOGO}
                beforeUpload={() => false}
                accept={AcceptType.IMAGE}
                maxCount={1}
                onRemove={() => setImageFile(null)}
                onChange={({ fileList }) =>
                  handleChange(fileList, setImageFile)
                }
              >
                <Button icon={<UploadOutlined />}>
                  {t(
                    "pdfOverview.loadTemplatePlaceholder.templateLogoPlaceholder",
                  )}
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              className={styles.formItem}
              name={LabelName.ISANONYMIZABLE}
              label={`${t(
                "pdfOverview.loadTemplateLabel.templateIsAnonymizableLabel",
              )}:`}
              valuePropName="checked"
            >
              <Tooltip
                title={
                  !isAnonymous
                    ? t("pdfOverview.isAnonymousCV")
                    : t("pdfOverview.notIsAnonymousCV")
                }
              >
                <Switch defaultChecked={isAnonymous} onChange={onChange} />
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <Flex
                gap="small"
                align="baseline"
                style={{ width: "100%" }}
                justify="flex-end"
              >
                <Flex gap="small" wrap="wrap">
                  <Button
                    type="primary"
                    size={"middle"}
                    htmlType="submit"
                    disabled={isLoading || disabled}
                  >
                    {isLoading && <Spin size="large" />}
                    {t("tutorial.insertBtn")}
                  </Button>
                  <Button size={"middle"} onClick={cleanForm}>
                    {t(
                      "homePage.professionalExperienceSection.form.cancelButton",
                    )}
                  </Button>
                </Flex>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default FormLoadTemplate;
