import { useCallback, useState } from "react";
import CustomDivider from "../CustomDivider";
import FormTechnicalSkills from "./FormTechnicalSkills";
import { notification } from "antd";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import { useTranslation } from "react-i18next";
import { IErrorData } from "../../interfaces";
import { ITechnicalSkillsValues } from "../../interfaces/ITechnicalSkills";
import { initialValues } from "../models/initialFormValuesSkills";
import { usePutTechnicalSkillsMutation } from "../../services/api";
import BoxTechnicalSkills from "./BoxTechnicalSkills";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface ITechnicalSkills {
  title: string;
}

const TechnicalSkills = ({ title }: ITechnicalSkills) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [values, setValues] = useState<ITechnicalSkillsValues>(initialValues);
  const [isValid, setIsValid] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [putTechnicalSkills, { isLoading }] = usePutTechnicalSkillsMutation();
  const user = useSelector((state: RootState) => state.user);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const handleVisibleEditBox = useCallback(() => {
    setIsDisabled((prev) => !prev);
  }, []);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: ITechnicalSkillsValues) =>
      setValues((prev) => {
        setIsValid(Object.values(allValues).some((value) => !!value));
        return { ...prev, ...allValues };
      }),
    [],
  );

  const closeForm = useCallback(() => {
    setIsDisabled(false);
    setIsValid(false);
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.description",
      ),
      NotificationTypes.WARNING,
    );
  }, [api, t]);

  const handleOnFinish = useCallback(async () => {
    const objSkills = {
      programmingLanguages: values.programmingLanguages,
      frameworks: values.frameworks,
      databases: values.databases,
      operativeSystems: values.operativeSystems,
      software: values.software,
    };

    const response = await putTechnicalSkills({
      content: objSkills,
      id: idEmployee.employeeId,
    });
    setIsValid(false);
    setValues(initialValues);
    setIsDisabled((prev) => !prev);

    if ("error" in response && "data" in response.error) {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.errorPut.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.successProfessionalProfile.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.successProfessionalProfile.description",
      ),
      NotificationTypes.SUCCESS,
    );
  }, [
    api,
    idEmployee.employeeId,
    putTechnicalSkills,
    t,
    values.databases,
    values.frameworks,
    values.operativeSystems,
    values.programmingLanguages,
    values.software,
  ]);

  return (
    <>
      <CustomDivider title={title} onEdit={handleVisibleEditBox} />
      {contextHolder}
      {isDisabled ? (
        <FormTechnicalSkills
          handleOnChangeTemp={handleOnChangeTemp}
          handleOnFinish={handleOnFinish}
          closeForm={closeForm}
          isValid={isValid}
          isLoading={isLoading}
        />
      ) : (
        <BoxTechnicalSkills user={user} />
      )}
    </>
  );
};

export default TechnicalSkills;
