import { useGetLayoutPdfQuery } from "../../../services/api";
import styles from "./LayoutListPdf.module.css";
import Loading from "../../../components/Loading";
import { LoadingType } from "../../../components/Loading/LoadingType";
import { openNotification } from "../../../components/Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../../components/Notification/utils/constants";
import { useTranslation } from "react-i18next";
import { Empty, notification } from "antd";
import LayoutCardPdf from "../LayoutCardPdf/LayoutCardPdf";
import { IErrorData } from "../../../interfaces";

const LayoutListPdf = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const {
    data: layoutData,
    isLoading,
    isFetching,
    error,
  } = useGetLayoutPdfQuery();

  if (error && "data" in error) {
    openNotification(
      NotificationPosition.TOP_LEFT,
      api,
      t("pdfOverview.errorTitle"),
      (error.data as IErrorData).message,
      NotificationTypes.ERROR,
    );
  }

  return (
    <div className={styles.listCard}>
      {contextHolder}
      {layoutData?.every((template) => template.isDraft) ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("emptyTemplate")}
        />
      ) : (
        layoutData?.map(
          (item) =>
            item.isDraft === false && (
              <LayoutCardPdf item={item} key={item.id} />
            ),
        )
      )}
      <Loading type={LoadingType.MODIFY} loading={isLoading || isFetching} />
    </div>
  );
};
export default LayoutListPdf;
