import { useCallback, useState } from "react";
import styles from "./EducationCardHome.module.css";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FormEducation from "../FormEducation";
import { formatDateIntoNumber } from "../../../utils/formatDate";
import { usePatchEducationMutation } from "../../../services/api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useForm } from "antd/es/form/Form";
import { NotificationTypes } from "../../Notification/utils/constants";
import {
  IEducationCardHome,
  IEducationInitial,
} from "../../../interfaces/IEducationCardHome";
import { TbSchool } from "react-icons/tb";
import { IEducation, IErrorData } from "../../../interfaces";
import initialFormValues from "./utils/initialFormValues";

const EducationCardHome = ({
  specialization,
  educations,
  index,
  showNotification,
}: IEducationCardHome) => {
  const [isEditing, setIsEditing] = useState(false);
  const [patchEducation, { isLoading }] = usePatchEducationMutation();
  const [form] = useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);
  const [tempEducation, setTempEducation] = useState<IEducationInitial>(
    initialFormValues(specialization),
  );

  const handleOnFinish = useCallback(async () => {
    const newEducation = [...educations];
    newEducation[index] = {
      ...tempEducation,
      graduationYear: formatDateIntoNumber(
        tempEducation.graduationYear as number,
      ),
    };
    const educationList: IEducation[] = newEducation;
    const response = await patchEducation({
      content: { educationList },
      id: idEmployee.employeeId,
    });
    setIsFormValid(true);
    setIsEditing(false);

    if ("error" in response && "data" in response.error) {
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.errorDeleteEducation.title",
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    showNotification(
      "homePage.professionalExperienceSection.form.notifications.successPatch.title",
      "homePage.professionalExperienceSection.form.notifications.successPatch.description",
      NotificationTypes.SUCCESS,
    );
  }, [
    idEmployee.employeeId,
    index,
    patchEducation,
    showNotification,
    tempEducation,
    educations,
  ]);

  const closeFormEdit = useCallback(() => {
    showNotification(
      "homePage.professionalExperienceSection.form.notifications.warningPatch.title",
      "homePage.professionalExperienceSection.form.notifications.warningPatch.description",
      NotificationTypes.WARNING,
    );
    setIsEditing(false);
  }, [showNotification]);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: IEducationInitial | IEducation) =>
      setTempEducation((prev) => {
        return { ...prev, ...allValues };
      }),
    [],
  );

  const handleEditBox = useCallback(async () => {
    setIsEditing((prev) => !prev);
  }, [setIsEditing]);

  const deleteEducation = useCallback(
    async (deleteIndex: number) => {
      const educationList: IEducation[] = educations.filter(
        (_, index) => index !== deleteIndex,
      );
      const response = await patchEducation({
        content: { educationList },
        id: idEmployee.employeeId,
      });

      if ("error" in response && "data" in response.error) {
        showNotification(
          "homePage.professionalExperienceSection.form.notifications.errorDeleteEducation.title",
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.successDeleteEducation.title",
        "homePage.professionalExperienceSection.form.notifications.successDeleteEducation.description",
        NotificationTypes.SUCCESS,
      );
    },
    [idEmployee.employeeId, patchEducation, showNotification, educations],
  );

  const handleFormChange = useCallback(() => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsFormValid(hasErrors);
  }, [form]);

  return (
    <div className={styles.cardWrapper}>
      {!isEditing ? (
        <div className={styles.card}>
          <div>
            <h3 className={styles.specializationName}>
              <TbSchool style={{ fontSize: "22px" }} />
              {`${specialization.qualification} | ${specialization.institution}`}
            </h3>
            <p className={styles.specializationDate}>
              {specialization.studyField}
            </p>
            <p className={styles.specializationDate}>
              {specialization.graduationYear}
            </p>
          </div>
          <div className={styles.icons}>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={handleEditBox}
            />
            {!isLoading ? (
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => deleteEducation(index)}
              />
            ) : (
              <LoadingOutlined />
            )}
          </div>
        </div>
      ) : (
        <FormEducation
          title={"saveEditBtn"}
          handleOnChangeTemp={handleOnChangeTemp}
          handleOnFinish={handleOnFinish}
          closeForm={closeFormEdit}
          initialValues={tempEducation}
          isValid={!isFormValid}
          isEditing={isEditing}
          handleFormChange={handleFormChange}
          isLoading={isLoading}
          form={form}
        />
      )}
    </div>
  );
};
export default EducationCardHome;
