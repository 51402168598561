import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./GeneralInfoBox.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const fontSizeLight = {
  fontSize: 20,
};

const GeneralInfoBox = () => {
  const { t } = useTranslation();

  const { name, surname, email } = useSelector(
    (state: RootState) => state.user,
  );

  return (
    <div className={styles.generalInfoContainer}>
      <h4 className={styles.title}>
        {t("homePage.generalInfoSection.sectionTitle")}
      </h4>
      <div className={styles.infoBox}>
        <div className={styles.infoElement}>
          <UserOutlined style={{ ...fontSizeLight }} />
          <h4>{t("homePage.generalInfoSection.labels.userNameAndSurname")}:</h4>
          <p>{name && surname ? `${name} ${surname}` : undefined}</p>
        </div>
        <div className={styles.infoElement}>
          <MailOutlined style={{ ...fontSizeLight }} />
          <h4>{t("homePage.generalInfoSection.labels.userEmail")}:</h4>
          <p>{email}</p>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoBox;
