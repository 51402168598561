import dayjs from "dayjs";
import { FormatDate } from "../../../constants/FormatDateEnum";
import { InitialFormValuesProps } from "../../../interfaces";

const initialFormValues = ({
  role,
  company,
  place,
  projects,
  startDate,
  endDate,
  priority,
}: InitialFormValuesProps) => ({
  isNDA: projects[0].finalCustomerNDA ? true : false,
  role: role,
  company: company,
  place: place,
  finalCustomer: projects[0].finalCustomer,
  finalCustomerNDA:
    projects[0].finalCustomerNDA && projects[0].finalCustomerNDA,
  projectName: projects[0].name,
  description: projects[0].description,
  tools: projects[0].tools,
  onGoing: endDate ? false : true,
  startDate: dayjs(startDate, FormatDate.DATE_MONTH_Y),
  endDate: endDate && dayjs(endDate, FormatDate.DATE_MONTH_Y),
  priority: priority,
});

export default initialFormValues;
