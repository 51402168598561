import styles from "./EduHeader.module.css";
import { Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
const EduHeader = () => {
  const { t } = useTranslation();
  return (
    <Flex align="center" justify="start" style={{ width: "80%" }}>
      <p className={styles.headerItem}>
        {t("tutorial.educationPage.tableHeader.qualification")}
      </p>
      <p className={styles.headerItem}>
        {t("tutorial.educationPage.tableHeader.place")}
      </p>
      <p className={styles.headerItem}>
        {t("tutorial.educationPage.tableHeader.description")}
      </p>
      <p className={styles.headerItem}>
        {t("tutorial.educationPage.tableHeader.date")}
      </p>
    </Flex>
  );
};

export default React.memo(EduHeader);
