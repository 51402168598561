import styles from "./LangHeader.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
const LangHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <p className={styles.headerItem} style={{ paddingLeft: "25px" }}>
        {t("tutorial.languagePage.tableHeader.language")}
      </p>
      <p className={styles.headerItem}>
        {t("tutorial.languagePage.tableHeader.level")}
      </p>
    </div>
  );
};

export default React.memo(LangHeader);
