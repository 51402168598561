import { initialUserState, IUser } from "../components/models/IUser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const userSlice = createSlice({
name: "user",
initialState: initialUserState,
reducers:{
    setCurrentUser: (state, action : PayloadAction<IUser>) => {
        return state = {...state, ...action.payload};
    },
}
});
export const {setCurrentUser} = userSlice.actions;
export default userSlice.reducer;