import { useCallback } from "react";
import { Select } from "antd";
import { t } from "i18next";
import { Gb, It } from "react-flags-select";
import i18n from "../../i18n";
import styles from "./LanguagesPickerSidebar.module.css";
import { LanguageValue } from "./types";
const { Option } = Select;

const LanguagesPickerSidebar = () => {
  const onLanguageChange = useCallback((value: string) => {
    i18n.changeLanguage(value);
  }, []);

  return (
    <div className={styles.languagePickerWrapper}>
      <Select
        className={styles.select}
        defaultValue={i18n.language}
        style={{ width: "50%" }}
        onChange={onLanguageChange}
      >
        <Option value={LanguageValue.IT}>
          <span className={styles.optionContent}>
            <It className={styles.flag} />
            {t("languagePicker.italian")}
          </span>
        </Option>
        <Option value={LanguageValue.EN}>
          <span className={styles.optionContent}>
            <Gb className={styles.flag} />
            {t("languagePicker.english")}
          </span>
        </Option>
      </Select>
    </div>
  );
};

export default LanguagesPickerSidebar;
