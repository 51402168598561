import styles from "./ResultEdu.module.css";
import React from "react";
import { Input, DatePicker } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IEduProps } from "../../interfaces";
import { disabledDateGreaterCurrentYear } from "../../utils/disabledDate";
import { FormatDate } from "../../constants/FormatDateEnum";

const ResultEdu: React.FC<IEduProps> = ({
  editedQualification,
  setEditedQualification,
  editedDescription,
  setEditedPlace,
  editedPlace,
  setEditedDescription,
  handleEditGraduation,
  item,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.eduRow} style={{ gap: "0", height: "100%" }}>
      <Input
        value={editedQualification}
        onChange={(e) => setEditedQualification(e.target.value)}
        className={styles.editInput}
      />
      <Input
        value={editedPlace}
        onChange={(e) => setEditedPlace(e.target.value)}
        className={`${styles.editInput} ${styles.descInput}`}
      />
      <Input
        value={editedDescription}
        onChange={(e) => setEditedDescription(e.target.value)}
        className={`${styles.editInput} ${styles.descInput}`}
      />

      <DatePicker
        onChange={(date, dateString) =>
          handleEditGraduation(dayjs(date), dateString)
        }
        placeholder={
          item.graduationYear ?? t("tutorial.educationPage.placeholderAddonTo")
        }
        format={FormatDate.DATE_Y}
        className={styles.editDate}
        picker="year"
        disabledDate={disabledDateGreaterCurrentYear}
      />
    </div>
  );
};

export default ResultEdu;
