import "./LangSelector.css";
import styles from "./Languages.module.css";
import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Flex, Select, Form } from "antd";
import { useTranslation } from "react-i18next";
import { LangType } from "../../../components/models/langType";
import { RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import Results from "../../../components/Results/Results";
import { SelectFlagMap } from "./utils/constants";
import {
  Completed,
  addLanguage,
  editLanguage,
  removeLanguage,
} from "../../../slices/tutorialSlice";
import {
  languageLevelsList,
  languageNameList,
  LanguageNameList,
} from "../../../components/LanguagesContent/utils/constants";
import LangHeader from "../../../components/TutorialLangHeader/LangHeader";
import _ from "lodash";

interface ILanguagePage {
  image: string;
}

const Languages = ({ image }: ILanguagePage) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [languageListInfo, setLanguageListInfo] = useState<LangType[]>([]);
  const [selectLanguageList, setSelectLanguageList] =
    useState<LanguageNameList>(
      languageNameList.filter(
        (lang) =>
          !languageListInfo.some((language) => language.name === lang.value),
      ),
    );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const languageUser = useSelector(
    (state: RootState) => state.userInfoSlice.languages,
  );

  useEffect(() => {
    setLanguageListInfo(languageUser);
    languageUser.length === 0
      ? dispatch(Completed(false))
      : dispatch(Completed(true));
  }, [dispatch, languageUser]);

  const handleInsert = useCallback(async () => {
    try {
      await form.validateFields();
      const newLanguage = {
        name: language,
        level,
      };
      dispatch(addLanguage(newLanguage));
      setLanguage("");
      setLevel("");
      form.resetFields();
      dispatch(Completed(true));
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  }, [dispatch, form, language, level]);

  useEffect(() => {
    setSelectLanguageList(
      languageNameList.filter(
        (lang) =>
          !languageListInfo.some((language) => language.name === lang.value),
      ),
    );
  }, [languageListInfo]);

  const handleDelete = useCallback(
    (index: number) => {
      dispatch(removeLanguage(index));
    },
    [dispatch],
  );

  const handleSelect = useCallback((value: string) => {
    setLevel(value);
  }, []);

  const handleSelectName = useCallback((value: string) => {
    setLanguage(value);
  }, []);

  const handleSave = useCallback(
    (index: number, editedLanguage?: string, editedLevel?: string) => {
      dispatch(
        editLanguage({
          index,
          editedLanguage: {
            ...languageListInfo[index],
            name: editedLanguage,
            level: editedLevel,
          },
        }),
      );
    },
    [dispatch, languageListInfo],
  );

  return (
    <Row style={{ height: "100%" }}>
      <p className={styles.sectionDescription}>
        {t("tutorial.languagePage.sectionDescription")}
      </p>
      <Flex justify="center" align="center" style={{ width: "50%" }}>
        <Col style={{ width: "400px" }}>
          <Form form={form} layout="vertical">
            <Col>
              <Form.Item
                name="language"
                label={`${t("tutorial.languagePage.labelLanguage")}:`}
                tooltip={t("tooltip.tooltipLanguage")}
                rules={[
                  {
                    required: language.length === 0,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  onChange={handleSelectName}
                  options={selectLanguageList.map((lang) => {
                    return {
                      ...lang,
                      label: (
                        <span className={styles.flag}>
                          {SelectFlagMap[lang.label]} {lang.label}
                        </span>
                      ),
                    };
                  })}
                  placeholder={t("tutorial.languagePage.placeholderLangSelect")}
                  className={`${styles.formInput} ${styles.flagsSelect}`}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="level"
                label={`${t("tutorial.languagePage.labelLevel")}:`}
                tooltip={t("tooltip.tooltipLevel")}
                rules={[
                  {
                    required: language.length === 0,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Select
                  placeholder={t("tutorial.languagePage.placeholderLvlSelect")}
                  onChange={handleSelect}
                  options={[
                    ...languageLevelsList,
                    {
                      value: t("tutorial.languagePage.native"),
                      label: t("tutorial.languagePage.native"),
                    },
                  ]}
                  className={`${styles.formInput} ${styles.flagsSelect}`}
                  style={{ zIndex: 1 }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="default"
                  onClick={handleInsert}
                  className={styles.button}
                >
                  {t("tutorial.insertBtn")}
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Flex>
      {languageListInfo.length > 0 ? (
        <Flex
          vertical
          align="start"
          justify="start"
          style={{ height: "100%", width: "50%" }}
        >
          <LangHeader />
          <Flex
            align="center"
            justify="center"
            vertical
            gap="10px"
            style={{ width: "100%" }}
          >
            {languageListInfo.map((item, index) => (
              <Results
                key={_.uniqueId("id_")}
                index={index}
                item={item}
                onDelete={handleDelete}
                onSave={handleSave}
              />
            ))}
          </Flex>
        </Flex>
      ) : (
        <Flex
          align="center"
          justify="center"
          style={{ width: "50%", height: "100%" }}
        >
          <img src={image} alt="icon" className={styles.icon} />
        </Flex>
      )}
    </Row>
  );
};

export default Languages;
