import { useCallback } from "react";
import { usePostProfessionalExperiencesMutation } from "../../../services/api";
import { initialProExperience } from "../utils/initialProExperience";
import { Language } from "../utils/constants";
import { IErrorData, IHandleOnFinishExperiences } from "../../../interfaces";
import { openNotification } from "../../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../Notification/utils/constants";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/formatDate";
import { getPriority } from "../../../utils/itemsPriority";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

const useHandleOnFinish = ({
  tempProExperience,
  checked,
  checkedNDA,
  data,
  setTempProExperience,
  setLanguage,
  api,
  form,
  setIsDisabled,
  selectedTools,
  setChecked,
  setCheckedNDA,
}: IHandleOnFinishExperiences) => {
  const [postProfessionalExperiences, { isLoading: isLoadingPost }] =
    usePostProfessionalExperiencesMutation();
  const { t } = useTranslation();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const handleOnFinish = useCallback(async () => {
    const objProfessionalExperiences = {
      isNDA: checkedNDA,
      role: tempProExperience.role,
      company: tempProExperience.company,
      finalCustomer: tempProExperience.finalCustomer,
      finalCustomerNDA: checkedNDA ? tempProExperience.finalCustomerNDA : null,
      place: tempProExperience.place,
      projectName: tempProExperience.projectName,
      description: tempProExperience.description,
      tools: selectedTools,
      onGoing: checked,
      startDate: formatDate(tempProExperience.startDate as string),
      endDate: formatDate(tempProExperience.endDate as string),
      priority: data && getPriority(data),
    };

    const response = await postProfessionalExperiences({
      employeeId: idEmployee.employeeId,
      content: objProfessionalExperiences,
    });
    setIsDisabled((prev) => !prev);

    if ("error" in response && "data" in response.error) {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.error.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.success.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.success.description",
      ),
      NotificationTypes.SUCCESS,
    );
    setTempProExperience(initialProExperience);
    form.resetFields();
    setChecked(false);
    setCheckedNDA(false);
    setLanguage(Language.ITA);
  }, [
    api,
    checked,
    checkedNDA,
    data,
    form,
    idEmployee.employeeId,
    postProfessionalExperiences,
    selectedTools,
    setChecked,
    setCheckedNDA,
    setIsDisabled,
    setLanguage,
    setTempProExperience,
    t,
    tempProExperience.company,
    tempProExperience.description,
    tempProExperience.endDate,
    tempProExperience.finalCustomer,
    tempProExperience.finalCustomerNDA,
    tempProExperience.place,
    tempProExperience.projectName,
    tempProExperience.role,
    tempProExperience.startDate,
  ]);
  return { handleOnFinish, isLoadingPost };
};

export default useHandleOnFinish;
