import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { REFRESHTOKEN } from "./constants";
import { logout, setIdToken } from "../slices/tokenSlice";
import { jwtDecode } from "jwt-decode";
import { IToken } from "../components/models/IToken";
import { RootState } from "../store/store";
import { api } from "./api";
import { IErrorData } from "../interfaces";
import { setString } from "../slices/errorApiCallSlice";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.token.idToken;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, queryApi, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, queryApi, extraOptions);

  if (result.error) {
    if (typeof result.error.data === "string") {
      result.error.data = JSON.parse(result.error.data);
    }
  }

  if (result.error && result.error.status === 498) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          { url: REFRESHTOKEN, method: "POST" },
          queryApi,
          extraOptions,
        );

        if (refreshResult.data) {
          queryApi.dispatch(
            setIdToken({
              ...jwtDecode((refreshResult.data as IToken).token),
              idToken: (refreshResult.data as IToken).token,
            }),
          );

          result = await baseQuery(args, queryApi, extraOptions);
        } else {
          api.endpoints.getLogout.initiate();

          queryApi.dispatch(logout());

          result.error.data &&
            queryApi.dispatch(
              setString((result.error.data as IErrorData).message),
            );
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, queryApi, extraOptions);
    }
  }

  return result;
};
