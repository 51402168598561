import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { value: string | null } = {
  value: null,
};

const errorApiCallSlice = createSlice({
  name: "errorApiCallSlice",
  initialState,
  reducers: {
    setString: (state, action: PayloadAction<string | null>) => {
      state.value = action.payload;
    },
  },
});

export const { setString } = errorApiCallSlice.actions;
export default errorApiCallSlice.reducer;
