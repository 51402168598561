import { Dispatch, SetStateAction } from "react";

export const filteredTools = (
  input: string,
  data: string[] | undefined,
  setSearch: Dispatch<SetStateAction<string[] | undefined>>,
) => {
  if (input.trim() === "") {
    setSearch([]);
  } else {
    const lowerCaseInput = input.toLowerCase();
    const filteredTools = data?.filter((tool) =>
      tool.toLowerCase().includes(lowerCaseInput),
    );
    setSearch(filteredTools);
  }
};
