import { useCallback } from "react";
import { useDeleteProfessionalExperiencesMutation } from "../../../services/api";
import { NotificationTypes } from "../../Notification/utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IErrorData } from "../../../interfaces";

const useDeleteExperience = (
  id: string,
  showNotification: (
    title: string,
    description: string,
    type: NotificationTypes,
  ) => void,
) => {
  const [deleteProfessionalExperiences, { isLoading: isLoadingDelete }] =
    useDeleteProfessionalExperiencesMutation();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const deleteExperience = useCallback(async () => {
    const response = await deleteProfessionalExperiences({
      id,
      employeeId: idEmployee.employeeId,
    });

    if ("error" in response && "data" in response.error) {
      showNotification(
        "homePage.professionalExperienceSection.form.notifications.errorDelete.title",
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    showNotification(
      "homePage.professionalExperienceSection.form.notifications.successDelete.title",
      "homePage.professionalExperienceSection.form.notifications.successDelete.description",
      NotificationTypes.SUCCESS,
    );
  }, [
    deleteProfessionalExperiences,
    id,
    idEmployee.employeeId,
    showNotification,
  ]);

  return { deleteExperience, isLoadingDelete };
};

export default useDeleteExperience;
