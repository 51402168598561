import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { RoutesPath } from "../constants/RoutesEnum";
import  mapLocationTitle  from "../interfaces/IDocumentTitle";

export const useSetPageTitle = () => {
   const location = useLocation();

  useEffect(() => {
    document.title = mapLocationTitle(location.pathname as RoutesPath);
  }, [location.pathname]);
};