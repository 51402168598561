import i18n from "../../../i18n";

export enum LanguageLevels {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export enum Language {
  NAME = "name",
  LEVEL = "level",
}

interface ILanguageLevel {
  value: LanguageLevels;
  label: LanguageLevels;
}

interface ILanguageName {
  value: string;
  label: string;
}

type LanguageLevelsList = ILanguageLevel[];

export type LanguageNameList = ILanguageName[];

export const languageLevelsList: LanguageLevelsList = [
  { value: LanguageLevels.A1, label: LanguageLevels.A1 },
  { value: LanguageLevels.A2, label: LanguageLevels.A2 },
  { value: LanguageLevels.B1, label: LanguageLevels.B1 },
  { value: LanguageLevels.B2, label: LanguageLevels.B2 },
  { value: LanguageLevels.C1, label: LanguageLevels.C1 },
  { value: LanguageLevels.C2, label: LanguageLevels.C2 },
];

export const languageNameList: LanguageNameList = [
  {
    value: i18n.t("tutorial.languagePage.languageList.english"),
    label: i18n.t("tutorial.languagePage.languageList.english"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.french"),
    label: i18n.t("tutorial.languagePage.languageList.french"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.german"),
    label: i18n.t("tutorial.languagePage.languageList.german"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.italian"),
    label: i18n.t("tutorial.languagePage.languageList.italian"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.russian"),
    label: i18n.t("tutorial.languagePage.languageList.russian"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.ukrainian"),
    label: i18n.t("tutorial.languagePage.languageList.ukrainian"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.spanish"),
    label: i18n.t("tutorial.languagePage.languageList.spanish"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.portuguese"),
    label: i18n.t("tutorial.languagePage.languageList.portuguese"),
  },
  {
    value: i18n.t("tutorial.languagePage.languageList.chinese_mandarin"),
    label: i18n.t("tutorial.languagePage.languageList.chinese_mandarin"),
  },
];
