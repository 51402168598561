import { useTranslation } from "react-i18next";
import styles from "./CardLanguagesContent.module.css";
import { ICardLAnguagesContentProps, IErrorData } from "../../../interfaces";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { usePatchLanguagesMutation } from "../../../services/api";
import { NotificationTypes } from "../../Notification/utils/constants";
import { SelectFlagMap } from "../../../pages/tutorial/languages/utils/constants";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

const CardLanguagesContent = ({
  lang,
  languages,
  index,
  showNotification,
}: ICardLAnguagesContentProps) => {
  const { t } = useTranslation();
  const [patchLanguages, { isLoading }] = usePatchLanguagesMutation();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  const deleteLanguage = useCallback(
    async (index: number) => {
      const updatedLanguages = languages.filter((_, i) => i !== index);
      const response = await patchLanguages({
        content: updatedLanguages,
        id: idEmployee.employeeId,
      });
      if ("error" in response && "data" in response.error) {
        showNotification(
          t(
            "homePage.professionalExperienceSection.form.notifications.errorDeleteLanguage.title",
          ),
          (response.error.data as IErrorData).message,
          NotificationTypes.ERROR,
        );
        return;
      }

      showNotification(
        t(
          "homePage.professionalExperienceSection.form.notifications.successDeleteLanguage.title",
        ),
        t(
          "homePage.professionalExperienceSection.form.notifications.successDeleteLanguage.description",
        ),
        NotificationTypes.SUCCESS,
      );
    },
    [idEmployee.employeeId, languages, patchLanguages, showNotification, t],
  );

  return (
    <div className={styles.contentCard}>
      <div className={styles.content}>
        <p className={styles.flag}>{SelectFlagMap[lang.name as string]}</p>
        <p className={styles.langName}>{lang.name}:</p>
        <p className={styles.langLevel}>
          {lang.level === "native"
            ? t("tutorial.languagePage.native")
            : lang.level}
        </p>
      </div>
      {isLoading ? (
        <LoadingOutlined className={styles.icon} />
      ) : (
        <DeleteOutlined
          className={styles.icon}
          onClick={() => deleteLanguage(index)}
        />
      )}
    </div>
  );
};
export default CardLanguagesContent;
