import { Button, Flex, Form, Input, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { languageLevelsList } from "./utils/costants";
import styles from "./FormSoftSkills.module.css";
import { IFormSoftSkillsProps } from "../../../interfaces";
import { SkillsNameInput } from "./utils/enumNameInput";

const FormSoftSkills = ({
  closeForm,
  handleOnChangeTemp,
  handleOnFinish,
  isLoading,
  isValid,
  initialValues,
  title,
  isEditing,
  handleFormChange,
  form,
}: IFormSoftSkillsProps) => {
  const { t } = useTranslation();

  return (
    <Form
      className={!isEditing ? styles.form : styles.formEdit}
      onValuesChange={handleOnChangeTemp}
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFieldsChange={handleFormChange}
      form={form}
    >
      <Flex justify="space-between" gap={30}>
        <Form.Item
          className={styles.formItem}
          name={SkillsNameInput.NAME}
          label={t("homePage.softSkills.nameSoftSkillsLabel")}
          rules={[
            {
              required: true,
              message: t("errormsg.genericInput"),
            },
          ]}
        >
          <Input
            placeholder={t("homePage.softSkills.nameSoftSkillsPlaceholder")}
          />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          name={SkillsNameInput.LEVEL}
          label={t("homePage.softSkills.levelSoftSkillsLabel")}
          rules={[
            {
              required: true,
              message: t("errormsg.genericInput"),
            },
          ]}
        >
          <Select
            placeholder={t("homePage.softSkills.levelSoftSkillsPlaceholder")}
            options={languageLevelsList}
          />
        </Form.Item>
      </Flex>
      <Form.Item>
        <Flex
          gap="small"
          align="baseline"
          style={{ width: "100%" }}
          justify="flex-end"
        >
          <Flex gap="small" wrap="wrap">
            <Button
              type="primary"
              size={"middle"}
              htmlType="submit"
              disabled={!isValid || isLoading}
            >
              {isLoading && <Spin size="large" />}
              {t(title)}
            </Button>
            <Button size={"middle"} onClick={closeForm}>
              {t("homePage.professionalExperienceSection.form.cancelButton")}
            </Button>
          </Flex>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default FormSoftSkills;
