import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLazyGetLogoutQuery } from "../services/api";
import { logout } from "../slices/tokenSlice";

const useLogOut = () => {
  const [getLogout] = useLazyGetLogoutQuery();
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    getLogout();
    dispatch(logout());
  }, [dispatch, getLogout]);

  return logOut;
};

export default useLogOut;
