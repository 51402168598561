import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoutesPath } from "../../constants/RoutesEnum";
import { RootState } from "../../store/store";
import { RoleEnum } from "../../constants/RoleEnum";

const AdminRedirect: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = useSelector((state: RootState) => state.token);
  const user = useSelector((state: RootState) => state.user);

  const protectNavigation = useMemo(() => {
    if (!token.idToken) {
      return <Navigate to={RoutesPath.ROOT} replace />;
    }

    if (token.role === RoleEnum.ADMIN || token.role === RoleEnum.HR) {
      return <>{children}</>;
    } else {
      return (
        <Navigate to={`${RoutesPath.USEREDITOR}?id=${user?.id}`} replace />
      );
    }
  }, [token, children, user?.id]);

  return protectNavigation;
};

export default AdminRedirect;
