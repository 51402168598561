import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useLazyGetPersonalCvQuery,
  useLazyGetEmployeeCvQuery,
  useGetUserInfoQuery,
} from "../../services/api";
import Loading from "../../components/Loading";
import { LoadingType } from "../../components/Loading/LoadingType";
import { getPdfFileName } from "../../utils/getPdfFileName";
import { IPdfParams } from "../../interfaces/IPdfParams";
import styles from "./PdfViewer.module.css";
import mapLocationTitle from "../../interfaces/IDocumentTitle";
import { RoutesPath } from "../../constants/RoutesEnum";

const PdfViewer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [getPersonalCv] = useLazyGetPersonalCvQuery();
  const [getEmployeeCv] = useLazyGetEmployeeCvQuery();
  const { data } = useGetUserInfoQuery("token");
  const state = location.state as IPdfParams & {
    type: string;
    templateName?: string;
  };
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const handleGetCv = useCallback(async () => {
    const templateName = state.templateName || "Aitho";
    const { sensitiveFields, isAnonymous, employeeEmail } = state;
    let result;

    if (employeeEmail) {
      result = await getEmployeeCv({
        employeeEmail,
        templateName,
        sensitiveFields,
        isAnonymous,
      }).unwrap();
    } else {
      result = await getPersonalCv({
        templateName,
        sensitiveFields,
        isAnonymous,
      }).unwrap();
    }

    if (result) {
      setPdfUrl(result);
    }
    document.title = mapLocationTitle(RoutesPath.PDF, templateName);
  }, [state, getEmployeeCv, getPersonalCv]);

  const handleDownload = useCallback(() => {
    const employeeEmail = state.employeeEmail || `${data?.email}`;
    const template = state.templateName;
    const isAnonymous = state.isAnonymous;

    const linkEl = document.createElement("a");
    linkEl.href = pdfUrl;
    linkEl.download = getPdfFileName(employeeEmail, template, isAnonymous);

    document.body.appendChild(linkEl);
    linkEl.click();
    document.body.removeChild(linkEl);
  }, [
    data?.email,
    pdfUrl,
    state.employeeEmail,
    state.isAnonymous,
    state.templateName,
  ]);

  useEffect(() => {
    handleGetCv();
  }, [handleGetCv]);

  return (
    <div className={styles.pdfViewer}>
      {pdfUrl ? (
        <div>
          <iframe src={pdfUrl} className={styles.pdfIframe} title="pdf" />
          <button onClick={handleDownload} className={styles.btnDownloadPdf}>
            {t("pdfOverview.download")}
          </button>
        </div>
      ) : (
        <Loading type={LoadingType.MODIFY} loading={true} />
      )}
    </div>
  );
};

export default PdfViewer;
