import dayjs from "dayjs";
import { FormatDate } from "../constants/FormatDateEnum";

export const disabledDateGreaterCurrentYear = (current: dayjs.Dayjs): boolean =>
  current && current.year() > dayjs().year();

export const dateDisabledMinorEnteredYear = (
  current: dayjs.Dayjs,
  releaseYear: number,
): boolean => current && current.year() < releaseYear;

export const disabledEndDate = (current: dayjs.Dayjs, startDate: string) =>
  current &&
  (current < dayjs(startDate, FormatDate.DATE_MONTH_Y) || current > dayjs());

export const disabledDateGreaterCurrentYearAndMonths = (
  current: dayjs.Dayjs,
): boolean => current?.isAfter(dayjs(), "month");
