import CustomDivider from "../CustomDivider";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CardLanguagesContent from "./CardLanguagesContent";
import FormLanguagesContent from "./FormLanguagesContent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Empty, notification } from "antd";
import { useTranslation } from "react-i18next";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import { ILanguages } from "../../interfaces/ILanguagesContentProps";
import ButtonLanguages from "./ButtonLanguages";
import _ from "lodash";

export interface ILanguageContentProps {
  title: string;
}

const LanguagesContent = ({ title }: ILanguageContentProps) => {
  const user = useSelector((state: RootState) => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [languages, setLanguages] = useState<ILanguages[]>([]);
  const [prevLanguages, setPrevLanguages] = useState<ILanguages[]>([]);

  useEffect(() => {
    setLanguages(user.languages);
    setPrevLanguages(user.languages);
  }, [user.languages]);

  const onEdit = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);

  const showNotification = useCallback(
    (title: string, description: string, type: NotificationTypes) => {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(title),
        t(description),
        type,
      );
    },
    [api, t],
  );

  const homeViewing = useMemo(() => {
    if (languages.length) {
      return languages.map((lang, index) => (
        <CardLanguagesContent
          lang={lang}
          key={_.uniqueId("id_")}
          languages={languages}
          index={index}
          showNotification={showNotification}
        />
      ));
    }
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t("tutorial.languagePage.emptyLanguages")}
      />
    );
  }, [languages, showNotification, t]);

  return (
    <>
      <CustomDivider title={title} onEdit={onEdit} />
      {contextHolder}
      {!isEdit ? (
        homeViewing
      ) : (
        <>
          {languages.map((lang, index) => (
            <FormLanguagesContent
              key={_.uniqueId("id_")}
              languages={languages}
              setLanguages={setLanguages}
              index={index}
            />
          ))}
          <ButtonLanguages
            languages={languages}
            prevLanguages={prevLanguages}
            setIsEdit={setIsEdit}
            setLanguages={setLanguages}
            showNotification={showNotification}
          />
        </>
      )}
    </>
  );
};

export default LanguagesContent;
