import { Button, Flex, Form } from "antd";
import { useCallback, useState } from "react";
import FormLoadTemplate from "./FormLoadTemplate";
import TableTemplate from "./TableTemplate";
import { useTranslation } from "react-i18next";
import styles from "./LoadTemplate.module.css";

const LoadTemplate = () => {
  const { t } = useTranslation();
  const [viewFormTemplate, setViewFormTemplate] = useState(false);
  const [form] = Form.useForm();

  const toggleFormLoadTemplate = useCallback(() => {
    setViewFormTemplate((prev) => !prev);
    form.resetFields();
  }, [form]);

  return (
    <div className={styles.loadTemplate}>
      <Flex justify="end">
        <Button
          type="primary"
          className={styles.ButtonloadTemplate}
          onClick={toggleFormLoadTemplate}
        >
          {t("pdfOverview.addTemplate")}
        </Button>
      </Flex>

      <FormLoadTemplate
        setViewFormTemplate={setViewFormTemplate}
        viewFormTemplate={viewFormTemplate}
        toggleFormLoadTemplate={toggleFormLoadTemplate}
        form={form}
      />

      <TableTemplate />
    </div>
  );
};

export default LoadTemplate;
