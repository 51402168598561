import styles from "./ButtonIcon.module.css";

interface IButtonIConProps {
  onClick: () => void;
  text: string;
  children: React.ReactNode;
}

const ButtonIcon = ({ onClick, text, children }: IButtonIConProps) => {
  return (
    <button className={styles.icon} onClick={onClick}>
      {children}
      {text}
    </button>
  );
};

export default ButtonIcon;
