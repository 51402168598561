import { Table } from "antd";
import styles from "./TablePaginationSearch.module.css";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IErrorData, ITablePaginationSearch } from "../../interfaces";
import ErrorBox from "../ErrorBox";
import useLogOut from "../../hooks/useLogOut";

const TablePaginationSearch = ({
  setPageValue,
  columns,
  dataMapping,
  data,
  isLoading,
  isFetching,
  pageValue,
  error,
  title,
  children,
}: ITablePaginationSearch) => {
  const { t } = useTranslation();

  const handlePageChange = useCallback(
    (page: number) => {
      setPageValue(page - 1);
    },
    [setPageValue],
  );

  const logOut = useLogOut();

  if (error && "data" in error) {
    return (
      <ErrorBox
        title={t("homePage.professionalExperienceSection.visualization.error")}
        description={(error.data as IErrorData).message}
        onRetry={logOut}
        text={t("homePage.professionalExperienceSection.visualization.refresh")}
      />
    );
  }

  return (
    <>
      <div className={styles.header}>
        <h3>{title}</h3>

        {children}
      </div>
      <Table
        columns={columns}
        className={styles.table}
        dataSource={dataMapping}
        loading={isLoading || isFetching}
        pagination={{
          total: data?.totalElements,
          current: pageValue + 1,
          pageSize: 10,
          onChange: handlePageChange,
          showSizeChanger: false,
          disabled: isLoading || isFetching,
        }}
      />
    </>
  );
};

export default TablePaginationSearch;
