import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ACCESSTOKEN,
  CERTIFICATIONS,
  CONTENT,
  CV_PDF,
  DATABASES,
  DOWNLOAD,
  EMPLOYEE_BASE_URL,
  EMPLOYEE_EDUCATION,
  FILTER,
  FRAMEWORK,
  ISDRAFT,
  LANGUAGES,
  LIVE_SEARCH,
  LOGO,
  LOGOUT,
  MANAGMENT_ROLE,
  ME,
  OCCUPATIONS,
  OPERATIVE_SYSTEMS,
  PROFESSIONAL_EXPERIENCES,
  PROFESSIONAL_PROFILE,
  PROGRAMMING_LANGUAGES,
  ROLE,
  SEARCH,
  SENSITIVEFIELDS,
  SKILLS,
  SOFTWARE,
  TECHINFO,
  TEMPLATES,
  TEMPLATE_NAME,
  TEMPLATE_PREVIEW,
  TUTORIAL_DATA,
  VISIBILITY,
} from "./constants";
import { IUser, IUserPost } from "../components/models/IUser";
import {
  ICertificationsApi,
  IEducationPatch,
  IGetPdfName,
  IDraft,
  IPdfParams,
  ISoftSkillsApi,
  IApiResponse,
  IEducation,
  IApiResponseManagmentRole,
  IProfessionalExperiencesGet,
  IProfessionalExperiencesPatch,
  IVisibilityProfessionalExperiences,
  IExperienceDetails,
  IApiParams,
} from "../interfaces";
import { ITechnicalSkillsValues } from "../interfaces/ITechnicalSkills";
import { ILanguages } from "../interfaces/ILanguagesContentProps";
import { IToken } from "../components/models/IToken";
import { baseQueryWithReauth } from "./refreshToken";
import { IContentPatch } from "../interfaces/ISensitiveFields";

export const api = createApi({
  tagTypes: [
    "usersInfo",
    "userRole",
    "template",
    "professionalExperiences",
    "filter",
  ],
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUserInfo: builder.query<IUser, string>({
      query: () => ({
        url: `${EMPLOYEE_BASE_URL}/${ME}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      providesTags: ["usersInfo"],
    }),

    postUserInfoById: builder.mutation<void, IUserPost>({
      query: (content) => ({
        url: `${EMPLOYEE_BASE_URL}/${TUTORIAL_DATA}`,
        method: "POST",
        body: content,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    getUserInfoPdf: builder.query<string, IPdfParams>({
      query: ({ templateName, sensitiveFields, isAnonymous }) => ({
        url: `${TEMPLATE_NAME}/${TEMPLATE_PREVIEW}`,
        method: "GET",
        responseHandler: "text",
        params: {
          templateName,
          ...sensitiveFields,
          isAnonymous,
        },
      }),
      providesTags: ["template"],
    }),

    getLayoutPdf: builder.query<IGetPdfName[], void>({
      query: () => ({
        url: TEMPLATE_NAME,
        method: "GET",
        headers: {
          "Accept-Language": window.navigator.language,
        },
      }),
      providesTags: ["template"],
    }),

    postProfessionalExperiences: builder.mutation<
      string,
      { content: IExperienceDetails; employeeId?: string }
    >({
      query: ({ content, employeeId }) => ({
        url: `${PROFESSIONAL_EXPERIENCES}`,
        method: "POST",
        body: content,
        params: { employeeId },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["professionalExperiences"],
    }),

    getProfessionalExperiences: builder.query<
      IProfessionalExperiencesGet[],
      { employeeId?: string }
    >({
      query: ({ employeeId }) => ({
        url: PROFESSIONAL_EXPERIENCES,
        params: { employeeId },
      }),

      providesTags: ["professionalExperiences"],
    }),

    deleteProfessionalExperiences: builder.mutation<
      string,
      { id: string; employeeId?: string }
    >({
      query: ({ id, employeeId }) => ({
        url: `${PROFESSIONAL_EXPERIENCES}/${id}`,
        method: "DELETE",
        params: { employeeId },
      }),
      invalidatesTags: ["professionalExperiences"],
    }),

    patchProfessionalExperiences: builder.mutation<
      string,
      IProfessionalExperiencesPatch
    >({
      query: ({ id, content, employeeId }) => ({
        url: `${PROFESSIONAL_EXPERIENCES}/${id}`,
        method: "PATCH",
        body: content,
        params: { employeeId },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["professionalExperiences"],
    }),

    patchVisibilityProfessionalExperiences: builder.mutation<
      string,
      IVisibilityProfessionalExperiences
    >({
      query: ({ id, visible, employeeId }) => ({
        url: `${PROFESSIONAL_EXPERIENCES}/${id}/${VISIBILITY}`,
        method: "PATCH",
        params: { visible, employeeId },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["professionalExperiences"],
    }),

    postEducation: builder.mutation<void, { content: IEducation; id?: string }>(
      {
        query: ({ content, id }) => ({
          url: `${EMPLOYEE_BASE_URL}/${EMPLOYEE_EDUCATION}`,
          method: "POST",
          body: content,
          params: { id },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
        invalidatesTags: ["usersInfo"],
      },
    ),

    patchEducation: builder.mutation<
      void,
      { content: IEducationPatch; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${EMPLOYEE_EDUCATION}`,
        method: "PATCH",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    patchProfessionalProfile: builder.mutation<
      string,
      { content: string[]; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${PROFESSIONAL_PROFILE}`,
        method: "PATCH",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo", "filter"],
    }),

    getOccupations: builder.query<string[], void>({
      query: () => OCCUPATIONS,
    }),

    putTechnicalSkills: builder.mutation<
      string,
      { content: ITechnicalSkillsValues; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${TECHINFO}`,
        method: "PUT",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo", "filter"],
    }),

    postCertifications: builder.mutation<
      string,
      { content: ICertificationsApi; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${CERTIFICATIONS}`,
        method: "POST",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    patchCertifications: builder.mutation<
      string,
      { content: ICertificationsApi[]; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${CERTIFICATIONS}`,
        method: "PATCH",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    postSoftSkills: builder.mutation<
      string,
      { content: ISoftSkillsApi; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${SKILLS}`,
        method: "POST",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    patchSoftSkills: builder.mutation<
      string,
      { content: ISoftSkillsApi[]; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${SKILLS}`,
        method: "PATCH",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    patchLanguages: builder.mutation<
      string,
      { content: ILanguages[]; id?: string }
    >({
      query: ({ content, id }) => ({
        url: `${EMPLOYEE_BASE_URL}/${LANGUAGES}`,
        method: "PATCH",
        body: content,
        params: { id },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["usersInfo"],
    }),

    getProgrammingLanguages: builder.query<string[], void>({
      query: () => `${LIVE_SEARCH}/${PROGRAMMING_LANGUAGES}`,
    }),

    getOperativeSystems: builder.query<string[], void>({
      query: () => `${LIVE_SEARCH}/${OPERATIVE_SYSTEMS}`,
    }),

    getFramework: builder.query<string[], void>({
      query: () => `${LIVE_SEARCH}/${FRAMEWORK}`,
    }),

    getDatabases: builder.query<string[], void>({
      query: () => `${LIVE_SEARCH}/${DATABASES}`,
    }),

    getSoftware: builder.query<string[], void>({
      query: () => `${LIVE_SEARCH}/${SOFTWARE}`,
    }),

    getLiveSearch: builder.query<string[], void>({
      query: () => LIVE_SEARCH,
    }),

    getAuth: builder.query<IToken, void>({
      query: () => ACCESSTOKEN,
    }),

    getLogout: builder.query<string, void>({
      query: () => ({
        url: LOGOUT,
        method: "GET",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }),
    }),

    postTemplates: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: TEMPLATES,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["template"],
    }),

    deleteTemplate: builder.mutation<void, string>({
      query: (id) => ({
        url: `${TEMPLATES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["template"],
    }),

    patchSensitiveFields: builder.mutation<void, IContentPatch>({
      query: ({ id, content }) => ({
        url: `${TEMPLATES}/${id}/${SENSITIVEFIELDS}`,
        method: "PATCH",
        body: content,
      }),
      invalidatesTags: ["template"],
    }),

    patchUpdateLogo: builder.mutation<void, { id: string; content: FormData }>({
      query: ({ id, content }) => ({
        url: `${TEMPLATES}/${id}/${LOGO}`,
        method: "PATCH",
        body: content,
      }),
      invalidatesTags: ["template"],
    }),

    patchUpdateHTML: builder.mutation<void, { id: string; content: FormData }>({
      query: ({ id, content }) => ({
        url: `${TEMPLATES}/${id}/${CONTENT}`,
        method: "PATCH",
        body: content,
      }),
      invalidatesTags: ["template"],
    }),

    getDownloadHtml: builder.query<void, string>({
      query: (id) => ({
        url: `${TEMPLATES}/${id}/${DOWNLOAD}`,
        method: "GET",
        responseHandler: "text",
      }),
      providesTags: ["template"],
    }),

    getDownloadLogo: builder.query<void, string>({
      query: (id) => ({
        url: `${TEMPLATES}/${id}/${LOGO}/${DOWNLOAD}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      providesTags: ["template"],
    }),

    patchIsDraft: builder.mutation<string, IDraft>({
      query: ({ id, isDraft }) => ({
        url: `${TEMPLATES}/${id}/${ISDRAFT}`,
        method: "PATCH",
        params: { isDraft },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["template"],
    }),

    getFilter: builder.query<IApiResponse, IApiParams>({
      query: ({ page, size, name, professionalProfile, technicalSkill }) => {
        const technicalSkillFilter = technicalSkill
          ?.map((el) => `technicalSkill=${el}`)
          .toString()
          .replace(",", "&");

        return {
          url: `${EMPLOYEE_BASE_URL}/${FILTER}${
            technicalSkillFilter ? "?" + technicalSkillFilter : ""
          }`,
          method: "GET",
          params: {
            page,
            size,
            name,
            professionalProfile,
          },
        };
      },
      providesTags: ["filter"],
    }),

    getEmployeeInfoPdf: builder.query<string, IPdfParams>({
      query: ({
        employeeEmail,
        templateName,
        sensitiveFields,
        isAnonymous,
      }) => ({
        url: `${TEMPLATE_NAME}/${TEMPLATE_PREVIEW}/${employeeEmail}`,
        method: "GET",
        responseHandler: "text",
        params: {
          templateName,
          ...sensitiveFields,
          isAnonymous,
        },
      }),
      providesTags: ["template"],
    }),

    getUserInfoById: builder.query<IUser, string>({
      query: (id) => ({
        url: `${EMPLOYEE_BASE_URL}/${id}`,
      }),
      providesTags: ["usersInfo"],
    }),

    getSearchUserRole: builder.query<
      IApiResponseManagmentRole,
      { page: number; size: number; value: string }
    >({
      query: ({ page, size, value }) => ({
        url: `/${MANAGMENT_ROLE}/${SEARCH}`,
        method: "GET",
        params: { page, size, value },
      }),
      providesTags: ["userRole"],
    }),

    patchUpdateUserRole: builder.mutation<
      void,
      { email: string; newRole: string }
    >({
      query: ({ email, newRole }) => ({
        url: `${MANAGMENT_ROLE}/${ROLE}`,
        method: "PATCH",
        params: { email, role: newRole },
      }),
      invalidatesTags: ["userRole"],
    }),

    getPersonalCv: builder.query<string, IPdfParams>({
      query: ({ templateName, sensitiveFields, isAnonymous }) => ({
        url: `${CV_PDF}/${DOWNLOAD}`,
        method: "GET",
        params: {
          templateName,
          ...sensitiveFields,
          isAnonymous,
        },

        cache: "no-cache",
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response: Blob) => {
        const url = window.URL.createObjectURL(response);
        return url;
      },
    }),

    getEmployeeCv: builder.query<string, IPdfParams>({
      query: ({
        employeeEmail,
        templateName,
        sensitiveFields,
        isAnonymous,
      }) => ({
        url: `${CV_PDF}/${DOWNLOAD}/${employeeEmail}`,
        method: "GET",
        params: {
          employeeEmail,
          templateName,
          ...sensitiveFields,
          isAnonymous,
        },
        cache: "no-cache",
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response: Blob) => {
        const url = window.URL.createObjectURL(response);
        return url;
      },
    }),
  }),
});

export const {
  useGetFilterQuery,
  useGetUserInfoQuery,
  usePatchUpdateUserRoleMutation,
  useGetSearchUserRoleQuery,
  usePostUserInfoByIdMutation,
  useLazyGetUserInfoPdfQuery,
  useGetLayoutPdfQuery,
  usePostProfessionalExperiencesMutation,
  useGetProfessionalExperiencesQuery,
  useDeleteProfessionalExperiencesMutation,
  usePatchProfessionalExperiencesMutation,
  usePostEducationMutation,
  usePatchEducationMutation,
  usePatchProfessionalProfileMutation,
  useGetOccupationsQuery,
  usePutTechnicalSkillsMutation,
  usePostCertificationsMutation,
  usePatchCertificationsMutation,
  usePostSoftSkillsMutation,
  usePatchSoftSkillsMutation,
  usePatchLanguagesMutation,
  usePatchVisibilityProfessionalExperiencesMutation,
  useGetProgrammingLanguagesQuery,
  useGetOperativeSystemsQuery,
  useGetFrameworkQuery,
  useGetDatabasesQuery,
  useGetSoftwareQuery,
  useGetLiveSearchQuery,
  useGetAuthQuery,
  useLazyGetLogoutQuery,
  usePostTemplatesMutation,
  useDeleteTemplateMutation,
  usePatchSensitiveFieldsMutation,
  useLazyGetDownloadHtmlQuery,
  useLazyGetDownloadLogoQuery,
  usePatchIsDraftMutation,
  useLazyGetEmployeeInfoPdfQuery,
  useLazyGetUserInfoByIdQuery,
  usePatchUpdateLogoMutation,
  usePatchUpdateHTMLMutation,
  useLazyGetPersonalCvQuery,
  useLazyGetEmployeeCvQuery,
} = api;
