import { RoutesPath } from "../constants/RoutesEnum";

type MapFn = (value: RoutesPath, templateName?: string) => string;
export type LocationTypeMap = Record<RoutesPath, string>;

const defaultTitle = "CV Aitho";

const pdfTitles: Record<string, string> = {
  "Nuovo Intesa": "Intesa Template",
  "Nuovo Aitho": "Aitho Template",
};

const locationTitleMap: LocationTypeMap = {
  [RoutesPath.ROOT]: `${defaultTitle}`,
  [RoutesPath.TUTORIAL]: `Tutorial - ${defaultTitle}`,
  [RoutesPath.USEREDITOR]: `Dashboard - ${defaultTitle}`,
  [RoutesPath.ADMIN]: `Admin - ${defaultTitle}`,
  [RoutesPath.PDF_OVERVIEW]: `Export Pdf - ${defaultTitle}`,
  [RoutesPath.LOGIN]: `Login - ${defaultTitle}`,
  [RoutesPath.SUCCESS_PAGE]: `Tutorial - ${defaultTitle}`,
  [RoutesPath.PDF]: ``,
};

const mapLocationTitle: MapFn = (location, templateName = "Aitho Template") => {
  if (location === RoutesPath.PDF) {
    return `${pdfTitles[templateName] || pdfTitles.Aitho}`;
  }
  return locationTitleMap[location] ?? defaultTitle;
};

export default mapLocationTitle;
