import styles from "./ProfessionalExperience.module.css";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { openNotification } from "../Notification/Notification";
import {
  Button,
  Checkbox,
  DatePicker,
  Empty,
  Flex,
  Form,
  Input,
  Select,
  Spin,
  Tabs,
  TabsProps,
  notification,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import TextArea from "antd/es/input/TextArea";
import ExperienceCard from "../ExperienceCard";
import { Language } from "./utils/constants";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import { useTranslation } from "react-i18next";
import { FormatDate } from "../../constants/FormatDateEnum";
import { initialProExperience } from "./utils/initialProExperience";
import { useHandleOnFinish } from "./hooks";
import {
  useGetLiveSearchQuery,
  useGetOccupationsQuery,
  useGetProfessionalExperiencesQuery,
} from "../../services/api";
import {
  IErrorData,
  IProExperience,
  IProfessionalExperiencesGet,
} from "../../interfaces";
import { filteredTools } from "../../utils/filteredTools";
import { sortedExperienceStartDate } from "../../utils/datesCronologicalOrder";
import { HiArrowsUpDown } from "react-icons/hi2";
import {
  disabledDateGreaterCurrentYearAndMonths,
  disabledEndDate,
} from "../../utils/disabledDate";
import { PlusOutlined } from "@ant-design/icons";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import ButtonIcon from "../ButtonIcon";
import _ from "lodash";
import { mappingLabelItem } from "./utils/utils";
import { FormName } from "../ExperienceCard/utils/constants";
import ErrorBox from "../ErrorBox";

interface ProExperienceProps {
  title: string;
}

const ProfessionalExperience = ({ title }: ProExperienceProps) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isOrder, setIsOrder] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [checkedNDA, setCheckedNDA] = useState<boolean>(false);
  const [proExperience, setProExperience] = useState<
    IProfessionalExperiencesGet[]
  >([]);
  const [tempProExperience, setTempProExperience] =
    useState<IProExperience>(initialProExperience);
  const [language, setLanguage] = useState<Language>(Language.ITA);
  const [selectedTools, setSelectedTools] = useState<string[]>([]);
  const [liveSearch, setLiveSearch] = useState<string[] | undefined>([]);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);
  const {
    data,
    error: errorProfessionalExperiences,
    isFetching,
    isLoading,
  } = useGetProfessionalExperiencesQuery({ employeeId: idEmployee.employeeId });

  const { data: dataOccupations, error } = useGetOccupationsQuery();
  const { data: dataLiveSearch, error: errorLiveSearch } =
    useGetLiveSearchQuery();

  useEffect(() => {
    if (data) {
      setProExperience(data);
    }
  }, [data]);

  const { handleOnFinish, isLoadingPost } = useHandleOnFinish({
    tempProExperience,
    checked,
    checkedNDA,
    data,
    setTempProExperience,
    setLanguage,
    api,
    form,
    setIsDisabled,
    selectedTools,
    setChecked,
    setCheckedNDA,
  });

  const loadingOrErrorUI = useMemo(() => {
    if (
      errorProfessionalExperiences &&
      "data" in errorProfessionalExperiences
    ) {
      return (
        <ErrorBox
          title={t(
            "homePage.professionalExperienceSection.visualization.error",
          )}
          description={
            (errorProfessionalExperiences.data as IErrorData).message
          }
        />
      );
    }

    if (isLoading || isFetching) {
      return <Spin size="large" />;
    }
  }, [errorProfessionalExperiences, isFetching, isLoading, t]);

  const languages: TabsProps["items"] = [
    {
      key: Language.ITA,
      label: `${t("homePage.professionalExperienceSection.form.itaTab")}*`,
    },
    {
      key: Language.ENG,
      label: `${t("homePage.professionalExperienceSection.form.engTab")}`,
      disabled: true,
    },
  ];

  const onChangeTab = useCallback(
    (key: string) => setLanguage(key as Language),
    [],
  );

  const handleVisibleEditBox = useCallback(() => {
    setIsDisabled((prev) => !prev);
    form.resetFields();
  }, [form]);

  const exitForm = useCallback(() => {
    form.resetFields();
    setIsDisabled((prev) => !prev);
    setChecked(false);
    setCheckedNDA(false);
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.description",
      ),
      NotificationTypes.WARNING,
    );
  }, [api, form, t]);

  const isSubmitButtonDisabled = useMemo(() => {
    const requiredItalianFields = [
      "role",
      "company",
      "projectName",
      "startDate",
    ];

    const areItalianFieldsFilled = requiredItalianFields.every(
      (field) => !!tempProExperience[field as keyof IProExperience],
    );

    return !areItalianFieldsFilled;
  }, [tempProExperience]);

  const handleOnChangeTemp = useCallback(
    (_: any, allValues: IProExperience) => {
      setTempProExperience((prev) => {
        return { ...prev, ...allValues };
      });
    },
    [],
  );

  const handleCheckboxChange = useCallback(
    (set: Dispatch<SetStateAction<boolean>>) => (e: CheckboxChangeEvent) =>
      set(e.target.checked),
    [],
  );

  const roleProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.rolePlaceholder",
      );
    } else {
      return tempProExperience.role !== ""
        ? tempProExperience.role
        : t(
            "homePage.professionalExperienceSection.form.placeholders.rolePlaceholder",
          );
    }
  }, [language, tempProExperience.role, t]);

  const finalCustomerProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.finalCustomerPlaceholder",
      );
    } else {
      return tempProExperience.finalCustomer !== ""
        ? tempProExperience.finalCustomer
        : t(
            "homePage.professionalExperienceSection.form.placeholders.finalCustomerPlaceholder",
          );
    }
  }, [language, t, tempProExperience.finalCustomer]);

  const finalCustomerProjectNDA = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.finalCustomerNDAPlaceholder",
      );
    }
    return tempProExperience.finalCustomerNDA !== null
      ? tempProExperience.finalCustomerNDA
      : t(
          "homePage.professionalExperienceSection.form.placeholders.finalCustomerNDAPlaceholder",
        );
  }, [language, t, tempProExperience.finalCustomerNDA]);

  const companyProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.companyPlaceholder",
      );
    } else {
      return tempProExperience.company !== ""
        ? tempProExperience.company
        : t(
            "homePage.professionalExperienceSection.form.placeholders.companyPlaceholder",
          );
    }
  }, [language, tempProExperience.company, t]);

  const placeProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.placeAgencyPlaceholder",
      );
    } else {
      return tempProExperience.company !== ""
        ? tempProExperience.company
        : t(
            "homePage.professionalExperienceSection.form.placeholders.placeAgencyPlaceholder",
          );
    }
  }, [language, tempProExperience.company, t]);

  const projectNameProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.projectNamePlaceholder",
      );
    } else {
      return tempProExperience.projectName !== ""
        ? tempProExperience.projectName
        : t(
            "homePage.professionalExperienceSection.form.placeholders.projectNamePlaceholder",
          );
    }
  }, [language, tempProExperience.projectName, t]);

  const toolsProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.toolsPlaceholder",
      );
    } else {
      const toolsString = tempProExperience.tools.join(", ");
      return tempProExperience.tools.length > 0
        ? toolsString
        : t(
            "homePage.professionalExperienceSection.form.placeholders.toolsPlaceholder",
          );
    }
  }, [language, t, tempProExperience.tools]);

  const descriptionProject = useMemo(() => {
    if (language === Language.ITA) {
      return t(
        "homePage.professionalExperienceSection.form.placeholders.descriptionPlaceholder",
      );
    } else {
      return tempProExperience.description !== ""
        ? tempProExperience.description
        : t(
            "homePage.professionalExperienceSection.form.placeholders.descriptionPlaceholder",
          );
    }
  }, [language, tempProExperience.description, t]);

  const showNotification = useCallback(
    (title: string, description: string, type: NotificationTypes) => {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(title),
        t(description),
        type,
      );
    },
    [api, t],
  );

  const changeOrder = useCallback(() => {
    setIsOrder((prev) => !prev);
  }, []);

  const sortedExperience = sortedExperienceStartDate(proExperience, isOrder);

  return (
    <div className={styles.experienceInfoContainer}>
      {contextHolder}
      <div className={styles.sectionTitle}>
        <div className={styles.dividerContainer}>
          <h3>{title}</h3>
          <div className={styles.icon}>
            <ButtonIcon
              onClick={handleVisibleEditBox}
              text={t("tutorial.insertBtn")}
            >
              <PlusOutlined />
            </ButtonIcon>
          </div>
        </div>

        <Button onClick={() => changeOrder()} className={styles.buttonOrder}>
          <HiArrowsUpDown style={{ fontSize: "18px" }} />
        </Button>
      </div>
      <div className={styles.experience}>
        {!isDisabled && (
          <div className={styles.addExperienceBox}>
            <Tabs
              defaultActiveKey={Language.ITA}
              items={languages}
              onChange={onChangeTab}
              activeKey={language}
            />
            <Form
              onFinish={handleOnFinish}
              onValuesChange={handleOnChangeTemp}
              className={styles.formContainer}
              form={form}
            >
              <Form.Item
                className={styles.formItem}
                style={{ display: "flex" }}
              >
                <Checkbox
                  checked={checkedNDA}
                  onChange={handleCheckboxChange(setCheckedNDA)}
                >
                  {t(
                    "homePage.professionalExperienceSection.form.labels.currentNDACheckBoxLabel",
                  )}
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={mappingLabelItem[language].role}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.role",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.role",
                )}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  filterOption={(input, option) => {
                    return (
                      (option?.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  placeholder={roleProject}
                  notFoundContent={
                    error &&
                    "data" in error && (
                      <p className={styles.error}>
                        {t(
                          "homePage.professionalExperienceSection.visualization.error",
                        )}
                        <br />
                        {(error.data as IErrorData).message}
                      </p>
                    )
                  }
                >
                  {dataOccupations?.map((level) => (
                    <Select.Option key={_.uniqueId("id_")} value={level}>
                      {level}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={mappingLabelItem[language].company}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.company",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.company",
                )}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  className={styles.experienceInput}
                  placeholder={companyProject}
                  value={tempProExperience.company}
                />
              </Form.Item>
              <Form.Item
                name={mappingLabelItem[language].place}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.place",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.place",
                )}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  className={styles.experienceInput}
                  placeholder={placeProject}
                  value={tempProExperience.place}
                />
              </Form.Item>
              <Form.Item
                name={mappingLabelItem[language].finalCustomer}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.finalCustomer",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.finalCustomer",
                )}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  className={styles.experienceInput}
                  placeholder={finalCustomerProject}
                />
              </Form.Item>

              {checkedNDA && (
                <Form.Item
                  name={mappingLabelItem[language].finalCustomerNDA}
                  tooltip={t(
                    "homePage.professionalExperienceSection.form.tooltip.finalCustomerNDA",
                  )}
                  label={t(
                    "homePage.professionalExperienceSection.form.labels.finalCustomerNDA",
                  )}
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("errormsg.genericInput"),
                    },
                  ]}
                >
                  <Input
                    className={styles.experienceInput}
                    placeholder={finalCustomerProjectNDA}
                  />
                </Form.Item>
              )}

              <Form.Item
                name={mappingLabelItem[language].projectName}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.projectName",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.projectName",
                )}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Input
                  className={styles.experienceInput}
                  placeholder={projectNameProject}
                />
              </Form.Item>
              <Form.Item
                name={mappingLabelItem[language].tools}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.tools",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.tools",
                )}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericInput"),
                  },
                ]}
              >
                <Select
                  onSelect={() => setLiveSearch([])}
                  listItemHeight={32}
                  mode="tags"
                  placeholder={toolsProject}
                  value={selectedTools}
                  onChange={(selected) => setSelectedTools(selected)}
                  onSearch={(input) =>
                    filteredTools(input, dataLiveSearch, setLiveSearch)
                  }
                  notFoundContent={
                    errorLiveSearch &&
                    "data" in errorLiveSearch && (
                      <p className={styles.error}>
                        {t(
                          "homePage.professionalExperienceSection.visualization.error",
                        )}
                        <br />
                        {(errorLiveSearch.data as IErrorData).message}
                      </p>
                    )
                  }
                >
                  {liveSearch?.map((tool) => (
                    <Select.Option key={_.uniqueId("id_")} value={tool}>
                      {tool}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={mappingLabelItem[language].description}
                tooltip={t(
                  "homePage.professionalExperienceSection.form.tooltip.description",
                )}
                label={t(
                  "homePage.professionalExperienceSection.form.labels.description",
                )}
                rules={[
                  {
                    required: true,
                    message: t("errormsg.genericDate"),
                  },
                ]}
                className={styles.formItem}
              >
                <TextArea
                  rows={4}
                  className={`${styles.experienceInput} ${styles.experienceTextArea}`}
                  placeholder={descriptionProject}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                style={{ display: "flex" }}
              >
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange(setChecked)}
                >
                  {t(
                    "homePage.professionalExperienceSection.form.labels.currentProjectCheckBoxLabel",
                  )}
                </Checkbox>
              </Form.Item>
              <Flex
                className={styles.dataForm}
                gap="large"
                justify="flex-start"
              >
                <Form.Item
                  name={FormName.STARTDATE}
                  label={t(
                    "homePage.professionalExperienceSection.form.labels.startDateLabel",
                  )}
                  rules={[
                    {
                      required: true,
                      message: t("errormsg.genericDate"),
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    className={styles.experienceInput}
                    format={FormatDate.DATE_MONTH_Y}
                    picker="month"
                    placeholder={t(
                      "homePage.professionalExperienceSection.form.placeholders.startDatePlaceholder",
                    )}
                    disabledDate={disabledDateGreaterCurrentYearAndMonths}
                  />
                </Form.Item>
                {!checked && (
                  <Form.Item
                    name={FormName.ENDDATE}
                    label={t(
                      "homePage.professionalExperienceSection.form.labels.endDateLabel",
                    )}
                    rules={[
                      {
                        required: true,
                        message: t("errormsg.genericDate"),
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <DatePicker
                      className={styles.experienceInput}
                      format={FormatDate.DATE_MONTH_Y}
                      picker="month"
                      placeholder={t(
                        "homePage.professionalExperienceSection.form.placeholders.endDatePlaceholder",
                      )}
                      disabledDate={(current) =>
                        disabledEndDate(
                          current,
                          tempProExperience.startDate as string,
                        )
                      }
                    />
                  </Form.Item>
                )}
              </Flex>
              <Form.Item className={styles.formItem}>
                <Flex
                  gap="small"
                  align="baseline"
                  style={{ width: "100%" }}
                  justify="flex-end"
                >
                  <Flex gap="small" wrap="wrap">
                    <Button
                      type="primary"
                      size={"middle"}
                      className={styles.submitButton}
                      htmlType="submit"
                      disabled={isSubmitButtonDisabled || isLoadingPost}
                    >
                      {isLoadingPost && <Spin size="large" />}
                      {t(
                        "homePage.professionalExperienceSection.form.addButton",
                      )}
                    </Button>
                    <Button
                      size={"middle"}
                      className={styles.undoButton}
                      onClick={exitForm}
                    >
                      {t(
                        "homePage.professionalExperienceSection.form.cancelButton",
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Form.Item>
            </Form>
          </div>
        )}

        {data?.length ? (
          <>
            {sortedExperience?.map((experience) => (
              <ExperienceCard
                id={experience.id}
                key={experience.id}
                priority={experience.priority}
                visible={experience.visible}
                totalMonths={experience.totalMonths}
                place={experience.place}
                company={experience.company}
                projects={experience.projects}
                role={experience.role}
                startDate={experience.startDate}
                endDate={experience.endDate}
                showNotification={showNotification}
              />
            ))}
          </>
        ) : (
          <>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t(
                "homePage.professionalExperienceSection.visualization.emptyExperiences",
              )}
            />
            {loadingOrErrorUI}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfessionalExperience;
