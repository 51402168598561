export enum DownloadType {
  HTML = "html",
  JPEG = "jpeg",
}

export enum AcceptType {
  HTML = ".html",
  IMAGE = ".png,.jpg,.jpeg",
}

export enum DownloadFile {
  FILE = "text/html",
  IMAGE = "image/jpeg",
}

export const downloadMappingFile: Record<DownloadType, DownloadFile> = {
  [DownloadType.HTML]: DownloadFile.FILE,
  [DownloadType.JPEG]: DownloadFile.IMAGE,
};
