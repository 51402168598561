export const EMPLOYEE_BASE_URL = "/employees";
export const ME = "me";
export const TUTORIAL_DATA = "tutorial-input-data";
export const TEMPLATE_PREVIEW = "preview";
export const TEMPLATE_NAME = "templates";
export const PROFESSIONAL_EXPERIENCES = "professional-experiences";
export const EMPLOYEE_EDUCATION = "education";
export const PROFESSIONAL_PROFILE = "professional-profile";
export const OCCUPATIONS = "occupations";
export const TECHINFO = "tech-info";
export const CERTIFICATIONS = "certifications";
export const SKILLS = "skills";
export const LANGUAGES = "languages";
export const VISIBILITY = "visibility";
export const LIVE_SEARCH = "live-search";
export const PROGRAMMING_LANGUAGES = "programming-languages";
export const OPERATIVE_SYSTEMS = "operative-systems";
export const FRAMEWORK = "frameworks";
export const DATABASES = "databases";
export const REFRESHTOKEN = "/auth/refresh";
export const ACCESSTOKEN = "/auth/token";
export const LOGOUT = "/auth/logout";
export const TEMPLATES = "/templates";
export const SENSITIVEFIELDS = "sensitive-fields";
export const DOWNLOAD = "download";
export const LOGO = "logo";
export const ISDRAFT = "is-draft";
export const SEARCH = "search";
export const MANAGMENT_ROLE = "users-roles";
export const ROLE = "role";
export const CONTENT = "content";
export const SOFTWARE = "software";
export const FILTER = "filter";
export const CV_PDF = "cv/pdf";
