import dayjs from "dayjs";
import { FormatDate } from "../constants/FormatDateEnum";
import { IProfessionalExperiencesGet } from "../interfaces";

export const sortedExperienceStartDate = (
  proExperience: IProfessionalExperiencesGet[],
  isOrder: boolean,
) => {
  return proExperience.slice().sort((a, b) => {
    const dateA = dayjs(a.startDate, FormatDate.DATE_MONTH_Y);
    const dateB = dayjs(b.startDate, FormatDate.DATE_MONTH_Y);

    return isOrder ? dateA.diff(dateB) : dateB.diff(dateA);
  });
};
