import { IEducation, ISoftSkillsApi } from "../../interfaces";
import { ITechnicalSkillsValues } from "../../interfaces/ITechnicalSkills";
import { LangType } from "./langType";

export interface IUser {
  id: string;
  createdDate: string;
  lastModifiedDate: string;
  name: string;
  surname: string;
  email: string;
  birthDate: string;
  professionalProfiles: string[];
  skills: ISoftSkillsApi[];
  technicalInformation: ITechnicalSkillsValues;
  certifications: [
    {
      name: string;
      url: string;
      expireYear: number;
      releaseYear: number;
    },
  ];
  educations: IEducation[];
  languages: LangType[];
  tutorialCompleted: boolean;
}

export interface IUserPost {
  educations: IEducation[];
  languages: LangType[];
}

export const initialUserState: IUser = {
  id: "",
  createdDate: "",
  lastModifiedDate: "",
  name: "",
  surname: "",
  email: "",
  birthDate: "",
  professionalProfiles: [],
  skills: [
    {
      name: "",
      level: "",
    },
  ],
  technicalInformation: {
    programmingLanguages: [],
    frameworks: [],
    databases: [],
    operativeSystems: [],
    software: [],
  },
  tutorialCompleted: false,
  certifications: [
    {
      name: "",
      url: "",
      expireYear: 0,
      releaseYear: 0,
    },
  ],
  educations: [
    {
      qualification: "",
      institution: "",
      studyField: "",
      graduationYear: 0,
    },
  ],
  languages: [
    {
      name: "",
      level: "",
    },
  ],
};
