import { Button, DatePicker, Flex, Form, Input, Spin } from "antd";
import styles from "./FormEducation.module.css";
import { useTranslation } from "react-i18next";
import { IFormEducation } from "../../../interfaces";
import { disabledDateGreaterCurrentYear } from "../../../utils/disabledDate";
import { FormatDate } from "../../../constants/FormatDateEnum";
import { InputName } from "./utils/InputName";

const FormEducation = ({
  handleOnChangeTemp,
  handleOnFinish,
  closeForm,
  isValid,
  initialValues,
  title,
  isEditing,
  handleFormChange,
  isLoading,
  form,
}: IFormEducation) => {
  const { t } = useTranslation();

  return (
    <Form
      className={!isEditing ? styles.form : styles.formEdit}
      onValuesChange={handleOnChangeTemp}
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFieldsChange={handleFormChange}
      form={form}
    >
      <Form.Item
        className={styles.formItem}
        name={InputName.QUALIFICATION}
        label={`${t("tutorial.educationPage.labelQualification")}:`}
        tooltip={t("tooltip.tooltipQualification")}
        rules={[
          {
            required: true,
            message: t("errormsg.genericInput"),
          },
        ]}
      >
        <Input
          placeholder={t("tutorial.educationPage.placeholderQualification")}
        />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={InputName.ISTITUTION}
        label={`${t("tutorial.educationPage.labelPlace")}:`}
        tooltip={t("tooltip.tooltipInstitution")}
        rules={[
          {
            required: true,
            message: t("errormsg.genericInput"),
          },
        ]}
      >
        <Input placeholder={t("tutorial.educationPage.placeholderPlace")} />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={InputName.STYDYFIELD}
        label={`${t("tutorial.educationPage.labelDescription")}:`}
        tooltip={t("tooltip.tooltipFieldStudy")}
        rules={[
          {
            required: true,
            message: t("errormsg.genericInput"),
          },
        ]}
      >
        <Input
          placeholder={t("tutorial.educationPage.placeholderDescription")}
        />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name={InputName.GRADUATIONYEAR}
        label={`${t("tutorial.educationPage.labelDate")}:`}
        tooltip={t("tooltip.tooltipYearAttainment")}
        rules={[
          {
            required: true,
            message: t("errormsg.genericDate"),
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%", padding: "8px 11px 8px" }}
          placeholder={t("tutorial.educationPage.placeholderRangePicker")}
          format={FormatDate.DATE_Y}
          picker="year"
          disabledDate={disabledDateGreaterCurrentYear}
        />
      </Form.Item>
      <Form.Item>
        <Flex
          gap="small"
          align="baseline"
          style={{ width: "100%" }}
          justify="flex-end"
        >
          <Flex gap="small" wrap="wrap">
            <Button
              type="primary"
              size={"middle"}
              htmlType="submit"
              disabled={!isValid || isLoading}
            >
              {isLoading && <Spin size="large" />}
              {t(title)}
            </Button>
            <Button size={"middle"} onClick={closeForm}>
              {t("homePage.professionalExperienceSection.form.cancelButton")}
            </Button>
          </Flex>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default FormEducation;
