import styles from "./Login.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Row, notification } from "antd";
import { openNotification } from "../../components/Notification/Notification";
import logo from "./../../icon/aitho-white.svg";
import office from "./../../icon/o365.png";
import { useTranslation } from "react-i18next";
import { RoutesPath } from "../../constants/RoutesEnum";
import { useEffect } from "react";
import {
  NotificationPosition,
  NotificationTypes,
} from "../../components/Notification/utils/constants";
import { once } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const { value } = useSelector((state: RootState) => state.errorApiCallSlice);

  const notifyOnce = once(() => {
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.warning.title",
      ),
      value ??
        t(
          "homePage.professionalExperienceSection.form.notifications.warningLogout",
        ),
      NotificationTypes.INFO,
    );
  });

  useEffect(() => {
    if (state?.error) {
      notifyOnce();
    }
  }, [state?.error, api, notifyOnce]);

  const handleLogin = async () => {
    navigate(RoutesPath.LOGIN);
  };

  return (
    <div className={styles.bgLogin}>
      {contextHolder}
      <div className={styles.form}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <Row justify={"center"}>
          <Flex justify="center" style={{ maxWidth: 600 }}>
            <div className={styles.openidWrapper}>
              <Button
                type="default"
                size="large"
                onClick={handleLogin}
                className={styles.btnOffice}
              >
                <img src={office} alt="openid" />
                <span className={styles.openidSpan}>
                  {t("login.placeholderOpenId")}
                </span>
              </Button>
            </div>
          </Flex>
        </Row>
      </div>
      <p className={styles.privacy}>{t("login.privacyMessage")}</p>
    </div>
  );
};

export default Login;
