import { Modal } from "antd";
import React from "react";

interface IWarningDialog {
  isWarningDialogOpen: boolean;
  closeWarningDialogModal: () => void;
  preDownload: () => void;
  title: string;
  okText: string;
  cancelText: string;
  description: string;
}

const WarningDialog = ({
  isWarningDialogOpen,
  closeWarningDialogModal,
  preDownload,
  title,
  okText,
  cancelText,
  description,
}: IWarningDialog) => (
  <Modal
    title={title}
    open={isWarningDialogOpen}
    onOk={preDownload}
    onCancel={closeWarningDialogModal}
    okText={okText}
    cancelText={cancelText}
  >
    <p>{description}</p>
  </Modal>
);

export default React.memo(WarningDialog);
