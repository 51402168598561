import { Button, Empty, Flex, Form, Select, Spin, notification } from "antd";
import CustomDivider from "../CustomDivider";
import styles from "./ProfessionalProfile.module.css";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  useGetOccupationsQuery,
  usePatchProfessionalProfileMutation,
} from "../../services/api";
import { openNotification } from "../Notification/Notification";
import {
  NotificationPosition,
  NotificationTypes,
} from "../Notification/utils/constants";
import {
  InitialValues,
  initialValues,
} from "../../interfaces/IProfessionalProfileProps";
import { IErrorData } from "../../interfaces";
import _ from "lodash";

interface IProfessionalProfileProps {
  title: string;
}

const ProfessionalProfile = ({ title }: IProfessionalProfileProps) => {
  const user = useSelector((state: RootState) => state.user);
  const [isValid, setIsValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [professionalProfileValue, setProfessionalProfileValue] =
    useState<InitialValues>(initialValues);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [patchProfessionalProfile, { isLoading }] =
    usePatchProfessionalProfileMutation();
  const [api, contextHolder] = notification.useNotification();
  const { data: dataOccupations, error } = useGetOccupationsQuery();
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);

  useEffect(() => {
    setProfessionalProfileValue({
      professionalProfiles: user.professionalProfiles,
    });
  }, [user.professionalProfiles]);

  const handleVisibleEditBox = useCallback(() => {
    setIsDisabled((prev) => !prev);
  }, []);

  const handleOnChangeTemp = useCallback(
    (_: any, values: InitialValues) =>
      setProfessionalProfileValue((prev) => {
        setIsValid(!!values.professionalProfiles);
        return { ...prev, ...values };
      }),
    [],
  );

  const handleOnFinish = useCallback(async () => {
    const response = await patchProfessionalProfile({
      content: professionalProfileValue.professionalProfiles,
      id: idEmployee.employeeId,
    });

    setProfessionalProfileValue(initialValues);
    form.resetFields();
    setIsDisabled(false);
    setIsValid(false);

    if ("error" in response && "data" in response.error) {
      openNotification(
        NotificationPosition.TOP_RIGHT,
        api,
        t(
          "homePage.professionalExperienceSection.form.notifications.errorProfessionalProfile.title",
        ),
        (response.error.data as IErrorData).message,
        NotificationTypes.ERROR,
      );
      return;
    }

    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.successProfessionalProfile.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.successProfessionalProfile.description",
      ),
      NotificationTypes.SUCCESS,
    );
  }, [
    api,
    form,
    idEmployee.employeeId,
    patchProfessionalProfile,
    professionalProfileValue.professionalProfiles,
    t,
  ]);

  const closeForm = useCallback(() => {
    setIsDisabled(false);
    setIsValid(false);
    openNotification(
      NotificationPosition.TOP_RIGHT,
      api,
      t(
        "homePage.professionalExperienceSection.form.notifications.warningProfessionalProfile.title",
      ),
      t(
        "homePage.professionalExperienceSection.form.notifications.warningProfessionalProfile.description",
      ),
      NotificationTypes.WARNING,
    );
  }, [api, t]);

  const conditionalRenderingForHomeViewing = useMemo(() => {
    if (!user.professionalProfiles.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("homePage.roleSection.emptyRole")}
        />
      );
    }
    return (
      <div>
        {user.professionalProfiles.map((role) => (
          <ul key={_.uniqueId("id_")} style={{ marginTop: "20px" }}>
            <li className={styles.profile}>{role}</li>
          </ul>
        ))}
      </div>
    );
  }, [t, user.professionalProfiles]);

  return (
    <div className={styles.ProfessionalProfile}>
      <CustomDivider title={title} onEdit={handleVisibleEditBox} />
      {contextHolder}
      {isDisabled ? (
        <Form
          form={form}
          className={styles.form}
          onValuesChange={handleOnChangeTemp}
          initialValues={professionalProfileValue}
          onFinish={handleOnFinish}
        >
          <Form.Item
            className={styles.formItem}
            name={"professionalProfiles"}
            label={`${t("homePage.roleSection.labelProfessionalProfile")}:`}
            tooltip={t("homePage.roleSection.tooltip")}
          >
            <Select
              showSearch={true}
              mode="tags"
              filterOption={(input, option) =>
                (option?.props.children as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={t(
                "homePage.roleSection.placeholderProfessionalProfile",
              )}
              notFoundContent={
                error &&
                "data" in error && (
                  <p className={styles.error}>
                    {t(
                      "homePage.professionalExperienceSection.visualization.error",
                    )}
                    <br />
                    {(error.data as IErrorData).message}
                  </p>
                )
              }
            >
              {dataOccupations?.map((level) => (
                <Select.Option key={_.uniqueId("id_")} value={level}>
                  {level}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Flex
              gap="small"
              align="baseline"
              style={{ width: "100%" }}
              justify="flex-end"
            >
              {professionalProfileValue.professionalProfiles.length > 2 && (
                <p className={styles.limit}>
                  {t("homePage.roleSection.alertLimit")}
                </p>
              )}
              <Button
                type="primary"
                size={"middle"}
                htmlType="submit"
                disabled={
                  !isValid ||
                  isLoading ||
                  professionalProfileValue.professionalProfiles.length > 2
                }
              >
                {isLoading && <Spin size="large" />}
                {t("saveBtn")}
              </Button>
              <Button size={"middle"} onClick={closeForm}>
                {t("homePage.professionalExperienceSection.form.cancelButton")}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      ) : (
        conditionalRenderingForHomeViewing
      )}
    </div>
  );
};

export default ProfessionalProfile;
