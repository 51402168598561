import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, TokenPayload } from "../components/models/IToken";

export const tokenSlice = createSlice({
  name: "token",
  initialState: initialState,
  reducers: {
    setIdToken: (state, action: PayloadAction<TokenPayload>) =>
      (state = { ...state, ...action.payload }),
    logout: () => initialState,
  },
});
export const { setIdToken, logout } = tokenSlice.actions;
export default tokenSlice.reducer;
