import { Language } from "./constants";

interface ILabelsFormItems {
  role: string;
  company: string;
  place: string;
  finalCustomer: string;
  finalCustomerNDA: string;
  projectName: string;
  description: string;
  tools: string;
}

export const mappingLabelItem: Record<Language, ILabelsFormItems> = {
  [Language.ITA]: {
    role: "role",
    company: "company",
    place: "place",
    finalCustomer: "finalCustomer",
    finalCustomerNDA: "finalCustomerNDA",
    projectName: "projectName",
    description: "description",
    tools: "tools",
  },
  [Language.ENG]: {
    role: "engRole",
    place: "engplace",
    company: "engCompany",
    finalCustomer: "engFinalCustomer",
    finalCustomerNDA: "engfinalCustomerNDA",
    projectName: "engProjectName",
    description: "engDescription",
    tools: "engTools",
  },
};
