import styles from "./UserEditor.module.css";
import Sidebar from "../../components/Sidebar";
import ContentSidebarUser from "../../components/Sidebar/ContentSidebarUser";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ContentSidebarAdmin from "../../components/Sidebar/ContentSidebarAdmin";
import { RoleEnum } from "../../constants/RoleEnum";
import { useLazyGetUserInfoByIdQuery } from "../../services/api";
import { useEffect, useMemo } from "react";
import { setCurrentUser } from "../../slices/userSlice";
import Loading from "../../components/Loading";
import { LoadingType } from "../../components/Loading/LoadingType";
import LoadTemplate from "../../components/LoadTemplate";
import { ContentsComponent } from "../../constants/ContentsComponentEnum";
import ContainerUserInfo from "../../components/ContainerUserInfo";

const UserEditor = () => {
  const { t } = useTranslation();
  const token = useSelector((state: RootState) => state.token);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);
  const [GetDataEmployee, { data: dataEmployee, isLoading, isFetching }] =
    useLazyGetUserInfoByIdQuery();
  const dispatch = useDispatch();
  const component = useSelector(
    (state: RootState) => state.componentTemplateEditor.component,
  );

  useEffect(() => {
    if (idEmployee.employeeId) {
      GetDataEmployee(idEmployee.employeeId);
    }
  }, [GetDataEmployee, idEmployee.employeeId]);

  useEffect(() => {
    if (dataEmployee) {
      dispatch(setCurrentUser(dataEmployee));
    }
  }, [dataEmployee, dispatch]);

  const description = idEmployee.employeeId
    ? t("homePage.welcomeMessageAdmin")
    : t("homePage.welcomeMessage");

  const title = idEmployee.employeeId
    ? t("homePage.welcomeAdmin")
    : t("homePage.welcome");

  const renderComponent = useMemo(() => {
    const components: Partial<Record<ContentsComponent, JSX.Element>> = {
      [ContentsComponent.USEREDITOR]: <ContainerUserInfo />,
      [ContentsComponent.LOADTEMPLATE]: <LoadTemplate />,
    };
    return components[component];
  }, [component]);

  return (
    <div className={styles.home}>
      <Sidebar>
        {token.role === RoleEnum.USER ||
        token.role === RoleEnum.TEMPLATE_EDITOR ? (
          <ContentSidebarUser />
        ) : (
          <ContentSidebarAdmin />
        )}
      </Sidebar>
      <div className={styles.content}>
        <Header description={description} title={title} />
        <Loading type={LoadingType.MODIFY} loading={isLoading || isFetching} />

        {renderComponent}
      </div>
    </div>
  );
};

export default UserEditor;
