export enum NotificationTypes {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export type NotificationType =
  | NotificationTypes.SUCCESS
  | NotificationTypes.INFO
  | NotificationTypes.WARNING
  | NotificationTypes.ERROR;

  export enum NotificationPosition {
    TOP_LEFT="topLeft",
    TOP_RIGHT="topRight",
  }