import { useCallback } from "react";
import ButtonSidebar from "../ButtonSidebar";
import styles from "./ContentSidebarAdmin.module.css";
import { useTranslation } from "react-i18next";
import { ContentsComponent } from "../../../constants/ContentsComponentEnum";
import { useDispatch, useSelector } from "react-redux";
import { setComponent } from "../../../slices/componentNavigationSidebarAdminSlice";
import { RootState } from "../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesPath } from "../../../constants/RoutesEnum";
import { deleteIdEmployee } from "../../../slices/infoEmployeeSlice";
import { RoleEnum } from "../../../constants/RoleEnum";
import { FormatDate } from "../../../constants/FormatDateEnum";
import dayjs from "dayjs";

const ContentSidebarAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const component = useSelector(
    (state: RootState) => state.component.component,
  );
  const location = useLocation();
  const pathRoot = location.pathname;
  const user = useSelector((state: RootState) => state.user);
  const idEmployee = useSelector((state: RootState) => state.infoEmployee);
  const token = useSelector((state: RootState) => state.token);

  const goPdfPage = useCallback(() => {
    navigate(`${RoutesPath.PDF_OVERVIEW}?id=${user?.id}`);
    dispatch(setComponent(ContentsComponent.USEREDITOR));
  }, [dispatch, navigate, user?.id]);

  const onClickChangeComponent = useCallback(
    (component: ContentsComponent) => {
      if (pathRoot === RoutesPath.USEREDITOR) {
        navigate(`${RoutesPath.ADMIN}?id=${user?.id}`);
      }
      dispatch(deleteIdEmployee());
      dispatch(setComponent(component));
    },
    [dispatch, navigate, pathRoot, user?.id],
  );

  const onClickChangePage = useCallback(() => {
    dispatch(deleteIdEmployee());
    navigate(`${RoutesPath.USEREDITOR}?id=${user?.id}`);
    dispatch(setComponent(ContentsComponent.USEREDITOR));
  }, [dispatch, navigate, user?.id]);

  return (
    <div className={styles.contentSidebarAdmin}>
      {pathRoot === RoutesPath.USEREDITOR &&
        idEmployee.employeeId === undefined && (
          <p style={{ marginTop: "0", fontSize: "18px" }}>{`${t(
            "homePage.lastEdit",
          )}: ${dayjs(user?.lastModifiedDate).format(
            FormatDate.DATE_MONTH_Y,
          )}`}</p>
        )}
      <ButtonSidebar
        text={t("homePage.titleAdminPage")}
        onClickChangeComponent={() =>
          onClickChangeComponent(ContentsComponent.CONTAINERALLEMPLOYEES)
        }
        active={
          component === ContentsComponent.CONTAINERALLEMPLOYEES &&
          pathRoot !== RoutesPath.USEREDITOR
        }
      />
      {token.role === RoleEnum.ADMIN && (
        <ButtonSidebar
          text={t("pdfOverview.templateTitleSidebar")}
          onClickChangeComponent={() =>
            onClickChangeComponent(ContentsComponent.LOADTEMPLATE)
          }
          active={component === ContentsComponent.LOADTEMPLATE}
        />
      )}

      <ButtonSidebar
        text={t("homePage.editRole")}
        onClickChangeComponent={() =>
          onClickChangeComponent(ContentsComponent.ROLEMANAGMENT)
        }
        active={component === ContentsComponent.ROLEMANAGMENT}
      />
      <ButtonSidebar
        text={t("homePage.changeProfile")}
        onClickChangeComponent={onClickChangePage}
        active={
          pathRoot === RoutesPath.USEREDITOR &&
          idEmployee.employeeId === undefined
        }
      />
      <ButtonSidebar
        text={t("homePage.exportPdf")}
        onClickChangeComponent={goPdfPage}
      />
    </div>
  );
};

export default ContentSidebarAdmin;
