import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

export const getPdfFileName = (
  employeeEmail: string,
  template: string,
  isAnonymous: boolean,
) => {
  const emailEl = employeeEmail
    ? employeeEmail.split("@")[0].replaceAll(".", " ").split(" ")
    : "";

  const name = emailEl[0] ? capitalizeFirstLetter(emailEl[0]) : "";
  const surname = emailEl[1] ? capitalizeFirstLetter(emailEl[1]) : "";

  const nameReduce = name ? name[0].toUpperCase() : "";
  const surnameReduce = surname ? surname[0].toUpperCase() : "";

  if (isAnonymous && employeeEmail) {
    return `${nameReduce}.${surnameReduce} - ${template}.pdf`;
  } else {
    return `${name} ${surname} - ${template}.pdf`;
  }
};
