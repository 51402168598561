export enum RoleEnum {
  ADMIN = "ADMIN",
  USER = "USER",
  HR = "HR",
  TEMPLATE_EDITOR = "TEMPLATE_EDITOR",
}

export const RoleOptions = [
  { value: RoleEnum.ADMIN, label: "Admin" },
  { value: RoleEnum.USER, label: "User" },
  { value: RoleEnum.HR, label: "HR" },
  { value: RoleEnum.TEMPLATE_EDITOR, label: "Template Editor" },
];
